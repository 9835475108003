<template>
  <div class="justify-center"  style="width: calc(100vw - 30px) !important;">
    <!-- start of USER-INPUT -->    
    <v-row class="mt-0" no-gutters>
        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">  
            <v-form ref="loginForm" v-on:submit.prevent="login()" class="mt-3" v-model="valid" lazy-validation>
                <v-text-field dense class="mb-3" v-model.trim="userEmail" filled :background-color="inputBackgroundColor" :label="$t('fields.email')" append-icon="icons8-envelope" hide-details="auto" :rules="emailRules" required></v-text-field>
                <v-text-field dense class="mb-4" v-model.trim="userPassword" filled :background-color="inputBackgroundColor" :label="$t('fields.password')" :append-icon="show ? 'icons8-eye' : 'icons8-invisible'" :type="show ? 'text' : 'password'" 
                    @click:append="show = !show" 
                    :rules="passwordRules"
                    hide-details="auto"
                    required
                    v-on:keyup.enter="login()"
                ></v-text-field>
            </v-form>
        </v-col>
    </v-row>
    <v-row class="justify-center" no-gutters>
        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="d-flex justify-space-around flex-column align-center">
            <v-btn class="primary mt-2 mb-1 lighttext--text" style="width: 45% !important;" :large="largeButton" :small="smallButton" @click.native="login()">{{ $t('buttons.login') }}</v-btn>
            <div class="mt-2"><router-link class="primary--text font-weight-bold text-decoration-none" :to="{ path: 'reset' }">{{ $t('firebaseAuth.login.forgotPassword') }}?</router-link></div>
        </v-col>
    </v-row>
    <!-- end of USER-INPUT -->
  </div>
</template>

<script>
    export default {
        name: 'Login',
        data: () => ({
            inputBackgroundColor: 'lighttext',
            valid: true,
            show: false,
            userEmail: '',
            userPassword: '',
            loading: false, // to show loading bar
            //validation rules
            emailRules: [
                (v) => !!v || 'E-mail is required',
                (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],
            passwordRules: [(v) => !!v || 'Password is required'],
        }),
        computed: {
            denseInput() {
                switch(true) {
                    case (this.$vuetify.breakpoint.xsOnly):
                        return true;
                    default:
                        return false;
                }
            },
            largeButton() {
                switch (true) {
                    case (this.$vuetify.breakpoint.xsOnly):
                        return false;
                    default:
                        return true;
                }
            },
            smallButton() {
                switch (true) {
                    case (this.$vuetify.breakpoint.xsOnly):
                        return true;
                    default:
                        return false;
                }
            }            
        },
        methods: {
            checkCurrentUser(userID){
                let t = this;
                var currentUser = [];
                t.$firebase.db.collection('users').doc(userID).get()
                    .then((doc) => {
                        if (doc.exists) { 
                            var document = doc.data();
                            document.id = doc.id;
                            currentUser = document;
                        //console.log('doc exists')
                        } else {
                            currentUser = null;
                            t.MIX_alert(-1, 'Account not found.', null, null);
                        }
                    });
                return currentUser;
            },
            login() {
                let t = this;
                t.MIX_progressBar({show: true});
                t.userEmail = t.userEmail.toLowerCase();
                if (t.$refs.loginForm.validate()) {
                    t.$firebase.auth
                    .signInWithEmailAndPassword(t.userEmail, t.userPassword)
                    .then((data) => {
                        t.loading = true;
                        var user = t.checkCurrentUser(data.user.uid);
                        if (user !== null){
                            // setTimeout(function(){            
                                t.MIX_progressBar({show: false}); 
                                t.MIX_FIREBASE_currentUser(data.user)
                                t.$router.push('/').catch((err) => console.log('err: ' + err));
                                // t.checkCurrentUserfirebase.analytics().logEvent('user_loggedin');
                            // }, 500); 
                        }

                    })
                    .catch((err) => {
                        console.log('err: ' + err)
                        t.MIX_alert(-1, err, null, null);
                    });
                } else {
                    t.MIX_alert(-1, 'The email password combination is incorrect.', null, null);
                }
            }
        }
    }
</script>

<style scoped>
</style>

