<template>
     <apexchart :options="chartOptions"  :height="height" :width="width" :series="series"></apexchart>
</template>

<script>
export default {
    props: {
        width: { type: String, default: '100%' },
        height: { type: String, default: '100%' },
        title: { type: String, default: 'Chart Title' },
        chartdata: { type: Object, default: function() { return {} } },
        // annotations: {type: Array, default: function() { return {} } }
    },
    // mounted () {
    //     this.renderChart(this.chartData, this.options)
    

    data: () => ({
        chartOptions: {
            chart: {
                height: 50,
                type: 'line',
                zoom: { enabled: false },
                fill: {
                    colors: ['#9c27b0']
                },
            },
            annotations: {
                yaxis: [{
                    y: 60,
                    borderColor: '#00E396',
                    label: {
                        borderColor: '#00E396',
                        style: {
                            color: '#fff',
                            background: '#00E396',
                        },
                        text: 'Support',
                    }
                }, {
                    y: 80,
                    y2: 90,
                    borderColor: '#000',
                    fillColor: '#FEB019',
                    opacity: 0.2,
                    label: {
                        borderColor: '#333',
                        style: {
                            fontSize: '10px',
                            color: '#333',
                            background: '#FEB019',
                        },
                        text: 'Y-axis range',
                    }
                }],
                xaxis: [{
                    x: 'Apr',
                    strokeDashArray: 0,
                    borderColor: '#775DD0',
                    label: {
                        borderColor: '#775DD0',
                        style: {
                            color: '#fff',
                            background: '#775DD0',
                        },
                        text: 'Anno Test',
                    }
                }],
                points: [{
                    x: 'Feb',
                    y: 40,
                    marker: {
                        size: 8,
                        fillColor: '#fff',
                        strokeColor: 'red',
                        radius: 2,
                        cssClass: 'apexcharts-custom-class'
                    },
                    label: {
                        borderColor: '#FF4560',
                        offsetY: 0,
                        style: {
                          color: '#fff',
                          background: '#FF4560',
                        },

                        text: 'Point Annotation',
                    }
                }]
            },      
            dataLabels: { enabled: false },
            stroke: { curve: 'straight' },
            title: { text: 'Annotated', align: 'left' },
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
            },  
        },
        series: []
    }),
    methods: {
        updateChart() {
            // console.log('updatechart')
            // console.log('this.chartdata = ' + JSON.stringify(this.chartdata))
            this.series = [];
            this.series.push (this.chartdata);
        }
    },
    mounted () {
        this.chartOptions.title.text = this.title // save prop into title
        this.chartOptions.chart.height = this.height;
        this.chartOptions.chart.width = this.width;
        // this.chartOptions.annotations = this.annotations
        this.chartOptions.chart.fill.colors[0] = this.$vuetify.theme.themes.light.primary  
        this.updateChart();
    }
}
</script>