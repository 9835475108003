<template>
    <div class="d-flex align-start flex-column justify-start fill-height" style="width: 100vw !important;">
        <v-container fluid width=100% style="padding: 0px !important;">
            <v-card flat color="lightgrey">
                <v-row class="py-2" >
                    <v-col cols="12" class="px-8 align-center">
                        <div class="text-h5 pt-2 primary--text font-weight-thin">CATALOG</div>
                        <span class="body-2 font-weight-regular darktext--text">Welcome to the Catalog, please select a location and then the type of activity below you want to monitor.</span>
                    </v-col>
                </v-row>
            </v-card>
        
        <!-- <h1>{{ $t('headings.catalog') }}</h1> -->
        <v-row class="d-flex align-center px-5 justify-start">
            <v-col cols="5" xs="2" class="d-flex mt-6 align-center justify-start">
                <v-text-field dense no-details filled :label="$t('filters.search')" v-model="filters.search" background-color="white" />
            </v-col>
            <v-spacer />
            <v-col cols="2" xs="2" class="text-right d-flex align-center justify-end">
                <v-btn @click="columnsDialog = !columnsDialog">Headers</v-btn>
            </v-col>
        </v-row>

        <v-data-table
            :headers="computedHeaders"
            :items="computedTableData"
            :items-per-page="10"
            item-key="id"
            class="elevation-0 mx-5"
            hide-default-header
            :search="filters.search"
            >
            <!-- // HEADERS -->                        
            <template v-slot:header="{ props }">
                <th :align="head.align" class="pa-4 font-weight-medium" style="font-size:12px !important;" v-for="(head, index) in props.headers" :key="index">
                    {{ $t(`fields.${head.text}`) }}
                </th>
            </template>     
            <!-- // NAME -->
            <template v-slot:[`item.station_name`]="{ item }">
                <span class="d-inline-block text-truncate" style="max-width: 200px;">{{ item.station_name }}</span>
            </template>
            <!-- // View DataStructure -->
            <template v-slot:[`item.actions`]="{ item }">
               <v-btn @click="showDataStructures(item.data_structures)">Data Structure</v-btn>
            </template>
        </v-data-table> 
        </v-container> 


        <!-- // * DYNAMIC DATA STRUCTURE DIALOG -->        
        <v-dialog v-model="dataStructureDialog" scrollable max-width="800px">
            <v-card>
                <v-card-title>{{$t('headings.dataStructures')}} ({{ MIX_arrayLength(dataStructures) }})</v-card-title>
                <v-divider></v-divider>
                <v-card-text style="height: 500px;">
                    <v-expansion-panels>
                        <v-expansion-panel v-for="item in dataStructures" :key="item.data_structure_type">
                            <v-expansion-panel-header>
                              {{ item.data_structure_type }} - {{ item.description }}
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">Name</th>
                                                <th class="text-left">Type</th>
                                                <th class="text-left">Units</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, propertyName) in item.data_structure" :key="propertyName">
                                                <td>{{ propertyName }}</td>
                                                <td>{{ item.type }}</td>
                                                <td>{{ item.units }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="text-right">
                    <v-spacer />
                    <v-btn color="primary" text @click="hideDatastructures()">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>        
        <!-- // * DYNAMIC COMPONENT DIALOG -->        
        <v-dialog v-model="columnsDialog" scrollable max-width="300px">
            <v-card>
                <v-card-title>{{$t('headings.showHideColumns')}}</v-card-title>
                <v-divider></v-divider>
                <v-card-text style="height: 300px;">
                    <v-list>
                        <v-list-item v-for="(header, index) in headers" :key="index">
                          <v-list-item-title><v-checkbox color="grey darken-1" hide-details v-model="headers[index].hidden" :false-value="true" :true-value="false" :label="$t(`fields.${header.text}`)"></v-checkbox></v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="text-right">
                    <v-spacer />
                    <v-btn color="primary" text @click="columnsDialog = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>      
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'; // Map Actions / Getters
    export default {
        data: () => ({
            tableData: [],
            filters: {
                search: '',
                sortAsc: true,
            },
            sortBy: 'sensor_type',
            columnsDialog: false,
            dataStructureDialog: false,
            dataStructures: [],
            headers: [
                { text: 'sensor_type', value: 'sensor_type', sortable: false, align: 'start', hidden: false, hide: false },
                { text: 'manufacturer', value: 'manufacturer', sortable: false, align: 'start', hidden: false, hide: false },
                { text: 'product_name', value: 'product_name', sortable: false, align: 'start', hidden: false, hide: true },
                { text: 'product_number', value: 'product_number', sortable: false, align: 'start', hidden: false, hide: true },                
                { text: 'category', value: 'category', sortable: false, align: 'start', hidden: false, hide: true },
                { text: 'actions', value: 'actions', sortable: false, align: 'end', hidden: false, hide: true },
            ],
        }),
        computed: {
            ...mapGetters({
                GET_weatherDataCatalog: 'GET_weatherDataCatalog',
            }),
            // Computed Table Headers            
            computedHeaders() {
                var t = this;
                var headers = t.headers;
                return (t.$filter(headers, {
                    hidden: false, // filter headers based on initial hidden value
                }));
            },
            // Computed Table Data
            computedTableData() {
                var t = this;
                var tableData = t.tableData;
                // // Filter by status
                // tableData = tableData.filter(function(item) {
                //     return t.filter.status.includes(item[t.collectionItem+'Status']);
                // });
                // // Filter by type
                // if ((t.filter.type !== '') && (t.filter.type !== undefined) && (t.filter.type !== null)) {                    
                //     tableData = tableData.filter(function(item) {
                //         return t.filter.type === item[t.collectionItem + 'Type'];
                //     });
                // }
                // // Filter by Search Mobile                
                // if (t.search !== '' && t.$vuetify.breakpoint.smAndDown) {
                //     tableData = tableData.filter(function(item) {
                //         var itemName = item[t.collectionItem+'Name'].toUpperCase();
                //         var search = t.search.toUpperCase();
                //         return itemName.includes(search)
                //     });
                // }
                var tableDataList = tableData.map(e => {
                    return {
                        sensor_type: e.sensor_type,
                        manufacturer: e.manufacturer,
                        product_name: e.product_name,
                        product_number: e.product_number,
                        category: e.category,
                        data_structures: e.data_structures,
                    };
                });
                // Sort the Table by the Field in the Sort By Filter
                var sortResults = t.$sortBy(tableDataList, t.sortBy)
                if (!t.filters.sortAsc) {sortResults.reverse()}
                return (sortResults);      
            }            

        },
        methods: {
            showDataStructures(dataStructures) {
                this.dataStructures = dataStructures;
                this.dataStructureDialog = true;
            },
            hideDatastructures() {
                this.dataStructureDialog = false;
                this.dataStructures = [];
            }

        },
        async mounted() {
            var t = this;
            await t.MIX_weatherDataCatalog();
            t.tableData = t.GET_weatherDataCatalog;
        }

}
</script>