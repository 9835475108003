
import supportedLocales from "./locales-list.js"

export function getSupportedLocales() {
  let annotatedLocales = []

  for (const code of Object.keys(supportedLocales)) {
    annotatedLocales.push({
      code,
      name: supportedLocales[code]
    })
  }

  return annotatedLocales
}