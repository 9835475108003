<template>
	<v-card flat color="appbackground" class="d-flex justify-center align-start" style="width: 75vw !important">
		<v-row class="" style="width: 100% !important">
			<v-col cols="12">
                <v-card tile flat color="lightgrey" :class="`rounded-t`" class="d-flex justify-center">
                    <div class="text-h6 darktext--text py-3">{{ $t('activityLog.title') }}</div>
                </v-card>
			</v-col>

            <v-col cols="12" class="pa-2">
                <div class="font-weight-bold px-6 darktext--text">{{ $t('activityLog.user') }}: <span class="font-weight-regular"> {{ GET_currentUser.userFirstName }}</span></div>
				<div class="font-weight-bold px-6 darktext--text">{{ $t('activityLog.userEmail') }}: <span class="font-weight-regular"> {{ GET_currentUser.userEmail }}</span></div>
                <div v-if="loggedDate" class="font-weight-bold px-6 darktext--text">{{ $t('activityLog.date') }}: <span class="font-weight-regular"> {{MIX_dayTimeFormat( loggedDate, 'short')}}</span></div>
                <div v-else class="font-weight-bold px-6 darktext--text">{{ $t('activityLog.date') }}: <span class="font-weight-regular"> {{ date }}</span></div>
                <div v-if="locationName" class="font-weight-bold px-6 darktext--text">{{ $t('activityLog.sensorLocation') }}: <span class="font-weight-regular"> {{ locationName }}</span></div>
				<div v-else class="font-weight-bold px-6 darktext--text">{{ $t('activityLog.sensorLocation') }}: <span class="font-weight-regular"> {{ station.station_name }}</span></div>
            </v-col>
            
            <v-col cols="12" class="pa-2">
                <div class="font-weight-bold px-6 text-uppercase darktext--text">{{ $t('activityLog.activity') }}: <span class="font-weight-regular"> {{ $t(currentdata.activity.title) }}</span></div>
				<div v-if="currentdata.fertiliserPeriod" class="font-weight-bold px-6 text-uppercase darktext--text">{{ $t('weather.fertiliserPeriod.title') }}: <span class="font-weight-regular"> {{ $t(currentdata.fertiliserPeriod.value) }}</span></div>
				<div v-if="currentdata.muckPeriod" class="font-weight-bold px-6 text-uppercase darktext--text">{{ $t('weather.muckSpreadingPeriod.title') }}: <span class="font-weight-regular"> {{ $t(currentdata.muckPeriod.value) }}</span></div>
				<div v-if="currentdata.slurryPeriod" class="font-weight-bold px-6 text-uppercase darktext--text">{{ $t('weather.slurrySpreadingPeriod.title') }}: <span class="font-weight-regular"> {{ $t(currentdata.slurryPeriod.value) }}</span></div>
				<div v-if="currentdata.soilTempDayDegC" class="font-weight-bold px-6 text-uppercase darktext--text">{{ $t('weather.soilTemperature') }}: <span class="font-weight-regular"> {{ currentdata.soilTempDayDegC.value }}°</span></div>
				<div v-if="currentdata.soilTempWeekDegC !== null && currentdata.soilTempWeekDegC" class="font-weight-bold px-6 text-uppercase darktext--text">{{ $t('weather.daily5SoilTemperature') }}: 
                    <span class="font-weight-regular"> {{ currentdata.soilTempWeekDegC.value }}</span>
                    <v-card class="appbackground my-3" flat>
                        <div v-for="(item, index) in currentdata.soilTempWeekDegC.value.slice(0,5)" :key="index">
                            <v-row>
                                <v-col cols=4>
                                    <v-row class="py-1 justify-end">
                                        <div class="text--caption font-weight-regular darktext--text">{{ MIX_formatDateTime(item.ts, 'X', 'DD/MM') }}</div>
                                    </v-row>
                                </v-col>
                                <v-col cols=6>
                                    <v-row class="justify-start pl-7 py-1"> 
                                        <v-icon size="15" color="darktext" class="">icons8-temperature</v-icon>
                                        <div class="text--caption font-weight-regular darktext--text">{{ item.value.toFixed() }}°</div>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card>
                </div>
				<div v-if="currentdata.soilTempMonthDegC" class="font-weight-bold px-6 text-uppercase darktext--text">{{ $t('weather.monthlySoilTemperature') }}: <span class="font-weight-regular"> {{ currentdata.soilTempMonthDegC.value }}°</span></div>
				<div v-if="currentdata.soilMoistureBar" class="font-weight-bold px-6 text-uppercase darktext--text">{{ $t('weather.soilMoisture') }}: <span class="font-weight-regular"> {{ currentdata.soilMoistureBar.value }} bar</span></div>
				<div v-if="currentdata.windspeed" class="font-weight-bold px-6 text-uppercase darktext--text">{{ $t('weather.windSpeed') }}: <span class="font-weight-regular"> {{ currentdata.windspeed.value }} bar</span></div>
				<div v-if="currentdata.leafMoisture" class="font-weight-bold px-6 text-uppercase darktext--text">{{ $t('weather.leafMoisture') }}: <span class="font-weight-regular"> {{ currentdata.leafMoisture.value }} bar</span></div>
				<div v-if="currentdata.weatherForecast" class="font-weight-bold px-6 text-uppercase darktext--text">
                    {{ $t('weather.weatherForecast') }}: <br />
                    <v-card class="appbackground mt-5" flat>
                        <div v-for="(item, index) in currentdata.weatherForecast.value.slice(0,5)" :key="index">
                            <v-row>
                                <v-col cols=5>
                                    <v-row class="py-1 justify-end">
                                        <div class="text--caption font-weight-regular darktext--text">{{ MIX_formatDateTime(item.dt, 'X', 'DD/MM') }}</div>
                                    </v-row>
                                </v-col>
                                <v-col cols=2>
                                    <v-row class="d-flex align-center justify-center py-1"> 
                                        <v-icon size="15" color="darktext" class="">icons8-temperature</v-icon>
                                        <div class="text--caption font-weight-regular darktext--text">{{ item.temp.max.toFixed() }}°</div>
                                    </v-row>
                                </v-col>
                                <v-col cols=5>
                                    <v-row class="d-flex align-center justify-start py-1"> 
                                        <v-icon size="15" color="primary" class="">icons8-rainmeter</v-icon>
                                        <div v-if="item.rain" class="text--caption font-weight-regular darktext--text">{{ item.rain.toFixed() }}°</div>
                                        <div v-else class="text--caption font-weight-regular darktext--text">0°</div>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card>
                </div>
            </v-col>
            <v-col  cols="12" class="px-6">
                <v-btn v-if="showButton === true" block depressed large @click="MIX_go('/farming')" class="mt-3 success">{{ $t('buttons.activity') }}</v-btn>          
                <!-- <v-btn block depressed large @click="overlay = overlay" class="mt-3 lightgrey darktext--text">{{ $t('buttons.back') }}</v-btn>-->
            </v-col>
        </v-row>
	</v-card>
</template>

<script>
    import { mapGetters } from 'vuex';
	export default {
        computed: {
        ...mapGetters({
            GET_activeStation: 'GET_activeStation',
            GET_currentUser: 'GET_currentUser'
            }),
            station: {
                get () {
                    return this.GET_activeStation;
                },
                set (value) {
                    this.MIX_activeStation(value);
                }
            },
        },
        data: () => ({
            date: new Date().toISOString().substr(0, 10), 
            // station: {},
        }),
		props: {
            loggedDate:{
                type:String,
                required:false,
            },
            locationName: {
                type: String,
                required: false,
            },
			currentdata: {
				type: Object,
				required: false,
				default: function() {
					return {
						icon: "icons8-calendar",
						title: "Dash Title",
                        unit: "Unit",
						subtitle: "Dash Sub Title",
						value: "-",
						status: "grey",
					};
				},
			},
            showButton: {
                type:Boolean,
                required:false,
            }
		},
        watch: {
            GET_currentUser: {
                handler() {
                   // this.token = JSON.parse(JSON.stringify(this.GET_currentUser)).stsTokenManager.accessToken
                }, deep: true
            }
        }
	};
</script>
