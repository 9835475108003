<template>
            <v-container fluid ma-0 pa-0 class="fill-height" style="width: 100% !important;"><router-view/></v-container>
</template>

<script>
    export default {
        async mounted () {
            var t = this;
            await t.MIX_weatherDataCatalog();
            await t.MIX_weatherDataStations();
            // console.log('Got Data Points')
        }

    }
</script>

