<!--
    soil_temperature.vue
    created by: Emilie@vindico.net
    // TODO


-->
<template>
    <div>
        <!-- LOOP THROUGH SOIL DATA -->


        <v-layout row wrap class="px-2 pt-1">
            <v-flex v-for="(day, index) in orderedSoilData" :key="index" class="lg5-custom">
                <template>
                    <div v-if="convertToCelcius(soilData[index].value) < 15"> 
                        <v-card color="success" rounded flat height="50" class="ma-1">
                            <v-row>
                                <v-col cols=12 class="d-flex justify-center py-0">
                                    <div>
                                        <div class="body-2 text-center font-weight-light white--text">{{ MIX_formatDateTime(soilData[index].ts, 'X', 'DD/MM') }}</div>
                                        <div class="font-weight-normal text-center body-1 white--text">{{ MIX_formatDateTime(soilData[index].ts, 'X', 'ddd') }}</div>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-card color="accent" class="rounded-0 mt-3" width=100% height="40" flat>
                                <v-row class="justify-center pt-1"><h3> {{ convertToCelcius(soilData[index].value) }}°</h3></v-row> 
                            </v-card>
                        </v-card>
                    </div>
                    <div v-else-if="convertToCelcius(soilData[index].value) >= 15"> 
                        <v-card color="error" rounded flat height="50" class="ma-1">
                            <v-row>
                                <v-col cols=12 class="d-flex justify-center py-0">
                                    <div>
                                        <div class="body-2 text-center font-weight-light white--text">{{ MIX_formatDateTime(soilData[index].ts, 'X', 'DD/MM') }}</div>
                                        <div class="font-weight-normal text-center body-1 white--text">{{ MIX_formatDateTime(soilData[index].ts, 'X', 'ddd') }}</div>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-card color="accent" class="rounded-0 mt-3" width=100% height="40" flat>
                                <v-row class="justify-center pt-1"><h3> {{ convertToCelcius(soilData[index].value) }}°</h3></v-row> 
                            </v-card>
                        </v-card>
                    </div>
                    <div v-else> 
                        <v-card color="grey" rounded flat height="50" class="ma-1">
                            <v-row>
                                <v-col cols=12 class="d-flex justify-center py-0">
                                    <div>
                                        <div class="body-2 text-center font-weight-light white--text">{{ MIX_formatDateTime(soilData[index].ts, 'X', 'DD/MM') }}</div>
                                        <div class="font-weight-normal text-center body-1 white--text">{{ MIX_formatDateTime(soilData[index].ts, 'X', 'ddd') }}</div>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-card color="accent" class="rounded-0 mt-3" width=100% height="40" flat>
                                <v-row class="justify-center pt-1"><h3> {{ convertToCelcius(soilData[index].value) }}°</h3></v-row> 
                            </v-card>
                        </v-card>
                    </div>
                    
                </template>
            </v-flex>        
        </v-layout>
    </div>
</template>

<script>

// import _ from 'lodash';
import _ from "lodash-es";
import { mapGetters } from 'vuex'; // Map Actions / Getters
export default {
    name: '',
    data: () => ({
        activeStation: null,
        soilData: [],
    }),
    computed: {
        ...mapGetters({
                GET_activeStation: 'GET_activeStation',
                GET_weatherDataStations: 'GET_weatherDataStations',
        }),
        orderedSoilData: function() {
            return _.sortBy(this.soilData, 'ts').reverse();
        },
    
    station: {
            get () {
                return this.GET_activeStation;
            },
            set (value) {
                this.MIX_activeStation(value);
            }
        }
    },
    methods: {
        convertToCelcius: function (value) {
          if (!value) return ''
          return parseFloat(((5/9) * (parseFloat(value) - 32)).toFixed())
        },  
        getSensor: function(sensor_type) {
            return this.current.find(sensor => parseInt(sensor.sensor_type) === parseInt(sensor_type) )
        },

        updateData: async function (stationId) {
            var t = this;
            // var routerPath = t.$route.path.split('/')
            // console.log ("stationID = " + stationId)
            t.soilData = await t.MIX_weatherDataSoilData(stationId);
            // console.log('xsoilData = ' + JSON.stringify(t.soilData, null, 2))
            
        },
    },
        async mounted () {
            var t = this;
            t.activeStation = await t.GET_activeStation;
            // console.log ('active station = ' + JSON.stringify(t.activeStation))
            t.updateData(t.activeStation.station_id);
        }
}
</script>

<style>
    .flex.lg5-custom {
        width: 20%;
        max-width: 20%;
        flex-basis: 20%;
    }
</style>