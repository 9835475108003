import Vue from 'vue';

// * SERVICE WORKER INTEGRATION
import '@/registerServiceWorker';
import appUpdate_mixin from '@/modules/appUpdate/appUpdate_mixin.js'; // Import Service Work Mixing for Global SW Functions
Vue.use(appUpdate_mixin); // Use Service Worker Mixing within Vie

// * APP UPDATE COMPONENT
import appUpdate_component from '@/modules/appUpdate/appUpdate_component.vue';
Vue.component('appupdate', appUpdate_component);
