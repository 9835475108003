<template>
    <div>
        <v-layout row wrap>
            <v-flex class="lg5-custom" v-for="(day, index) in forecast.daily.slice(0,5)" :key="index">
                <v-card color="accent" rounded flat class="ma-1">
                    <v-row class="d-flex align-center justify-center">
                        <div>
                            <div class="text-center caption grey--text pa-0">{{ MIX_formatDateTime(forecast.daily[index].dt, 'X', 'DD/MM') }}</div>
                            <div class="font-weight-bold text-h7 text-center darktext--text pa-0">{{ $t(MIX_formatDateTime(forecast.daily[index].dt, 'X', 'ddd')) }}</div>
                        </div>
                    </v-row>
                    <v-row class="d-flex align-center justify-center">
                        <div class="text-center text-h4 font-weight-normal primary--text py-3">{{ forecast.daily[index].temp.day.toFixed() }}°</div>
                    </v-row>
                    <v-row class="d-flex align-center justify-center px-1">
                        <v-icon size="15" color="grey" class="">icons8-temperature</v-icon>
                        <h4 class="text-center caption">{{ forecast.daily[index].temp.max.toFixed() }}° | <span class="font-weight-light"> {{ forecast.daily[index].temp.min.toFixed() }}°</span></h4>
                    </v-row>
                    <div v-if="forecast.daily[index].rain" >
                        <v-row class="d-flex align-center justify-center px-1 py-1">
                            <v-icon size="15" color="grey" class="pt-2">icons8-rainmeter</v-icon>
                            <h4 class="text-center caption pt-2">{{ forecast.daily[index].rain.toFixed() }}%</h4>
                        </v-row>
                    </div>
                    <div v-else >
                        <v-row class="d-flex align-center justify-center px-1 py-1">
                            <v-icon size="15" color="grey" class="pt-2">icons8-rainmeter</v-icon>
                            <h4 class="text-center caption pt-2">0%</h4>
                        </v-row>
                    </div>
                </v-card>   
            </v-flex>
        </v-layout>
            <!-- Forecast <br /><pre>{{ GET_weatherDataForecast }}</pre> -->
    </div>
</template>

<script>
import { mapGetters } from 'vuex'; // Map Actions / Getters
export default {
    data: () => ({
    }),
    computed: {
        ...mapGetters({
            GET_activeStation: 'GET_activeStation',
            GET_weatherDataStations: 'GET_weatherDataStations',
            GET_weatherDataForecast: 'GET_weatherDataForecast',
        }),
    forecast: {
            get () {
                return this.GET_weatherDataForecast;
            },
            set () {
                // do nothing
            }
    },
    station: {
            get () {
                return this.GET_activeStation;
            },
            set (value) {
                this.MIX_activeStation(value);
            }
        }

    },
    watch: {
        GET_weatherDataForecast: {
            handler() {
                this.forecast = this.GET_weatherDataForecast;
            },deep: true
        },
    },
    methods: { 
        updateForecast() {
            var t = this;
            t.forecast.main = t.forecast.current.weather.main
        },
    },
    async mounted () {
        var t = this;
        //console.log('GET_activeStation = ' + t.GET_activeStation)
        await t.MIX_weatherDataForecast(t.GET_activeStation.latitude, t.GET_activeStation.longitude);
    }
}
</script>

<style>
    .flex.lg5-custom {
        width: 20%;
        max-width: 20%;
        flex-basis: 20%;
    }
</style>