
const firebase_auth_store = {
    namespaced: true, //to be case sencitive
    // * STATE
    state: {
        currentUser: null,
    },
    // * MUTATIONS
    mutations:{
        MUT_FIREBASE_currentUser (state, payload) { state.currentUser = payload }
    },
    // * GETTERS
    getters:{
        GET_FIREBASE_currentUser (state) { return state.currentUser }
    },
    // * ACTIONS
    actions: {
        ACT_FIREBASE_currentUser ( { commit }, payload) { commit('MUT_FIREBASE_currentUser', payload) }
    }    
};
export default firebase_auth_store;