<template>
    <div class="d-flex align-center flex-column justify-start fill-height" style="width: 100% !important;">

        <pageLoader :is-page-loading="isPageLoading"/>

        <v-container fluid height="160" width=100% style="padding: 0px !important;">
            <v-card tile elevation="0" color="lightgrey">
                <v-row class="" >
                    <v-col cols="10" class="pl-0 pt-8 pb-4 align-self-center">
                        <div class="text-h5 pl-8 primary--text font-weight-thin text-uppercase">{{ $t('activityLog.activity') }}</div>
                    </v-col>
                </v-row>
            </v-card>


            <v-row class="pt-2 px-3" no-gutters>    

                <v-col cols="12" class="mt-2">

                    <!--Header and actions-->
                    <div class="justify-space-between d-flex  pl-2 pt-1 pr-2">

                        <!--Header-->
                        <div class="text-h6 font-weight-regular darktext--text text--darken-2">{{ $t('activityLog.yourActivity') }}</div>
                        
                        <!--Action-->
                        <div> 
                            <v-icon v-if="startValue > 0" size="30" color="darktext" class="pr-2 align-center icons8-left-arrow" @click="startValue-=10 ; endValue-=10"></v-icon>
                            <v-icon v-if="activityHistory.length >= endValue" size="30" color="darktext" class="pl-2 align-center icons8-right-arrow" @click="startValue+=10 ; endValue+=10"></v-icon>
                        </div>
                    </div>
                        <v-row no-gutters>
                            <v-col cols="12" xs="12" sm="12" class="pa-2">
                                <v-card v-for="(item, index) in logs" :key="index" flat width="100%" height="100" class="d-flex align-center justify-center mb-2"> 
                                    <v-row class=" align-center pl-5"> 
                                        <v-col cols="6" xs="6" sm="8" md="10">
                                            <div class="text-h7 font-weight-thin darktext--text text--darken-2 pl-1"><span class="font-weight-bold">{{ $t(item.accesed) }}</span></div>
                                            <div class="subtitle-2 font-weight-regular darktext--text text--darken-3 pl-1 pt-1"><span class="text-uppercase">{{ item.locationName }}</span></div>
                                        </v-col>
                                        <v-col cols="6" xs="6" sm="4" md="2" class="text-right">
                                            <div class="overline text-right mr-4" style="line-height: 15px !important;">{{ MIX_fromNow(item.dateTime, 'x', false)}}</div>
                                            <v-btn  depressed @click="overlay=!overlay; currentData=item.currentData; locationName=item.locationName ; loggedDate=item.dateTime"  color="primary" class="mt-3 mr-4 lighttext--text" > {{ $t('buttons.view') }} </v-btn>
                                        </v-col>
                                    </v-row>
                                    <v-overlay :value="overlay">
                                        <v-card color="appbackground" flat style="width: 75vw impo !important">
                                            <overlay :currentdata="currentData" :locationName="locationName" :loggedDate="loggedDate" :showButton="false" />
                                            <v-col cols=12 class="">
                                                <v-btn block depressed large @click="overlay = false" class="lightgrey darktext--text">{{ $t('buttons.back') }}</v-btn>   
                                            </v-col>             
                                        </v-card>
                                    </v-overlay>
                                </v-card>
                            </v-col>
                        </v-row>
                </v-col>
                <v-col class="px-2" cols="12" xs="12" sm="6" md="12" lg="12">
                    <v-btn block depressed large @click="MIX_go('/')" color="primary" class="mt-3 lighttext--text">{{ $t('buttons.back') }}</v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
    name: 'Activity',
    data: () => ({
        activityHistory: [],
        overlay: false,
        currentData:null,
        locationName: "",
        loggedDate:"",

        startValue: 0,
        endValue: 10,
        isPageLoading:true
        
    }),
    methods: {
        async readActivity(){
            var self = this;
            var userId = this.GET_currentUser.userId;
            self.activityHistory = [];
            this.$firebase.db.collection("activityLog").where('user.userId', '==', userId)
            .onSnapshot(function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                    var document = doc.data()
                    document.id = doc.id;
                    self.activityHistory.push(document);
                });
            });                            
        },
      
    },
    computed: {
        logs (){
            //Sort Activities
            var sortActivities = this.MIX_sortByDateTime( this.activityHistory);
            
            //Initialize new limit Array 10 per page
            let limitViewArray = [];

            //Check if the endValue is not greater thatn activity array length and that activity array in not empty
            if( this.endValue >= this.activityHistory.length && this.activityHistory.length !=0){

                for ( var i=this.startValue; i < this.activityHistory.length; i++){
                    limitViewArray.push(new Object (sortActivities[i]));
                 }

            }else{

                for ( var x=this.startValue; x < this.endValue; x++){
                    limitViewArray.push(new Object (sortActivities[x]));
                }
            }
            
            return limitViewArray ;
        }
    },
    mounted(){
    },
    async created(){
        this.readActivity();
        this.isPageLoading = false
    }
}
</script>

<style scoped>

</style>
