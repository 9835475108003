import Vue from 'vue'
import App from './App.vue'
import vuetify from './vuetify.js';
import store from './store.js'
import router from './router.js'
import moment from 'moment';

// * ANIMATE CSS
// import '../node_modules/animate.css/animate.min.css'; // import animate css

// * CUSTOM MODULE IMPORTS
require('./modules/firebase/firebase_import.js') // Require Firebase Components and Mixins
require('./modules/progressBar/progressBar_import.js') // Require Progress Bar Components and Mixins
require('./modules/alertbox/alertbox_import.js') // Require Alert Box Components and Mixins
require('./modules/appUpdate/appUpdate_import.js') // Require App Update Components and Mixins

import mapbox_mixin from '@/modules/mapbox/mapbox_mixin.js'; //
Vue.use(mapbox_mixin)

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

//lodash
import _ from "lodash-es";
import groupBy from "lodash-es/groupBy";
import find from "lodash-es/find";
import filter from "lodash-es/filter";
import sortBy from "lodash-es/sortBy";
// import orderBy from "lodash-es/orderBy";
import uniqBy from "lodash-es/uniqBy";
import uniq from "lodash-es/uniq";
import map from "lodash-es/map";
import has from "lodash-es/has";
import pick from "lodash-es/pick";
import omit from "lodash-es/omit";
import unionBy from "lodash-es/unionBy";
import reduce from "lodash-es/reduce";
import meanBy from "lodash-es/meanBy";

Vue.prototype.$_ = _;

Vue.prototype.$groupBy = groupBy;
Vue.prototype.$omit = omit;
Vue.prototype.$has = has;
Vue.prototype.$uniqBy = uniqBy;
Vue.prototype.$uniq = uniq;
Vue.prototype.$filter = filter;
Vue.prototype.$pick = pick;
Vue.prototype.$find = find;
Vue.prototype.$sortBy = sortBy;
Vue.prototype.$reduce = reduce;
Vue.prototype.$map = map;
Vue.prototype.$unionBy = unionBy;
Vue.prototype.$meanBy = meanBy;

Vue.prototype.$moment = moment;


import axios from 'axios';
Vue.prototype.$axios = axios;

// import DatetimePicker from 'vuetify-datetime-picker'
// Vue.use(DatetimePicker)


//* icons
import "@/assets/css/styles.min.css"; // Import Icons8 Font Library CSS file

// require('./csg-wp-api/index.js');
// console.log(api_URL)

// * GLOBAL MIXIN
import mixin from './mixin.js';
Vue.use(mixin);

import i18n from './i18n'
import HelloI18n from "./components/HelloI18n";
Vue.component('HelloI18n', HelloI18n);

import language from "./components/structural/language";
Vue.component('language', language);

import dashCard from "./components/dash_card";
Vue.component('dashcard', dashCard);

import weekTemp from "./components/week_temp";
Vue.component('weektemp', weekTemp);

import forecast from "./components/forecast";
Vue.component('forecast', forecast);

import overlay from "./components/overlay";
Vue.component('overlay', overlay);

// * CHARTS
import linechart_component from '@/components/charts/linechart_component.vue';
Vue.component('linechart', linechart_component);

import barchart_component from '@/components/charts/barchart_component.vue';
Vue.component('barchart', barchart_component);

import barchart_horizontal_component from '@/components/charts/barchart_horizontal_component.vue';
Vue.component('barcharthorizontal', barchart_horizontal_component);

import piechart_component from '@/components/charts/piechart_component.vue';
Vue.component('piechart', piechart_component);

import semidonut_component from '@/components/charts/semidonut_component.vue';
Vue.component('semidonut', semidonut_component);

import zoomable_component from '@/components/charts/zoomable_component.vue';
Vue.component('zoomable', zoomable_component);

import annotated_component from '@/components/charts/annotated_component.vue';
Vue.component('annotated', annotated_component);

import radar_component from '@/components/charts/radar_component.vue';
Vue.component('radar', radar_component);

import polararea_component from '@/components/charts/polararea_component.vue';
Vue.component('polararea', polararea_component);

import donut_component from '@/components/charts/donut_component.vue';
Vue.component('donut', donut_component);

import radialbar_component from '@/components/charts/radialbar_component.vue';
Vue.component('radialbar', radialbar_component);

import gauge_component from '@/components/charts/gauge_component.vue';
Vue.component('gauge', gauge_component);

import scatter_component from '@/components/charts/scatter_component.vue';
Vue.component('scatter', scatter_component);

import timeline_component from '@/components/charts/timeline_component.vue';
Vue.component('timeline', timeline_component);

import heatmap_component from '@/components/charts/heatmap_component.vue';
Vue.component('heatmap', heatmap_component);

import areachart_component from '@/components/charts/areachart_component.vue';
// import { api_URL } from '../../csg-wp-api';
Vue.component('areachart', areachart_component);

import mapbox_component from '@/modules/mapbox/mapbox_component.vue';
Vue.component('mapbox', mapbox_component);

import mapboxgl from "mapbox-gl";
Vue.prototype.$mapboxgl = mapboxgl;

import appcopyright from './components/base/app_copyright.vue';
//import './registerServiceWorker'
Vue.component('appcopyright', appcopyright)

Vue.component('apexchart', VueApexCharts)

// * 
import fertiliser_period from './components/fertiliser_period.vue';
Vue.component('fertiliserperiod', fertiliser_period)
import week_soil_temp from './components/week_soil_temp.vue';
Vue.component('weeksoiltemp', week_soil_temp)
import monthly_soil_temp from './components/monthly_soil_temp.vue';
Vue.component('monthlysoiltemp', monthly_soil_temp)
import soil_moisture from './components/soil_moisture.vue';
Vue.component('soilmoisture', soil_moisture)
import weatherforecast from './components/weatherforecast.vue';
Vue.component('weatherforecast', weatherforecast)
import slurry_period from './components/slurry_period.vue';
Vue.component('slurryperiod', slurry_period)
import soil_temp from './components/soil_temp.vue';
Vue.component('soiltemp', soil_temp)
import leaf_moisture from './components/leaf_moisture.vue';
Vue.component('leafmoisture', leaf_moisture)
import windspeed from './components/windspeed.vue';
Vue.component('windspeed', windspeed)
import local_forecast from './components/local_forecast.vue';
Vue.component('localforecast', local_forecast)
import wind_direction from './components/wind_direction.vue';
Vue.component('winddirection', wind_direction)

// Page Loader
import pageLoader from '@/components/pageLoading.vue';
Vue.component('pageLoader', pageLoader);


Vue.config.productionTip = false

new Vue({
  vuetify,
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
