<template>
     <apexchart :options="chartOptions"  :height="height" :width="width" :series="series" ></apexchart>
</template>

<script>
export default {
    props: {
        width: { type: String, default: '100%' },
        height: { type: String, default: '100%' },
        title: { type: String, default: 'Chart' },
        chartdata: { type: Object, default: function() { return {} } },
    },
    // mounted () {
    //     this.renderChart(this.chartData, this.options)
    

    data: () => ({
        chartOptions: {
            chart: {
                height: 50,
                type: 'bar',
                zoom: { enabled: false },
                // color: [blue]
                toolbar: {
                    show: false
                }
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                }
            },
            dataLabels: { enabled: false },
            stroke: { curve: 'straight' },
            title: { text: '', align: 'left' },
            grid: {
              row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
              },
            },
        },
        series: []
    }),
    methods: {
        updateChart() {
            // console.log('updatechart')
            // console.log('this.chartdata = ' + JSON.stringify(this.chartdata))
            this.series = [];
            this.series.push (this.chartdata);
        }
    },
    mounted () {
        this.chartOptions.title.text = this.title // save prop into title
        this.chartOptions.chart.height = this.height;
        this.chartOptions.chart.width = this.width;
//        this.chartOptions.grid.row.colors[0] = this.$vuetify.theme.themes.light.primary  
        this.updateChart();
// TODO
// change chart colour to primary vuuetify colour
// = this.$vuetify.theme.themes.light.primary
    }
}
</script>