<template>
  <div class="locale-switcher">
    <select v-if="!$vuetify.theme.dark" class="lighttext--text font-weight-bold pl-1 uppercase" label="locale.code" filled color="primary" v-model="$i18n.locale">
      <option class="darktext--text font-weight-bold uppercase" :value="locale.code" v-for="locale in locales" :key="locale.code">
        {{locale.code}}
      <!-- <option class="white--text font-weight-bold px-2" :value="locale.code" v-for="locale in locales" :key="locale.code">
        {{locale.name}}-->
      </option>
    </select>
    <select v-else class="lighttext--text font-weight-bold pl-1 uppercase" label="locale.code" filled color="primary" v-model="$i18n.locale">
      <option class="lighttext--text font-weight-bold uppercase" :value="locale.code" v-for="locale in locales" :key="locale.code">
        {{locale.code}}
      <!-- <option class="white--text font-weight-bold px-2" :value="locale.code" v-for="locale in locales" :key="locale.code">
        {{locale.name}}-->
      </option>
    </select>
  </div>
</template>

<script>
import { getSupportedLocales } from "@/i18n/supported-locales.js"

export default {
  name: "LanguageSelector",
  data: () => ({
    locales: getSupportedLocales(),
    languages: [
      {
        flag: "../assets/flags/en.png",
        code: "EN",
        name: "English",
        locale: "en",
      },
      {
        flag: "../assets/flags/cy.png",
        code: "CY",
        name: "Welsh",
        locale: "cy",
      },
      {
        flag: "../assets/flags/fr.png",
        code: "FR",
        name: "French",
        locale: "fr",
      },
    ],
  }),
};
</script>

<style scoped>
.uppercase {
  text-transform: uppercase;
}
</style>
