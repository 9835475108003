<template>
    <div class="d-flex align-center flex-column justify-start fill-height" style="width: 100% !important;">
        <v-container fluid width=100% class="lightgrey pa-5">
            <v-card flat color="lightgrey" height=100%>
                <v-row class="pt-1 d-flex align-center" >
                    <v-col cols="10" class="d-flex align-center" >
                         <div><h3 class="font-weight-normal darktext--text">{{ $t('headings.station') }} <span v-if="JSON.stringify(station) !== '{}'"> - {{ station['station_name'] }}</span></h3></div>   
                    </v-col>
                </v-row>
            </v-card>
        </v-container>
         <v-row class="d-flex align-center justify-start">
            <v-col cols="2" xs="2">
                <v-text-field dense no-details filled :label="$t('filters.search')" v-model="filters.search" background-color="white" />
            </v-col>
            <v-spacer />
            <v-col cols="2" xs="2" class="text-right d-flex align-center justify-end">
                <v-btn @click="columnsDialog = !columnsDialog">Headers</v-btn>
            </v-col>
        </v-row>
        <v-data-table
            :headers="computedHeaders"
            :items="computedTableData"
            :items-per-page="10"
            item-key="id"
            class="elevation-0"
            hide-default-header
            :search="filters.search"
            >
            <!-- // HEADERS -->                        
            <template v-slot:header="{ props }">
                <th :align="head.align" class="pa-4 font-weight-medium" style="font-size:12px !important;" v-for="(head, index) in props.headers" :key="index">
                    {{ $t(`fields.${head.text}`) }}
                </th>
            </template>     
            <!-- // NAME -->
            <template v-slot:[`item.station_name`]="{ item }">
                <span class="d-inline-block text-truncate" style="max-width: 200px;">{{ item.station_name }}</span>
            </template>
            <!-- // View DataStructure -->
            <template v-slot:[`item.actions`]="{ item }">
               <v-btn @click="showData((item.data))">Data</v-btn>
               <v-btn @click="showDataStructures(GET_weatherDataCatalogBySensorType(item.sensor_type))">Data Structure</v-btn>
            </template>
            <!-- // Resolve Sensor Name -->
            <template v-slot:[`item.sensor_name`]="{ item }">
                {{ GET_weatherDataCatalogBySensorType(item.sensor_type).product_name }}
            </template>

        </v-data-table>
        <!-- // * DYNAMIC DATA STRUCTURE DIALOG -->        
        <v-dialog v-model="dataStructureDialog" scrollable max-width="800px">
            <v-card>
                <v-card-title>{{$t('headings.dataStructures')}}</v-card-title>
                <v-divider></v-divider>
                <v-card-text style="height: 500px;">
                    <pre>{{ dataStructure }}</pre>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="text-right">
                    <v-spacer />
                    <v-btn color="primary" text @click="dataStructureDialog = false">Close</v-btn>
                </v-card-actions>                
            </v-card>
        </v-dialog>
        <!-- // * DYNAMIC DATA DIALOG -->        
        <v-dialog v-model="dataDialog" scrollable max-width="800px">
            <v-card>
                <v-card-title>{{$t('headings.data')}}</v-card-title>
                <v-divider></v-divider>
                <v-card-text style="height: 500px;">
                    <pre>{{ data }}</pre>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="text-right">
                    <v-spacer />
                    <v-btn color="primary" text @click="dataDialog = false">Close</v-btn>
                </v-card-actions>                                
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'; // Map Actions / Getters
    export default {
        data: () => ({
            tableData: [],
            filters: {
                search: '',
                sortAsc: true,
            },
            sortBy: 'sensor_type',
            columnsDialog: false,
            dataDialog: false,
            data: [],
            dataStructureDialog: false,
            dataStructure: {},
            dataStructures: [],
            headers: [
                { text: 'lsid', value: 'lsid', sortable: false, align: 'start', hidden: false, hide: false },
                { text: 'sensor_type', value: 'sensor_type', sortable: false, align: 'start', hidden: false, hide: false },
                { text: 'sensor_name', value: 'sensor_name', sortable: false, align: 'start', hidden: false, hide: false },
                { text: 'data_structure_type', value: 'data_structure_type', sortable: false, align: 'start', hidden: false, hide: true },
                { text: 'actions', value: 'actions', sortable: false, align: 'end', hidden: false, hide: true }
            ],
            station: {},


        }),
        computed: {
            ...mapGetters({
                GET_weatherDataStations: 'GET_weatherDataStations',
                GET_weatherDataStationById: 'GET_weatherDataStationById',
                GET_weatherDataCatalog: 'GET_weatherDataCatalog',
                GET_weatherDataCurrent: 'GET_weatherDataCurrent',
                GET_weatherDataCatalogBySensorType: 'GET_weatherDataCatalogBySensorType'
            }),
            // Computed Table Headers            
            computedHeaders() {
                var t = this;
                var headers = t.headers;
                return (t.$filter(headers, {
                    hidden: false, // filter headers based on initial hidden value
                }));
            },
            // Computed Table Data
            computedTableData() {
                var t = this;
                var tableData = t.tableData;
                // // Filter by status
                // tableData = tableData.filter(function(item) {
                //     return t.filter.status.includes(item[t.collectionItem+'Status']);
                // });
                // // Filter by type
                // if ((t.filter.type !== '') && (t.filter.type !== undefined) && (t.filter.type !== null)) {                    
                //     tableData = tableData.filter(function(item) {
                //         return t.filter.type === item[t.collectionItem + 'Type'];
                //     });
                // }
                // // Filter by Search Mobile                
                // if (t.search !== '' && t.$vuetify.breakpoint.smAndDown) {
                //     tableData = tableData.filter(function(item) {
                //         var itemName = item[t.collectionItem+'Name'].toUpperCase();
                //         var search = t.search.toUpperCase();
                //         return itemName.includes(search)
                //     });
                // }
                var tableDataList = tableData.map(e => {
                    return {
                        lsid: e.lsid,
                        sensor_type: e.sensor_type,
                        data_structure_type: e.data_structure_type,
                        data: e.data
                    };
                });
                // Sort the Table by the Field in the Sort By Filter
                var sortResults = t.$sortBy(tableDataList, t.sortBy)
                if (!t.filters.sortAsc) {sortResults.reverse()}
                return (sortResults);      
            }            

        },
        methods: {
            showDataStructures(dataStructures) {
                this.dataStructure = dataStructures;
                this.dataStructureDialog = true;
            },
            hideDatastructures() {
                this.dataStructureDialog = false;
                this.dataStructure = {};
            },
            showData(data) {
                this.data = data;
                this.dataDialog = true;
            },
            hideData() {
                this.dataDialog = false;
                this.data = {};
            }            

        },
        async mounted() {
            var t = this;
            // console.log('router ' + this.$route.path)
            var routerPath = t.$route.path.split('/')
            var stationId = routerPath[3];
            t.station = await t.GET_weatherDataStationById(stationId);
            await t.MIX_weatherDataCurrent(stationId) 
            t.tableData = await t.GET_weatherDataCurrent;
            // t.station = t.$find(t.GET_weatherDataStations, function(station) {
            //     return station.id === stationId
            // })
            //await t.MIX_weatherDataCatalog();
        //t.tableData = t.GET_weatherDataCatalog;
        }

}
</script>