<template>
    <div class="d-flex align-center flex-column justify-start fill-height" style="width: 100% !important;">
        <v-container fluid height="160" width=100% style="padding: 0px !important;">
            <v-card tile elevation="0" color="lightgrey">
                <v-row class="pt-6">
                    <v-col cols="2" xs="3" sm="1" class="pl-6 pr-0">
                        <v-icon size="40" color="primary" position="left">icons8-male-user</v-icon>
                    </v-col>
                    <v-col cols="9" xs="9" sm="11" class="pl-0 ml-0 text-left">
                        <div class="text-h5 pl-0 pt-1 primary--text font-weight-thin">{{ $t('profile.welcome') }}<span class="font-weight-bold">{{ GET_currentUser.userFirstName }}</span></div>
                    </v-col>
                </v-row>
            </v-card>
            <v-row class="pt-2 px-2" no-gutters>    
                <v-col v-if="editFlag === false" cols="6" xs="6" sm="10" md="11" class="px-2 mt-2">
                    <div class="text-h5 font-weight-thin darktext--text text--darken-2 pl-1 pt-4">{{ $t('profile.profile') }}</div>
                </v-col>
                <v-col cols="6" xs="6" sm="2" md="1" v-if="editFlag === false" class="px-2 mt-2">
                  <v-btn block depressed large  @click="editProfile()" color="primary" class="mt-3 lighttext--text">{{ $t('profile.edit') }}</v-btn>
                </v-col>
                <v-col cols="12" xs="12" sm="6" md="12" lg="12">
                    <v-row no-gutters>
                        <v-col v-if="editFlag === false" cols="12" xs="12" sm="8" md="6" class="pa-2">
                            <v-card color="accent" flat width="100%" height="100" class="d-flex align-center  justify-center mb-2"> 
                                <v-row class="flex-column align-start justify-center pl-5"> 
                                    <div class="grey--text font-weight-regular pl-1 pt-1"><span class="text-h7 font-weight-bold darktext--text">{{ $t('profile.name') }}:</span> {{ GET_currentUser.userFirstName }}</div>
                                    <div class="grey--text font-weight-regular pl-1 pt-1"><span class="font-weight-bold darktext--text">{{ $t('profile.surname') }}:</span> {{ GET_currentUser.userLastName }}</div>
                                    <div class="grey--text font-weight-regular pl-1 pt-1"><span class="font-weight-bold darktext--text">{{ $t('profile.email') }}:</span> {{ GET_currentUser.userEmail }}</div>
                                </v-row>
                            </v-card>
                            <v-card color="accent" flat width="100%" height="40" class="d-flex align-center justify-center"> 
                                <v-row class="flex-column align-start justify-center pl-5"> 
                                    <div class="grey--text font-weight-regular pl-1 pt-1"><span class="font-weight-bold darktext--text">{{ $t('profile.organisation') }}:</span> {{ GET_currentUser.userOrganisation }}</div>
                                </v-row>
                            </v-card>
                        </v-col>
                        <transition name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut" mode="out-in">
                            <v-col cols="12" xs="12" sm="6" md="4" lg="4" xl="4" class="text-left mt-3" v-if="editFlag">
                                <div class="primary card ma-2 pa-6 mt-2" style="border-radius: 4px !important">
                                    <v-row align="center">
                                        <div class="text-left lighttext--text text-h6 ml-4">{{ $t('profile.editProfile') }}</div>
                                        <v-col cols="12" class="text-left">
                                            <v-form ref="userProfile" v-model="valid" lazy-validation>
                                                <v-text-field class="mb-1" hide-details="auto" :rules="[(v) => !!v || 'Add Name.']" required :label="$t('profile.name')" v-model="newProfile.userFirstName" dark filled background-color="primary darken-2"></v-text-field>
                                                <v-text-field class="mb-1" hide-details="auto" :rules="[(v) => !!v || 'Add Surname.']" required :label="$t('profile.surname')" v-model="newProfile.userLastName" dark filled background-color="primary darken-2"></v-text-field>
                                                <v-text-field class="mb-1" hide-details="auto" :rules="[(v) => !!v || 'Add Email.']" required :label="$t('profile.email')" v-model="newProfile.userEmail" dark filled background-color="primary darken-2"></v-text-field>
                                                <v-text-field class="mb-1" hide-details="auto" :rules="[(v) => !!v || 'Add Organisation.']" required :label="$t('profile.organisation')" v-model="newProfile.userOrganisation" dark filled background-color="primary darken-2"></v-text-field>
                                            </v-form>
                                        </v-col>
                                        <v-col cols="12" class="d-flex align-center justify-end">
                                            <div><v-btn depressed @click="editFlag = false" class="accent darktext--text">{{ $t('buttons.close') }}</v-btn></div>
                                            <v-spacer />
                                            <div><v-btn depressed @click="updateProfile()" class="accent darktext--text">{{ $t('profile.updateProfile') }}</v-btn></div>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-col>
                        </transition>
                    </v-row>
                   
                  </v-col>
                  <v-col cols="12" class="px-2">
                    <!-- <v-btn block depressed large v-if="editFlag === false" @click="MIX_go('/activity')" color="primary" class="mt-3">VIEW ACTIVITY</v-btn> -->
                    <v-btn block depressed large  v-if="editFlag === false" @click="MIX_go('/')" color="primary" class="mt-3 lighttext--text">{{ $t('buttons.back') }}</v-btn>
                  </v-col>
            </v-row>
        </v-container>
        <v-spacer></v-spacer>
        <div>User since: {{ userCreatedOn() }}</div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: 'Profile',
  computed: {
    ...mapGetters({
      GET_currentUser: 'GET_currentUser',
      GET_authToken: 'GET_authToken'
    }),
    user: {
      get () {
        const user = this.GET_currentUser;
        // console.log("user = " + JSON.stringify(user, null, 2))
        return user;
      }
    }
  },
  data: () => ({
    token: '',
    valid: true,
    editFlag: false,
    newProfile: {},
  }),
  watch: {
    GET_authToken: {
      handler() {
        this.token = this.GET_authToken;
      },deep: true
    },
    GET_currentUser: {
      handler() {
        // this.token = JSON.parse(JSON.stringify(this.GET_currentUser)).stsTokenManager.accessToken
      }, deep: true
    }
  },
  methods: {
    updateProfile(){
      var self = this;
      self.editFlag = false;
      if(self.$firebase.auth.currentUser) {
        var document = JSON.parse(JSON.stringify(self.newProfile));
        return new Promise( function(resolve) {
          self.$firebase.db.collection("users").doc(document.id).set(document)
          .then(() => {
            resolve ({ code: 1, message: 'Document Updated Successfully in Database', data: null, error: null })
          })
          .catch((error) => {
            resolve ({ code: -1, message: 'Error Updating Document in Database', data: null, error: error })
          });
        })
      }
    },
    cancel(){
      var self = this;
      self.editFlag = false;
    },
    editProfile(){
      var self = this;
      self.editFlag = true;
      self.newProfile = self.user;
    },
    logout() {
      this.MIX_authToken = '',
      this.$firebase.auth.signOut().then(() => {
      this.showPageElements = false;
      this.$router.push("/login").catch((err) => {
        console.log("Route error: " + err);
      });
      this.$store.commit("SET_currentUser", null);
    });
    },
    sendRequest: async function () {
      var url = 'https://api.agricultureresearch.wales/api/v1/forecast/?lat=51.869286&lon=-4.0443335'
      this.$axios
        .get(url, {
            headers: {
                authtoken: this.token
            }
        })
        .then((response) => {
            console.log(response.data.message)
        })
        .catch ((axiosError) => {
            console.log('ERROR OCCURED: \n ' + axiosError)
        })
    },  
    userCreatedOn: function() {
        const timestamp = this.user.createdDateTime;
        const date = this.MIX_formatDateTime(timestamp, 'x', 'DD/MM/YYYY')

      return date;  
    },
    async mounted() {
      this.token = this.GET_authToken;
    },
  },
}
</script>