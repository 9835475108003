<!--
    soil_temperature.vue
    created by: Emilie@vindico.net
    // TODO


-->
<template>
	<!-- LOOP THROUGH SOIL DATA -->
	<v-card flat color="accent" width="100%" height="130" class="mb-4 pa-2">
                {{ $t(currentdata.title) }}
		<v-row class="" no-gutters>
			<v-col v-for="(item, index) in currentdata.value" :key="index" class="lg5-custom">
				<v-card  :color="item.status" rounded flat height="50" class="ma-1">
					<v-row no-gutters>
						<v-col cols="12" class="">
							<div>
								<div class="font-weight-normal text-center body-1 white--text">{{ MIX_formatDateTime(item.ts, 'X', 'ddd') }}</div>
								<div class="body-2 text-center font-weight-light white--text">{{ MIX_formatDateTime(item.ts, 'X', 'DD/MM') }}</div>
							</div>
						</v-col>
					</v-row>
					<v-card color="accent" class="rounded-0 mt-3" width="100%" height="40" flat>
						<v-row class="justify-center pt-1"><h3>{{ item.value }}{{ item.unit }}</h3></v-row>
					</v-card>
				</v-card>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
	export default {
		props: {
			currentdata: {
				type: Object,
				required: false,
				default: function() {
					return {
						title: "Card Title",
						subtitle: "Card Sub Title",
						value: [],
						status: "grey",
					};
				},
			},
		},
        mounted() {
            // console.log('currentdata week= ' + JSON.stringify(this.currentdata, null, 2))
        }
	};

</script>

<style>
	.flex.lg5-custom {
		width: 20%;
		max-width: 20%;
		flex-basis: 20%;
	}
</style>
