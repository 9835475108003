import { mapActions } from 'vuex';
const mapbox_mixins = {
  data: () => ({
    geoApi: 'https://api.agricultureresearch.wales/api/v1/',
    location: null,
    gettingLocation: false,
    errorStr: null,
  }),
  methods: {
    ...mapActions({
      ACT_marker_create: 'mapbox_store/ACT_marker_create',
      ACT_marker_update: 'mapbox_store/ACT_marker_update',
      ACT_marker_delete: 'mapbox_store/ACT_marker_delete',
      ACT_nearest_zone: 'mapbox_store/ACT_nearest_zone',
    }),
    MIX_update_map() {
      // center, mapstyle, zoom
      var payload = {};
      var mapTheme = this.$vuetify.theme.dark === true ? 'mapbox://styles/mapbox/dark-v10' : 'mapbox://styles/mapbox/streets-v11';
      payload.center = [-4.033000, 51.804960];
      payload.style = mapTheme;
      payload.zoom = 9;
      this.$store.dispatch('mapbox_store/ACT_update_map', payload);
      // console.log('MIX_update_map');
    },
    ACT_update_layerSelected(payload) {
      this.$store.dispatch('mapbox_store/ACT_update_layerSelected', payload);
    },
    //        MIX_
    MIX_marker_create(id, payload) {
      payload.id = id;
      this.ACT_marker_create(payload);
    },
    MIX_marker_update(id, payload) {
      payload.id = id;
      this.ACT_marker_update(payload);
    },
    MIX_marker_delete(id, payload) {
      payload.id = id;
      this.ACT_marker_delete(payload);
    },
    MIX_nearest_zone: async function (lat, lon)  {
      const t = this;
      const nearestZone = await this.MIX_axiosGet(`${t.geoApi}nearestzone/?lat=${lat}&lon=${lon}`)
      if (nearestZone.code === 1) {
          t.ACT_nearest_zone(nearestZone.data);
      }
      return nearestZone.data[0];
    },
  } 
};

export default {
  install(Vue) {
    Vue.mixin(mapbox_mixins);
  },
};
