var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-center flex-column justify-start fill-height",staticStyle:{"width":"100% !important"}},[_c('v-container',{staticClass:"lightgrey pa-5",attrs:{"fluid":"","width":"100%"}},[_c('v-card',{attrs:{"flat":"","color":"lightgrey","height":"100%"}},[_c('v-row',{staticClass:"pt-1 d-flex align-center"},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"10"}},[_c('div',[_c('h3',{staticClass:"font-weight-normal darktext--text"},[_vm._v(_vm._s(_vm.$t('headings.station'))+" "),(JSON.stringify(_vm.station) !== '{}')?_c('span',[_vm._v(" - "+_vm._s(_vm.station['station_name']))]):_vm._e()])])])],1)],1)],1),_c('v-row',{staticClass:"d-flex align-center justify-start"},[_c('v-col',{attrs:{"cols":"2","xs":"2"}},[_c('v-text-field',{attrs:{"dense":"","no-details":"","filled":"","label":_vm.$t('filters.search'),"background-color":"white"},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"text-right d-flex align-center justify-end",attrs:{"cols":"2","xs":"2"}},[_c('v-btn',{on:{"click":function($event){_vm.columnsDialog = !_vm.columnsDialog}}},[_vm._v("Headers")])],1)],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.computedHeaders,"items":_vm.computedTableData,"items-per-page":10,"item-key":"id","hide-default-header":"","search":_vm.filters.search},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return _vm._l((props.headers),function(head,index){return _c('th',{key:index,staticClass:"pa-4 font-weight-medium",staticStyle:{"font-size":"12px !important"},attrs:{"align":head.align}},[_vm._v(" "+_vm._s(_vm.$t(("fields." + (head.text))))+" ")])})}},{key:"item.station_name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"200px"}},[_vm._v(_vm._s(item.station_name))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{on:{"click":function($event){_vm.showData((item.data))}}},[_vm._v("Data")]),_c('v-btn',{on:{"click":function($event){_vm.showDataStructures(_vm.GET_weatherDataCatalogBySensorType(item.sensor_type))}}},[_vm._v("Data Structure")])]}},{key:"item.sensor_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.GET_weatherDataCatalogBySensorType(item.sensor_type).product_name)+" ")]}}],null,true)}),_c('v-dialog',{attrs:{"scrollable":"","max-width":"800px"},model:{value:(_vm.dataStructureDialog),callback:function ($$v) {_vm.dataStructureDialog=$$v},expression:"dataStructureDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('headings.dataStructures')))]),_c('v-divider'),_c('v-card-text',{staticStyle:{"height":"500px"}},[_c('pre',[_vm._v(_vm._s(_vm.dataStructure))])]),_c('v-divider'),_c('v-card-actions',{staticClass:"text-right"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dataStructureDialog = false}}},[_vm._v("Close")])],1)],1)],1),_c('v-dialog',{attrs:{"scrollable":"","max-width":"800px"},model:{value:(_vm.dataDialog),callback:function ($$v) {_vm.dataDialog=$$v},expression:"dataDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('headings.data')))]),_c('v-divider'),_c('v-card-text',{staticStyle:{"height":"500px"}},[_c('pre',[_vm._v(_vm._s(_vm.data))])]),_c('v-divider'),_c('v-card-actions',{staticClass:"text-right"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dataDialog = false}}},[_vm._v("Close")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }