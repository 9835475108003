<template>
	<v-card flat color="accent" width="100%" min-height="80" class="mb-4 pa-2">
		<v-row class="pa-2 d-flex align-center">
			<v-col cols="2">
				<v-icon size="50" position="left" :class="currentdata.status + '--text'" class="">{{ $t(currentdata.icon) }}</v-icon>
			</v-col>
			<v-col cols="8" class="d-flex align-center">
				<div class="font-weight-regular pl-2">
                    {{ $t(currentdata.title) }} 
                    <span class="font-weight-bold darktext--text"> {{ $t(currentdata.unit) }}</span>
                    <div class="caption grey--text">{{ $t(currentdata.subtitle) }}</div>
                    <div v-if="currentdata.status === 'error' && currentdata.title == 'weather.soilMoisture'" class="error--text caption font-weight-bold">Stop and Think. Re-evaluation Needed</div>
                    <div v-else-if="currentdata.status === 'warning' && currentdata.title == 'weather.soilMoisture'" class="warning--text caption font-weight-bold">Proceed with Caution</div>
                    <div v-else-if="currentdata.status == 'success' && currentdata.title == 'weather.soilMoisture'" class="success--text caption font-weight-bold">Good to Go</div>
				</div>
			</v-col>
			<v-col cols="2" class="d-flex justify-center align-center">
				<h2 v-if="currentdata.value !== 0" :class="currentdata.status + '--text'" class="pr-3">{{ $t(currentdata.value) }}</h2>
				<h2 v-else-if="currentdata.value === 0" :class="currentdata.status + '--text'" class="pr-3">{{ currentdata.value }}</h2>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
	export default {
		name: 'dash_card',
		props: {
			currentdata: {
				type: Object,
				required: false,
				default: function() {
					return {
						icon: "icons8-calendar",
						title: "Dash Title",
                        unit: "Unit",
						subtitle: "Dash Sub Title",
						value: "-",
						status: "grey",
					};
				},
			},
		},
	};
</script>
