<template>
  <!-- <div class="home viewport-maxwidth"> -->
  <div class="d-flex justify-center" style="width: calc(100vw - 30px) !important;">
    <v-progress-linear v-if="loading === true" color="primary" indeterminate></v-progress-linear>
    <!-- start of USER-INPUT -->
    <v-row class="mt-0 justify-center">
      <v-col cols="12" lg="12" md="12" sm="12" xl="12" xs="12">
        <v-window v-model="step">
          <!-- STEP 1 - CONTACT DETAILS -->
          <v-window-item :value="1">
            <v-form ref="registerUser" v-model="valid" lazy-validation>
              <v-text-field v-model.trim="form.userEmail" :background-color="inputBackgroundColor" :label="$t('fields.email')" :rules="emailRules"
                            class="mb-1" filled hide-details="auto" required></v-text-field>
              <v-text-field v-model="form.userFirstName" :background-color="inputBackgroundColor" :label="$t('fields.firstName')" :rules="firstNameRules"
                            class="mb-1" filled hide-details="auto"
                            required></v-text-field>
              <v-text-field v-model="form.userLastName" :background-color="inputBackgroundColor" :label="$t('fields.lastName')" :rules="lastNameRules"
                            class="mb-1" filled hide-details="auto"
                            required></v-text-field>
              <v-text-field v-model="form.userOrganisation" :background-color="inputBackgroundColor" :label="$t('fields.organisation')" class="mb-1"
                            filled hide-details="auto"></v-text-field>

              <v-text-field v-if="$route.params.id ==='admin'" v-model="adminRegCode" :background-color="inputBackgroundColor"
                            class="mb-1" filled label="Admin Registration Code"/>

              <div class="d-flex align-start mt-5">
                <div>
                  <!-- <v-checkbox color="primary" class="px-4" v-model="form.userPrivacyPolicyConsent" hide-details ></v-checkbox> -->
                  <v-checkbox v-model="form.userPrivacyPolicyConsent" :rules="[(v) => !!v]" class="px-4" color="primary"
                              hide-details="auto" required></v-checkbox>
                </div>
                <div class="text-left pt-2">
                  {{ $t('firebaseAuth.register.terms') }} <span class="primary--text font-weight-bold"
                                                                @click='MIX_terms'>{{
                    $t('firebaseAuth.register.termsLink')
                  }}</span>
                </div>
                <!-- <pre>{{ form.userPrivacyPolicyConsent }}</pre> -->
              </div>
            </v-form>
            <!--NEXT-BUTTON-->
            <div class="text-right">
            </div>

            <v-row class="my-4">
              <v-col cols="6">
                <v-btn block class="mt-3 grey lighten-2 darkgrey--text" depressed large @click="MIX_back()">
                  {{ $t('buttons.back') }}
                </v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="6">
                <v-btn :disabled="loading1" :loading="loading1" block class="mt-3 primary lighttext--text" depressed large
                       @click="next">{{ $t('buttons.next') }}
                </v-btn>
              </v-col>
            </v-row>


          </v-window-item>
          <!-- STEP 2 - PASSWORD-WINDOW-->
          <v-window-item :value="2">
            <v-form ref="userPassword" v-model="valid1" lazy-validation>
              <v-text-field v-model.trim="password" :append-icon="show ? 'icons8-eye' : 'icons8-invisible'" :background-color="inputBackgroundColor" :label="$t('fields.password')"
                            :rules="passwordRules" :type="show ? 'text' : 'password'"
                            class="mb-1" filled
                            hide-details="auto" required @click:append="show = !show"></v-text-field>
              <v-text-field v-model.trim="confirmPassword" :append-icon="show1 ? 'icons8-eye' : 'icons8-invisible'" :background-color="inputBackgroundColor" :label="$t('fields.confirmPassword')"
                            :rules="passwordValidationRule" :type="show1 ? 'text' : 'password'"
                            class="mb-2" filled
                            hide-details="auto" required @click:append="show1 = !show1"></v-text-field>
            </v-form>
            <!--Register button -->
            <v-row class="my-4">
              <v-col cols="6">
                <v-btn block class="mt-3 grey lighten-2 darkgrey--text" depressed large @click="step--">
                  {{ $t('buttons.back') }}
                </v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="6">
                <v-btn :disabled="loading" :loading="loading" block class=" mt-3 primary lighttext--text" depressed large
                       @click="register">{{ $t('buttons.register') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-window-item>
          <!-- Error message -->
          <v-window-item :value="3">
            <div v-if="accountCreated === true" class="pa-4 text-center">
              <v-progress-linear color="primary" indeterminate></v-progress-linear>
              <h3 class="title font-weight-light mb-2">Welcome</h3>
              <span class="caption grey--text">Thanks for signing up! You will be redirected to the home page</span>
            </div>
            <div v-else class="pa-4 text-center">
              <h3 class="title font-weight-light mb-2">Something went wrong</h3>
              <h4 v-if="registerErrorMessage != null">{{ registerErrorMessage }}</h4>
              <span class="caption grey--text">Try again!</span>
            </div>
          </v-window-item>
        </v-window>
        <!-- <br /> -->
        <!-- Password Confirmation Rule = {{ passwordConfirmationRule }} -->
      </v-col>
    </v-row>
    <!-- end of USER-INPUT -->

  </div>
</template>

<script>
// import PrivacyTermsConditions from "../../components/termsAndConditions.vue";

export default {
  name: "Register",
  components: {
    // PrivacyTermsConditions,
  },
  data() {
    return {
      inputBackgroundColor: 'lighttext',
      step: 1,
      valid: true,
      valid1: true,
      show: false,
      show1: false,
      adminRegCode: '',
      form: {
        userId: '',
        userLevel: 'CU',
        //userType: 'Client User',
        userEmail: '',
        userFirstName: '',
        userLastName: '',
        userOrganisation: '',
        userPrivacyPolicyConsent: false,
        createdDateTime: '',
      },
      password: '', // password input
      confirmPassword: '', // check password
      accountCreated: true, // show thank you/ try gain message at the end
      loading: false,
      loader1: null,
      loading1: false,
      privacyDialog: false,
      lookupValues: [],

      // * VALIDATIONS RULES
      firstNameRules: [(v) => !!v || 'First name is required'],
      lastNameRules: [(v) => !!v || 'Last name is required'],
      confirmRules: [(v) => !!v || 'Confirm password required'],
      privacyPolicyConsent: [(v) => !!v || 'Please Select'],
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      passwordRules: [(v) => !!v || 'Password is required',
        (v) => v.length >= 8 || 'Password needs to be 8 characters or more'],
      passwordValidationRule: [
        (v) => v === this.password || "Passwords need to match."
      ],
      integration: [],
      registerErrorMessage: null,
    };
  },
  watch: {
    loader1() {
      const l = this.loader1;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 3000);

      this.loader1 = null;
    },
  },
  computed: {},
  methods: {
    next: async function () {
      if (this.$refs.registerUser.validate()) {
        if (this.$route.params.id === 'admin') {
          let result = await this.MIX_FIREBASE_checkAdminRegCode(this.adminRegCode)
          if ((result.code === 1) && (result.data === true)) {
            this.form.userLevel = 'SA'
            this.step++; // * move to the next step
            // console.log('this.form.userLevel = ' + this.form.userLevel)
          } else {
            this.MIX_alert(-1, 'Admin Registration Code is Incorrect, please try again.')
          }
        } else {
          this.step++; // * move to the next step
        }
      } else {
        // ! did not pass validation
        this.MIX_alert(-1, 'Fill out all the fields correctly in order to proceed.', null, null);
      }
    },
    register() {
      var t = this;
      if (this.$refs.userPassword.validate()) {
        this.step++; // * move to the next step
        t.form.userEmail = t.form.userEmail.toLowerCase();
        // * create user
        t.$firebase.auth.createUserWithEmailAndPassword(t.form.userEmail, t.password).then((user) => {
          t.form.userId = user.user.uid; // * assign firebase auth user id to firebase user in collection
          t.form.createdDateTime = t.$moment().format('x');
          // * add to the users collection
          /* eslint-disable no-unused-vars */
          t.$firebase.db.collection('users').doc(user.user.uid).set(t.form).then((Result) => {
            // console.log('result: ' + Result);
          })
              .catch((err) => {
                console.log(err);
              });
          /* eslint-enable no-unused-vars */

          t.accountCreated = true;
          // * assign this user to the current logged in user
          //t.$store.commit('MUT_FIREBASE_currentUser', user);
          this.MIX_alert(1, 'Your account was created.', null, null);

          // * send verification userEmail to user
          var currentUser = t.$firebase.auth.currentUser;
          currentUser.sendEmailVerification().then(function () {
            t.MIX_alert(2, 'An account verification email has been sent to you.', null, null);
          })
              .catch(function (error) {
                // ! verification email error
                console.log('send verification email error: ' + error);
                t.MIX_alert(-1, error, null, null);
              });

          // * redirect user to homepage
          setTimeout(function () {
            t.$router.push('/').catch(error => {
              console.log(error.message)
            });
          }, 1500); // ? added time out to allow the current user to be set
        })
            .catch((err) => {
              // ! firebase registering error
              t.registerErrorMessage = err;
              t.accountCreated = false;
              console.log('firebase registering error: ' + err);
              t.MIX_alert(-1, err, null, null);
            });
      } else {
        // ! user did not pass input validation
        t.accountCreated = false;
        t.MIX_alert(-1, 'Fill out all the fields correctly in order to proceed.', null, null);
      }
    },
  },
};
</script>

<style scoped>
/* .v-input--selection-controls {
    margin-top: 0px;
    padding-top: 0px;
} */
</style>

