<template>
     <apexchart type="pie" :options="chartOptions" :height="height" :width="width" :series="series" ></apexchart>
</template>

<script>
export default {
    props: {
        width: { type: String, default: '100%' },
        height: { type: String, default: '100%' },
        title: { type: String, default: 'Chart Title' },
        chartdata: { type: Object, default: function() { return {} } }
    },
    

    data: () => ({
        series: [],
        chartOptions: {
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May'],
            chart: {
                height: 50,
                type: 'pie',
                fill: {
                    colors: ['#9c27b0']
                },
            },
            title: { text: 'Pie Chart', align: 'left' },
            // responsive: [{
                // breakpoint: 480,
                // options: {
                    // chart: {
                    //   width: 200
                    // },
                    // legend: {
                    //   position: 'bottom'
                    // }
                // }
            // }]
        },
    }),
    methods: {
        updateChart() {
            // console.log('updatechart')
            // console.log('this.chartdata pie = ' + JSON.stringify(this.chartdata))
            this.series = [];
            this.series = this.chartdata.data;
        }
    },
    mounted () {
        this.chartOptions.title.text = this.title // save prop into title
        this.chartOptions.chart.height = this.height;
        // this.series = this.chartdata;
        // this.chartOptions.chart.width = this.width;
        this.chartOptions.labels = this.labels;
        this.chartOptions.chart.fill.colors[0] = this.$vuetify.theme.themes.light.primary  
        this.updateChart();
    }
}
</script>