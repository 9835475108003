<template>
    <div class="d-flex align-start flex-column justify-start fill-height" style="width: 100% !important;">
        <v-row class="pa-0" style="height: calc(28vh) !important">
            <v-col cols="12" style="height: calc(5vh) !important">
                <v-img v-if="$vuetify.breakpoint.xsOnly" cover class="align-start pa-0 ma-0 justify-start" style="width: calc(100vw) !important; height: calc(25vh) !important" :src="require('@/assets/tractor_slurry.png')"></v-img>
                <v-img v-if="$vuetify.breakpoint.smOnly" cover class="align-start justify-start" style="width: calc(100vw) !important; height: calc(25vh) !important" :src="require('@/assets/tractor_slurry.png')"></v-img>
                <v-img v-if="$vuetify.breakpoint.mdAndUp" cover class="align-start pa-0 ma-0 justify-start" style="width: calc(100vw) !important; height: calc(25vh) !important" :src="require('@/assets/tractor_slurry.jpeg')"></v-img>            
            </v-col>
        </v-row>
        <v-row>
            <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" style="width: calc(98vw) !important;" class="justify-start ma-0 pb-0 pt-2">
                <div class="text-h5 px-4 primary--text font-weight-thin">{{ $t('home.welcomeTo') }}<br /><span class="font-weight-bold">{{ $t('home.tywiWeatherProject') }}</span></div>
                <div class="text-h7 px-4 font-weight-normal pa-2">
                    <p class="font-weight-bold">{{ $t('home.description1') }} </p> 
                    <p>{{ $t('home.description2') }}</p>
                </div>
            </v-col>
            <v-col v-else cols="12" style="width: 100% !important;" class="justify-start ma-0 pb-0 pt-2">
                <div class="text-h5 px-4 primary--text font-weight-thin">{{ $t('home.welcomeTo') }}<br /><span class="font-weight-bold">{{ $t('home.tywiWeatherProject') }}</span></div>
                <div class="text-h7 px-4 font-weight-normal pa-2">
                    <p class="font-weight-bold">{{ $t('home.description1') }} </p> 
                    <p>{{ $t('home.description2') }}</p>
                </div>
            </v-col>
        </v-row>
        <v-row style="width: calc(98vw) !important;" height="" class="pa-0 ma-0 align-start justify-start">
            <v-col cols="6" class="text-center ml-0 pa-0">
                <v-img v-if="$vuetify.breakpoint.smAndDown" style="width: calc(40vw) !important;"  class="mx-auto ml-10" :src="require('@/assets/ARC.png')"></v-img>
                <v-img v-if="$vuetify.breakpoint.mdAndUp" style="width: calc(25vw) !important;"  class="mx-auto" :src="require('@/assets/ARC.png')"></v-img>
            </v-col>
            <v-col cols="6" class="text-center ma-0 pa-0">
                <div v-if="$vuetify.breakpoint.smAndDown">
                    <v-img v-if="$vuetify.theme.dark" style="width: calc(40vw) !important;"  class="mx-auto" :src="require('@/assets/wg_funded_negative.png')"></v-img>
                    <v-img v-else style="width: calc(40vw) !important;"  class="mx-auto" :src="require('@/assets/welsh_government.png')"></v-img>
                </div>
                <div v-if="$vuetify.breakpoint.mdAndUp">
                    <v-img v-if="$vuetify.theme.dark" style="width: calc(20vw) !important;"  class="mx-auto mt-7" :src="require('@/assets/wg_funded_negative.png')"></v-img>
                    <v-img v-else style="width: calc(20vw) !important;"  class="mx-auto mt-7" :src="require('@/assets/welsh_government.png')"></v-img>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { LATEST_VERSION } from '../../service-worker'
    export default {
        name: 'Home',
        computed: {
            ...mapGetters({
                GET_currentUser: 'GET_currentUser'
            })
        },
        data: () => ({
           LATESTVERSION: LATEST_VERSION,
        }),
        watch: {
        },
        methods: {
            },

        created () {
        },
        async mounted() {

        }
    }
</script>

<style scoped>
.v-img {
    overflow: hidden;
}
</style>

