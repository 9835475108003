<template>
     <apexchart :options="chartOptions"  :height="height" :width="width" :series="series" ></apexchart>
</template>

<script>
export default {
    props: {
        width: { type: String, default: '100%' },
        height: { type: String, default: '100%' },
        title: { type: String, default: 'Chart Title' },
        chartdata: { type: Object, default: function() { return {} } },
    },
    // mounted () {
    //     this.renderChart(this.chartData, this.options)
    

    data: () => ({
        chartOptions: {
            chart: {
                height: 50,
                type: 'area',
                zoom: { 
                    type: 'x',
                    enabled: true,
                    autoScaleYaxis: true
                },
                toolbar: { autoSelected: 'zoom' },
                fill: { colors: ['#9c27b0'] },
            },
            dataLabels: { enabled: false },
            markers: {
               colors: ['#9c27b0']
            },
            xaxis: {
                type: 'datetime'
            },            
            yaxis: {
                labels: {
                    formatter: function (val) {
                        return (val).toFixed(0);
                    }
                }
            },
            tooltip: {
                shared: false,
                x: {
                    format: 'ddd dd/MM HH:MM',
                }
            },
            fill: { colors: ['#9c27b0'] },
            stroke: { curve: 'straight',  width: 1, colors: ['#9c27b0'] },
            title: { text: '', align: 'left' },
        },
        series: []
    }),
    methods: {
        updateChart() {
            // console.log('updatechart')
            // console.log('this.chartdata = ' + JSON.stringify(this.chartdata))
            this.series = [];
            this.series.push (this.chartdata);
        }
    },
    mounted () {
        this.chartOptions.title.text = this.title // save prop into title
        this.chartOptions.chart.height = this.height;
        this.chartOptions.chart.width = this.width;
        this.chartOptions.chart.fill.colors[0] = this.$vuetify.theme.themes.light.primary  
        this.updateChart();
    }
}
</script>