<template>
     <apexchart :options="chartOptions"  :height="height" :width="width" :series="series" ></apexchart>
</template>

<script>
export default {
    props: {
        width: { type: String, default: '100%' },
        height: { type: String, default: '100%' },
        title: { type: String, default: 'Chart Title' },
        chartdata: { type: Array, default: function() { return {} } },
    },
    // mounted () {
    //     this.renderChart(this.chartData, this.options)
    

    data: () => ({
        chartOptions: {
            chart: {
                height: 50,
                type: 'rangeBar',
                fill: {
                    colors: ['#9c27b0']
                },
            },
            title: { text: 'Timeline Chart', align: 'left' },
            plotOptions: {
              bar: {
                horizontal: true
              }
            },
            xaxis: {
              type: 'datetime'
            }
        },
        series: []
    }),
    methods: {
        updateChart() {
            // console.log('updatechart')
            // console.log('this.chartdata time = ' + JSON.stringify(this.chartdata))
            this.series = [];
            this.series = this.chartdata;
        }
    },
    mounted () {
        this.chartOptions.title.text = this.title // save prop into title
        this.chartOptions.chart.height = this.height;
        this.chartOptions.chart.width = this.width;
        this.chartOptions.chart.fill.colors[0] = this.$vuetify.theme.themes.light.primary  
        this.updateChart();
    }
}
</script>