<template>
  <div class="information">
    <v-row>
      <v-col class="px-5">
        <v-card>
          <v-card-title>
            <div class="text-h5 primary--text">
              {{ $t('information.information') }}
            </div>
          </v-card-title>
          <v-card-text>
            {{ $t('information.infoText1') }}<br>
            {{ $t('information.infoText2') }}<br>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="px-5">
        <v-card>
          <v-card-title>
            <div class="text-h5 primary--text">
              {{ $t('information.keyThresholds') }}
            </div>
          </v-card-title>
          <v-card-text>
            • {{ $t('information.keyThresholds1') }}<br>
            • {{ $t('information.keyThresholds2') }}<br>
            • {{ $t('information.keyThresholds3') }}<br>
            • {{ $t('information.keyThresholds4') }}<br>
            • {{ $t('information.keyThresholds5') }}<br>
            • {{ $t('information.keyThresholds6') }}<br>
            • {{ $t('information.keyThresholds7') }}<br>
            • {{ $t('information.keyThresholds8') }}<br>
            • {{ $t('information.keyThresholds9') }}<br>
            • {{ $t('information.keyThresholds10') }}<br>
            • {{ $t('information.keyThresholds11') }}<br>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="px-5">
        <v-card>
          <v-card-text>
            {{ $t('information.support') }}<a href="mailto:neil.nicholas@colegsirgar.ac.uk">neil.nicholas@colegsirgar.ac.uk</a>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Information"
}
</script>