<template>
  <div class="d-flex align-start flex-column justify-start fill-height" style="width: 100% !important;">
    <v-container class="appbackground" fluid style="padding: 0px !important;" width=100%>
      <!-- Location Selection -->
      <v-card color="lightgrey" flat>
        <v-row class="pt-6">
          <v-col class="px-6 pb-4" cols="12" lg="4" md="4" sm="12" xl="4" xs="12">
            <div class="text-h5 primary--text font-weight-thin">{{ $t('menu.forecast.title') }}</div>
            <span v-if="(JSON.stringify(GET_activeStation) === '{}')" class="body-2 font-weight-regular darktext--text">{{
                $t('menu.forecast.description')
              }}</span>
          </v-col>
        </v-row>
        <v-row class="mx-3">
          <v-col class="pa-0 pt-2" cols="12" lg="4" md="4" sm="12" xl="4" xs="12">
            <v-select v-model="station" :items="GET_weatherDataStations" :label="$t('farming.selectLocation')"
                      background-color="accent" dense item-text="station_name" outlined return-object></v-select>
          </v-col>
        </v-row>
        <v-row class="mx-4 pb-2 caption">
          <div>{{ $t('measurements.lastUpdated') }}:
            {{ MIX_formatDateTime(forecast.current.dt, 'X', 'DD MMM YYYY HH:mm') }}
          </div>
        </v-row>
      </v-card>

      <v-row>
        <!-- Current -->
        <v-col cols="12" lg="4" md="5" sm="12" xs="12">
          <v-row
              v-if="(JSON.stringify(GET_activeStation) !== '{}') && (JSON.stringify(GET_weatherDataForecast) !== '{}')"
              class="pt-4 px-4" no-gutters>
            <v-col cols="12">
              <v-row class="py-2" no-gutters>
                <v-col class="d-flex align-center justify-start" cols=6>
                  <div>
                    <div class="text-h4 primary--text">{{ $t('forecast.nextHour') }}</div>
                    <div class="text-body-1 grey--text text-darken-2 text-capitalize">
                      {{ $t(forecast.hourly[0].weather[0].description) }}
                    </div>
                  </div>
                </v-col>
                <v-col class="d-flex align-center pt-1 justify-end pr-6" cols=6>
                  <v-icon class="text-h5 pr-1 pb-1">icons8-sun</v-icon>
                  <div class="text-h7 font-weight-normal">
                    {{ MIX_formatDateTime(forecast.current.sunrise, 'X', 'HH:mm') }}
                    <span
                        class="text-h7 font-weight-light"> {{
                        MIX_formatDateTime(forecast.current.sunset, 'X', 'HH:mm')
                      }}</span>
                  </div>
                </v-col>
              </v-row>
              <v-row class="" no-gutters>
                <v-col class="d-flex align-center justify-center" cols=6>
                  <div>
                    <v-icon class="primary--text text-h1">{{
                        MIX_weatherForecastIcons(forecast.hourly[0].weather[0].id)
                      }}
                    </v-icon>
                  </div>
                </v-col>
                <v-col class="d-flex align-center justify-end pr-7" cols=6>
                  <div class="font-weight-bold text-center grey--text text-h2">{{
                      forecast.hourly[0].temp.toFixed()
                    }}°
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>

        <!-- HOURLY FORECAST -->

        <v-col cols="12" lg="8" md="7" sm="12" xs="12" class="mt-1">
          <v-row>
            <v-col align="center" class="mt-3 text-h5">
              <div class="text-h5 primary--text font-weight-thin">{{ $t("forecast.hourlyForecast") }}</div>
            </v-col>
          </v-row>
          <v-slide-group>
            <v-row
                v-if="(JSON.stringify(GET_activeStation) !== '{}') && (JSON.stringify(GET_weatherDataForecast) !== '{}')"
                class="mt-4" no-gutters style="width: 100% !important;">
              <v-slide-item v-for="(hour, index) in forecast.hourly.slice(0,24)" :key="index"
                            style="min-width: 120px">
                <template>
                  <v-card class="mx-1 pa-1" color="accent" flat>
                    <v-row>
                      <v-col class="d-flex align-center justify-center" cols="12">
                        <div>
                          <div class="text-h8 darktext--text text-center font-weight-bold">
                            {{ MIX_formatDateTime(forecast.hourly[index].dt, 'X', 'h:ssA') }}
                          </div>
                          <div class="caption grey--text text-center font-weight-normal">
                            {{ $t(forecast.hourly[index].weather[0].description) }}
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="d-flex justify-center align-center" cols="12">
                        <v-icon class="primary--text text-h4">
                          {{ MIX_weatherForecastIcons(forecast.hourly[index].weather[0].id) }}
                        </v-icon>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="d-flex align-center justify-center" cols="12">
                        <div class="font-weight-normal text-center body-2">{{
                            forecast.hourly[index].temp.toFixed()
                          }}°
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="d-flex align-center justify-center" cols="12">
                        <div class="font-weight-normal text-center body-2">
                          <v-icon class="text-h6">
                            {{ MIX_popIcons(forecast.hourly[index].pop) }}
                          </v-icon>
                          {{ (forecast.hourly[index].pop * 100).toFixed() }}%
                        </div>
                      </v-col>
                    </v-row>
                  </v-card>
                </template>
              </v-slide-item>
            </v-row>
          </v-slide-group>
        </v-col>


        <!-- LOOP THROUGH DAILY FORECAST -->
        <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="mt-1">
          <v-row>
            <v-col align="center" class="text-h5">
              <div class="text-h5 primary--text font-weight-thin">{{ $t("forecast.dailyForecast") }}</div>
            </v-col>
          </v-row>
          <v-row
              v-if="(JSON.stringify(GET_activeStation) !== '{}') && (JSON.stringify(GET_weatherDataForecast) !== '{}')"
              class="mt-4" no-gutters style="width: 100% !important;">
            <v-col v-for="(day, index) in forecast.daily.slice(0,6)" :key="index" class="my-1" cols="4" lg="4" md="4"
                   sm="4"
                   xs="4">
              <template>
                <v-card class="mx-1 pa-2" color="accent" flat height="100%">
                  <v-row>
                    <v-col class="d-flex align-center justify-center" cols="12">
                      <div>
                        <div class="text-h8 darktext--text text-center font-weight-bold">
                          {{ $t(MIX_formatDateTime(forecast.daily[index].dt, 'X', 'ddd')) }}
                          {{ $t(MIX_formatDateTime(forecast.daily[index].dt, 'X', 'Do')) }}
                        </div>
                        <div class="caption grey--text text-center font-weight-normal">
                          {{ $t(forecast.daily[index].weather[0].description) }}
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="d-flex justify-center align-center" cols="12">
                      <v-icon class="primary--text text-h4">
                        {{ MIX_weatherForecastIcons(forecast.daily[index].weather[0].id) }}
                      </v-icon>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="d-flex align-center justify-center" cols="12">
                      <div class="font-weight-normal text-center body-2">{{ forecast.daily[index].temp.max.toFixed() }}°
                        |
                        <span class="font-weight-light text-center">{{
                            forecast.daily[index].temp.min.toFixed()
                          }}°</span></div>
                    </v-col>
                  </v-row>
                </v-card>
              </template>
            </v-col>
          </v-row>
        </v-col>

      </v-row>
    </v-container>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'; // Map Actions / Getters
export default {
  data: () => ({}),
  computed: {
    ...mapGetters({
      GET_activeStation: 'GET_activeStation',
      GET_weatherDataStations: 'GET_weatherDataStations',
      GET_weatherDataForecast: 'GET_weatherDataForecast',
    }),
    forecast: {
      get() {
        return this.GET_weatherDataForecast;
      },
      set() {
        // do nothing
      }
    },
    station: {
      get() {
        return this.GET_activeStation;
      },
      set(value) {
        this.MIX_activeStation(value);
      }
    },
  },
  watch: {
    GET_activeStation: {
      handler() {
        this.forecast = this.GET_weatherDataForecast;
        this.updateForecast();
      }, deep: true
    },
    GET_weatherDataForecast: {
      handler() {
        // console.log('Forecast Updated')
      },
      //Im 11 and this is..
      deep: true
    }
  },
  methods: {
    updateForecast: async function () {
      var t = this;
      if (t.GET_activeStation !== null) {
        t.forecast = await t.MIX_weatherDataForecast(t.GET_activeStation.latitude, t.GET_activeStation.longitude);
      } else {
        console.log('Can\'t get Weather Data if no Active Station')
      }
    },

  },
  async mounted() {
    let t = this;
    t.updateForecast();
    if (t.GET_activeStation === null) {
      alert("Please choose a weather station.")
    }
  },
}
</script>