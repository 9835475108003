const terms_store = {
    namespaced: true, //to be case sencitive
    // * STATE
    state: {
        terms: {
            show: false,
        }
    },
    // * MUTATIONS
    mutations:{
        MUT_terms(state,data){
            state.terms = data;
        }
    },
    // * GETTERS
    getters:{
        GET_terms (state) { return state.terms }
    },
};
export default terms_store;