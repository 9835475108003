<template>
	<div class="d-flex align-start flex-column justify-start fill-height" style="width: 100% !important;">
		<!-- <v-sheet height="150" class="d-flex align-start justify-start" width=100% color="lightgrey">
            <v-row class="align-end"> -->
		<!-- <v-col cols="6">
                    <v-row class="mb-6 align-end">
                        <h1 v-if="$i18n.locale === 'en'" class="font-weight-light pl-4 pt-2">Welcome to the <span v-if="JSON.stringify(station) !== '{}'">{{ station.station_name }} </span> {{ $t('headings.dashboard') }}</h1>
                        <h1 v-if="$i18n.locale === 'cy'" class="font-weight-light pl-4 pt-2">Welsh Version Welcome to the <span v-if="JSON.stringify(station) !== '{}'">{{ station.station_name }} </span> {{ $t('headings.dashboard') }}</h1> -->
		<!-- </v-row>
                    <v-row>
                        <v-col cols="4"> <v-card flat color="lightgrey" style="width: 200px !important; height: 150px !important;"> <gauge :chartdata="sensors.temperature" :key="temperatureGaugeKey" width="100%" height="100%" /> <v-card-title class="font-weight-light justify-center">Temperature {{ sensors.temperature }}</v-card-title> </v-card> </v-col>
                        <v-col cols="4"> <v-card flat color="lightgrey" style="width: 200px !important; height: 150px !important;"> -->
		<!-- <gauge :chartdata="chartData4" width="100%" height="100%" /> -->
		<!-- <gauge :chartdata="sensors.humidity" :key="humidityGaugeKey" width="100%" height="100%" /> <v-card-title class="font-weight-light justify-center">Humidity</v-card-title> </v-card> </v-col>
                        <v-col cols="4"> <v-card flat color="lightgrey" style="width: 200px !important; height: 150px !important;"> -->
		<!-- <gauge :chartdata="chartData4" width="100%" height="100%" /> -->
		<!-- <v-card-title class="font-weight-light justify-center">Humidity</v-card-title> </v-card> </v-col>
                    </v-row>
                </v-col>
                <v-col cols="5">
                    <v-row>
                        <v-col cols="6"> <v-card flat color="lightgrey" style="width: 200px !important; height: 150px !important;"> -->
		<!-- <barcharthorizontal :chartdata="chartData" width="100%" height="100%" /> -->
		<!-- <v-card-title class="font-weight-light justify-center">Humidity</v-card-title> </v-card> </v-col>
                        <v-col cols="6"> <v-card flat color="lightgrey" style="width: 200px !important; height: 150px !important;"> -->
		<!-- <gauge :chartdata="chartData4" width="100%" height="100%" /> -->
		<!-- <v-card-title class="font-weight-light justify-center">Humidity</v-card-title> </v-card> </v-col>
                    </v-row>
                </v-col> -->
		<!-- </v-row>
        </v-sheet> -->
		<v-row style="width: 100% !important">
			<v-col class="mt-6" cols="5">
				<v-card
					flat
					color="accent"
					width="100%"
					height="80"
					class="mb-4 pa-2"
					@click="
						updateData(1617008400, 1617267600);
						chartShow = true;
					"
				>
					<v-row>
						<v-col cols="2"> <v-icon size="50" position="left" color="primary" class="pl-1 pt-3">icons8-temperature</v-icon> </v-col>
						<v-col cols="7" class="d-flex align-center">
							<div class="pt-2 text-h6 font-weight-light primary--text">
								{{ $t("weather.airTemperature") }} <span class="text-body-1 font-weight-bold grey--text"> °C</span>
								<div class="caption grey--text">{{ MIX_formatDateTime(sensors.temperatureUpdated, "X", "DD-MMM-YY HH:mm:ss") }}</div>
							</div>
						</v-col>
						<v-col cols="3">
							<h1 class="font-weight-bold text-center pt-3 pl-2 grey--text">{{ sensors.temperature }}°</h1>
						</v-col>
					</v-row>
				</v-card>
				<v-card
					flat
					color="accent"
					width="100%"
					height="80"
					class="mb-4 pa-2"
					@click="
						updateData(1617008400, 1617267600);
						chartShow = true;
					"
				>
					<v-row>
						<v-col cols="2"> <v-icon size="50" position="left" color="primary" class="pl-1 pt-3">icons8-hygrometer</v-icon> </v-col>
						<v-col cols="7" class="d-flex align-center">
							<div class="pt-2 text-h6 font-weight-light primary--text">
								{{ $t("weather.rainfall") }} <span class="text-body-1 font-weight-bold grey--text"> {{ $t("measurements.mm/hr") }}</span>
								<div class="caption grey--text">{{ MIX_formatDateTime(sensors.rainfallUpdated, "X", "DD-MMM-YY HH:mm:ss") }}</div>
							</div>
						</v-col>
						<v-col cols="3">
							<h1 class="font-weight-bold text-center pt-3 grey--text">{{ sensors.rainfall }}</h1>
						</v-col>
					</v-row>
				</v-card>
				<v-card
					flat
					color="accent"
					width="100%"
					height="80"
					class="mb-4 pa-2"
					@click="
						updateData(1617008400, 1617267600);
						chartShow = true;
					"
				>
					<v-row>
						<v-col cols="2"> <v-icon size="50" position="left" color="primary" class="pl-1 pt-3">icons8-wind-gauge</v-icon> </v-col>
						<v-col cols="7" class="d-flex align-center">
							<div class="pt-2 text-h6 font-weight-light primary--text">
								{{ $t("weather.windDirection") }}
								<div class="caption grey--text">{{ MIX_formatDateTime(sensors.wind_directionUpdated, "X", "DD-MMM-YY HH:mm:ss") }}</div>
							</div>
						</v-col>
						<v-col cols="3" class="justify-center align-center"> <winddirection /> </v-col>
					</v-row>
				</v-card>
				<v-card
					flat
					color="accent"
					width="100%"
					height="80"
					class="mb-4 pa-2"
					@click="
						updateData(1617008400, 1617267600);
						chartShow = true;
					"
				>
					<v-row>
						<v-col cols="2"> <v-icon size="50" position="left" color="primary" class="pl-1 pt-3">icons8-windsock</v-icon> </v-col>
						<v-col cols="8" class="d-flex align-center">
							<div class="pt-2 text-h6 font-weight-light primary--text">
								{{ $t("weather.windSpeed") }} <span class="text-body-1 font-weight-bold grey--text"> {{ $t("measurements.mph") }}</span>
								<div class="caption grey--text">{{ MIX_formatDateTime(sensors.wind_speedUpdated, "X", "DD-MMM-YY HH:mm:ss") }}</div>
							</div>
						</v-col>
						<v-col cols="2" class="align-center justify-center">
							<h1 class="font-weight-bold text-start pt-2 pl-0 grey--text">{{ sensors.wind_speed }}</h1>
						</v-col>
					</v-row>
				</v-card>
				<v-card
					flat
					color="accent"
					width="100%"
					height="80"
					class="mb-4 pa-2"
					@click="
						updateSoilData();
						chartShow = true;
					"
				>
					<v-row>
						<v-col cols="2"> <v-icon size="50" position="left" color="primary" class="pl-1 pt-3">icons8-temperature</v-icon> </v-col>
						<v-col cols="7" class="d-flex align-center">
							<div class="pt-2 text-h6 font-weight-light primary--text">
								{{ $t("weather.soilTemperature") }} <span class="text-body-1 font-weight-bold grey--text"> °C</span>
								<div class="caption grey--text">{{ MIX_formatDateTime(sensors.soil_moistureUpdated, "X", "DD-MMM-YY HH:mm:ss") }}</div>
							</div>
						</v-col>
						<v-col cols="3" class="align-center justify-center">
							<h1 class="font-weight-bold text-center pt-3 pl-2 grey--text">{{ sensors.soil_temp }}°</h1>
						</v-col>
					</v-row>
				</v-card>
				<v-card
					flat
					color="accent"
					width="100%"
					height="80"
					class="mb-4 pa-2"
					@click="
						updateData(1617008400, 1617267600);
						chartShow = true;
					"
				>
					<v-row>
						<v-col cols="2"> <v-icon size="50" position="left" color="primary" class="pl-1 pt-3">icons8-soil</v-icon> </v-col>
						<v-col cols="7" class="d-flex align-center">
							<div class="pt-2 text-h6 font-weight-light primary--text">
								{{ $t("weather.soilMoisture") }} <span class="text-body-1 font-weight-bold grey--text"> bar</span>
								<div class="caption grey--text">{{ MIX_formatDateTime(sensors.soil_moistureUpdated, "X", "DD-MMM-YY HH:mm:ss") }}</div>
							</div>
						</v-col>
						<v-col cols="3" class="align-center justify-center">
							<h1 class="font-weight-bold text-center pt-3 grey--text">{{ sensors.soil_moisture }}</h1>
						</v-col>
					</v-row>
				</v-card>
			</v-col>

			<!-- <v-col cols="12">
                <pre v-if="JSON.stringify(current) !== '[]'"> {{ current }}</pre>
            </v-col> -->
			<v-col class="mt-6 ml-0" v-if="chartShow !== ''" cols="7">
				<v-card flat cols="12">
					<v-tabs grow v-model="tab">
						<v-tabs-slider color="secondary"></v-tabs-slider>
						<v-tab href="#charts" class="lightgrey black--text">Charts</v-tab>
						<v-tab href="#table" class="lightgrey black--text">Table View</v-tab>
					</v-tabs>
					<v-tabs-items v-model="tab">
						<v-tab-item value="charts">
							<v-card flat class="pa-4 ma-0 white text-left" style="border-radius: 2px !important;">
								<span class="text-h7 font-weight-bold primary--text">{{ chartData.name }}</span>
								<div class="" style="width: 100% !important; height: 300px !important;">
									<!-- {{ chartData.data }} -->
									<zoomable :key="soilDataKey" :chartdata="chartData" :title="$t('charts.barchart.title')" width="100%" height="100%" />
								</div>
							</v-card>
						</v-tab-item>
						<v-tab-item value="table">
							<v-row no-gutters>
								<h4 class="font-weight-light">Data</h4>

								<!-- <v-spacer></v-spacer>
                                <v-datetime-picker width="100" label="Select Start Date and Time" v-model="datetime"></v-datetime-picker> <v-datetime-picker label="Select End Date and Time" v-model="datetime"></v-datetime-picker>
                                <v-card flat>
                                    <v-menu ref="menu" v-model="startmenu" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }"> <v-text-field v-model="date" label="Select Start Date" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field> </template>
                                    <v-date-picker v-model="date" no-title scrollable  >
                                      <v-spacer></v-spacer>
                                      <v-btn text color="primary" @click="startmenu = false"> Cancel </v-btn> <v-btn text color="primary" @click="$refs.menu.save(date)"> OK </v-btn>
                                    </v-date-picker>
                                  </v-menu>

                                  <v-menu ref="menu" v-model="startmenu2" :close-on-content-click="false" :nudge-right="40" :return-value.sync="time" transition="scale-transition" offset-y max-width="290px" min-width="290px" >
                                    <template v-slot:activator="{ on, attrs }"> <v-text-field v-model="time" label="Select Start Time" prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on" ></v-text-field> </template>
                                    <v-time-picker v-model="time" no-title scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="startmenu2 = false" > Cancel </v-btn> <v-btn text color="primary" @click="$refs.dialog.save(time)" > OK </v-btn>    
                                    </v-time-picker>
                                  </v-menu>
                                 </v-card>

                                <v-card class="pl-6" flat>
                                    <v-menu ref="menu" v-model="endmenu" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }"> <v-text-field v-model="date" label="Select End Date" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field> </template>
                                    <v-date-picker v-model="date" no-title scrollable  >
                                      <v-spacer></v-spacer>
                                      <v-btn text color="primary" @click="endmenu = false"> Cancel </v-btn> <v-btn text color="primary" @click="$refs.menu.save(date)"> OK </v-btn>
                                    </v-date-picker>
                                  </v-menu>
                                </v-card> -->
							</v-row>
						</v-tab-item>
					</v-tabs-items>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
	// @ is an alias to /src
	//
	// require('csg-wp-api.node_modules.index.js')
	import { mapGetters } from "vuex"; // Map Actions / Getters
	export default {
		name: "gelliaur",
		data: () => ({
			station: {},
			current: [],
			soilData: [],
			chartData: {
				name: "Soil Data",
				data: [],
			},
			chartShow: "",
			sensors: {
				temperature: "-",
				humidity: "-",
				humidityUpdate: "-",
				rainfall: "-",
				wind_direction: "-",
				wind_speed: "-",
				soil_moisture: "-",
				soil_temp: "-",

				//create all of the sensor values
			},
			temperatureGaugeKey: 0,
			humidityGaugeKey: 0,
			soilDataKey: 0,
			date: new Date().toISOString().substr(0, 10),
			time: null,
			startmenu: false,
			endmenu: false,
			startmenu2: false,
			endmenu2: false,
			tab: false,
		}),
		computed: {
			...mapGetters({
				GET_weatherDataStations: "GET_weatherDataStations",
				GET_weatherDataStationById: "GET_weatherDataStationById",
				GET_weatherDataCatalog: "GET_weatherDataCatalog",
				GET_weatherDataCurrent: "GET_weatherDataCurrent",
				GET_weatherDataCatalogBySensorType: "GET_weatherDataCatalogBySensorType",
			}),
		},
		watch: {
			soilData: {
				handler() {
					// console.log("updated chart key");
					this.soilDataKey++;
				},
				deep: true,
			},
		},
		methods: {
			convertToCelcius: function(value) {
				if (!value) return "";
				return parseFloat(((5 / 9) * (parseFloat(value) - 32)).toFixed());
			},
			getSensor: function(sensor_type) {
				return this.current.find((sensor) => parseInt(sensor.sensor_type) === parseInt(sensor_type));
			},
			updateSensors() {
				var t = this;
				t.sensors.humidity = parseFloat(t.getSensor(28).data[0].hum_out);
				t.sensors.humidityUpdated = t.getSensor(28).data[0].ts;
				t.humidityGaugeKey++;
				var soil_temp = parseFloat(t.getSensor(105).data[0].temp_last);
				t.sensors.soil_temp = t.convertToCelcius(soil_temp);
				var temperature = parseFloat(t.getSensor(28).data[0].temp_out);
				t.sensors.temperature = t.convertToCelcius(temperature);
				t.sensors.temperatureUpdated = t.getSensor(28).data[0].ts;
				t.temperatureGaugeKey++;
				t.sensors.rainfall = parseFloat(t.getSensor(28).data[0].rain_rate_mm);
				t.sensors.rainfallUpdated = t.getSensor(28).data[0].ts;
				t.sensors.wind_direction = parseFloat(t.getSensor(28).data[0].wind_dir);
				t.sensors.wind_directionUpdated = t.getSensor(28).data[0].ts;
				t.sensors.wind_speed = parseFloat(t.getSensor(28).data[0].wind_speed);
				t.sensors.wind_speedUpdated = t.getSensor(28).data[0].ts;
				t.sensors.soil_moisture = parseFloat(t.getSensor(108).data[0].moist_soil_last); //.toFixed()
				t.sensors.soil_moistureUpdated = t.getSensor(108).data[0].ts;

				// Create all the sensors and store their values
			},
			updateSoilData: async function() {
				var t = this;
				// var routerPath = t.$route.path.split('/')
				var stationId = 107021;
				// var dateToday = t.dateToday;
				if (t.GET_activeStation !== null) {
					var soilData = await t.MIX_weatherDataSoilData(stationId);
					this.soilData = soilData;
					// console.log("soilData = " + JSON.stringify(t.soilData, null, 2));
					var chartData = {};
					chartData.name = "Soil Temperature";
					chartData.data = await t.formatChartData(soilData, "ts", "value", "unix", "x");
					this.chartData = chartData;
					// console.log("chartData = " + chartData.data);
					return chartData;
				} else {
					console.log("Can't get Soil Data");
				}
			},
			updateData: async function(startDate, endDate) {
				var t = this;
				// var routerPath = t.$route.path.split('/')
				var stationId = 107021;
				// var dateToday = t.dateToday;
				if (t.GET_activeStation !== null) {
					var soilData = await t.MIX_weatherDataHistoric(stationId, startDate, endDate);
					this.soilData = soilData;
					// console.log("soilData = " + JSON.stringify(t.soilData, null, 2));
					var chartData = {};
					chartData.data = await t.formatChartData(soilData, "ts", "value", "unix", "x");
					this.chartData = chartData;
					// console.log("chartData = " + chartData.data);
					return chartData;
				} else {
					console.log("Can't get Soil Data");
				}
			},
			formatChartData: function(chartData, xField, yField, dateFormatIn, dateFormatOut) {
				return new Promise((resolve) => {
					var formatedData = [];
					chartData.forEach((data) => {
						var x = data[xField];
						var y = data[yField];
						if (xField === "ts") {
							x = this.$moment(x, dateFormatIn).format(dateFormatOut);
						}
						if (yField === "value") {
							y = this.convertToCelcius(y).toFixed();
						}
						x = parseInt(x);
						formatedData.push([x, y]);
					});
					resolve(formatedData);
				});
			},
		},
		async mounted() {
			var t = this;
			await t.MIX_weatherDataCatalog();
			await t.MIX_weatherDataStations();
			// var routerPath = t.$route.path.split('/')
			var stationId = 107021;
			t.station = await t.GET_weatherDataStationById(stationId);
			await t.MIX_weatherDataCurrent(stationId);
			t.current = await t.GET_weatherDataCurrent;
			t.MIX_weatherDataSoilData();
			t.updateData();
			t.updateSensors();
		},
	};
</script>
