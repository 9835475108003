<template>
  <div id="app">
    <v-app style="background-color: primary !important;">
      <alertbox/>

      <!-- <v-app-bar app color="primary" dark flat clipped-left class="ma-0 pa-0 noprint" v-if="$vuetify.breakpoint.xsOnly">
          <v-row class="align-center">
              <v-icon size="30" position="left" color="lighttext" class="pb-1 align-center">icons8-weather-station-wind</v-icon>
              <v-toolbar-title>
                  <v-card color="rgb(255, 0, 0, 0)" class="pb-1 pl-2" height=100% elevation="0">
                      <div class="text-h7 font-weight-light lighttext--text align-start justify-left">Tywydd <span class="font-weight-bold">Tywi</span> Weather</div>
                  </v-card>
              </v-toolbar-title> -->
      <!-- <h6 class="lighttext--text"> v{{LATEST_VERSION}}</h6> -->
      <!-- <v-spacer></v-spacer>
      <language />
      <v-menu offset-y left>
          <template v-slot:activator="{ on, attrs }">
              <v-btn dark icon v-bind="attrs" v-on="on">
                  <v-icon color="lighttext">icons8-male-user</v-icon>
              </v-btn>
          </template>

          <v-list nav dense pa-0 ma-0>
              <v-list-item active-class="secondary">
                  <v-btn v-if="$vuetify.theme.dark" text color="darktext" class="justify-end" width=100% @click="toggleDarkTheme()">LIGHT MODE</v-btn>
                  <v-btn v-else text color="darktext" class="justify-end" width=100% @click="toggleDarkTheme()" >DARK MODE</v-btn>
              </v-list-item>
              <v-list-item active-class="secondary">
                  <v-btn text color="grey" class="justify-end" width=100% to="/profile">PROFILE</v-btn>
              </v-list-item>
              <v-list-item active-class="secondary">
                  <v-btn v-if="this.GET_currentUser !== null" text color="primary" class="justify-end" width=100% @click="logout()" >LOG OUT</v-btn>
                  <v-btn v-else text color="primary" class="justify-end" width=100% @click="logout()" >LOG IN</v-btn>
              </v-list-item>
               <v-list-item-title class="grey--text text-center">
                  App version {{ LATEST_VERSION }}
              </v-list-item-title>
          </v-list> -->
      <!-- </v-menu> -->
      <!-- <v-tabs v-model="tab" icons-and-text pa-0 ma-0 align-with-title background-color="darkgrey">
          <v-tabs-slider color="lighttext"></v-tabs-slider>
          <v-tab v-for="(item, index2) in menu" :key="index2" color="primary" :to="item.path">
              <span class="caption font-weight-bold">{{ item.name }}</span>
              <v-icon class="navicons">{{ item.icon }}</v-icon>
          </v-tab>
      </v-tabs> -->
      <!-- </v-row>
  </v-app-bar> -->

      <!-- App Top Bar - Phone -->
      <v-app-bar v-if="$vuetify.breakpoint.smAndDown" app class="ma-0 pa-0 noprint" clipped-left color="primary" dark
                 flat>
        <v-col class="pa-0 ma-0" cols="12">
          <v-row class="align-center justify-start">
            <v-col cols="1" xs="1">
              <v-icon class="pb-1 pl-0 align-center" color="lighttext" position="left" size="25" @click="MIX_go('/')">
                icons8-weather-station-wind
              </v-icon>
            </v-col>
            <v-col cols="7" xs="7">
              <div v-if="$vuetify.breakpoint.xs" class="ml-2 subtitle-2 font-weight-light lighttext--text align-start justify-left">Tywydd <span
                  class="font-weight-bold">Tywi</span> Weather</div>
              <div v-else class="ml-2 subtitle-1 font-weight-light lighttext--text align-start justify-left">Tywydd <span
                  class="font-weight-bold">Tywi</span> Weather</div>
            </v-col>
            <!-- <v-col cols="1" xs="1" class="mr-1">
                <v-btn dark icon @click="MIX_go('/')">
                    <v-icon color="lighttext">icons8-home-page</v-icon>
                </v-btn>
            </v-col> -->
            <v-col class="justify-right pl-12" cols="2" xs="2">
              <language/>
            </v-col>
            <v-col class="justify-right" cols="1" xs="1">
              <v-menu left offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn dark icon v-bind="attrs" v-on="on">
                    <v-icon color="lighttext">icons8-male-user</v-icon>
                  </v-btn>
                </template>

                <v-list dense ma-0 nav pa-0>
                  <v-list-item active-class="secondary">
                    <v-btn v-if="this.GET_currentUser !== null" class="justify-end" color="darktext" text to="/profile"
                           width=100%>{{ $t('appbar.profile') }}
                    </v-btn>
                    <v-btn v-else class="justify-end" color="darktext" disabled text width=100%>{{
                        $t('appbar.profile')
                      }}
                    </v-btn>
                  </v-list-item>
                  <v-list-item active-class="secondary">
                    <v-btn v-if="this.GET_currentUser !== null" class="justify-end" color="darktext" text to="/activity"
                           width=100%>{{ $t('appbar.activity') }}
                    </v-btn>
                    <v-btn v-else class="justify-end" color="darktext" disabled text width=100%>{{
                        $t('appbar.activity')
                      }}
                    </v-btn>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item active-class="secondary">
                    <v-btn v-if="$vuetify.theme.dark" class="justify-end" color="darktext" text width=100%
                           @click="toggleDarkTheme()">{{ $t('appbar.lightMode') }}
                    </v-btn>
                    <v-btn v-else class="justify-end" color="darktext" text width=100% @click="toggleDarkTheme()">
                      {{ $t('appbar.darkMode') }}
                    </v-btn>
                  </v-list-item>
                  <v-list-item active-class="secondary">
                    <v-btn v-if="this.GET_currentUser !== null" class="justify-end" color="primary" text width=100%
                           @click="logout()">{{ $t('appbar.logOut') }}
                    </v-btn>
                    <v-btn v-else class="justify-end" color="primary" text width=100% @click="logout()">
                      {{ $t('appbar.logIn') }}
                    </v-btn>
                  </v-list-item>

                  <v-list-item-title class="pt-2 pb-2 grey--text text-center">
                    App Version {{ LATEST_VERSION }}
                  </v-list-item-title>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
      </v-app-bar>

        <appupdate/>

      <v-banner v-if="$vuetify.breakpoint.smAndDown" app class="pa-0 ma-0 justify-center align-center" elevation-0
                height="50" >
<!--        <v-tabs v-if="$i18n.locale === 'cy' " v-model="tab" background-color="lighttext" elevation-0 fixed-tabs height="70" hide-slider icons-and-text
                ma-0 pa-0>
          &lt;!&ndash; <v-tabs-slider color="primary"></v-tabs-slider> &ndash;&gt;
          <v-tab v-for="(item, index2) in menu2" :key="index2" :to="item.path" color="lightgrey">
            <span class="tabfont">{{ item.name }}</span>
            <v-icon class="pt-2 navicons" size="30">{{ item.icon }}</v-icon>
          </v-tab>
        </v-tabs>

        <v-tabs v-if="$i18n.locale === 'en'" v-model="tab" background-color="lighttext" elevation-0 fixed-tabs height="70" hide-slider icons-and-text
                ma-0 pa-0>
          &lt;!&ndash; <v-tabs-slider color="primary"></v-tabs-slider> &ndash;&gt;
          <v-tab v-for="(item, index2) in menu" :key="index2" :to="item.path" color="lightgrey">
            <span class="tabfont font-weight-bold">{{ item.name }}</span>
            <v-icon class="pt-2 navicons" size="30">{{ item.icon }}</v-icon>
          </v-tab>
        </v-tabs>-->

        <v-bottom-navigation grow v-if="$i18n.locale === 'en'">
          <v-btn v-for="(item, index) in menu" :key="index" :to="item.path">
            <span class="tabfont text-wrap font-weight-bold">{{ item.name }} </span>
            <v-icon size="30">{{ item.icon }}</v-icon>
          </v-btn>
        </v-bottom-navigation>
        <v-bottom-navigation grow v-if="$i18n.locale === 'cy'">
          <v-btn v-for="(item, index) in menu2" :key="index" :to="item.path">
            <span class="tabfont_welsh font-weight-bold">{{ item.name }} </span>
            <v-icon size="30">{{ item.icon }}</v-icon>
          </v-btn>
        </v-bottom-navigation>
      </v-banner>

      <!-- App Top Bar - Desktop / Tablet -->
      <v-app-bar v-if="$vuetify.breakpoint.mdAndUp" app class="ma-0 py-0 pl-1 pr-3 noprint" clipped-left color="primary"
                 dark>
        <v-row class="align-center">
          <v-icon class="align-center pl-1 justify-left" color="lighttext" position="left" size="35"
                  @click="MIX_go('/')">icons8-weather-station-wind
          </v-icon>
          <v-toolbar-title>
            <v-card class="pl-4" color="rgb(255, 0, 0, 0)" elevation="0">
              <h6 class="text-h6 font-weight-light lighttext--text align-start justify-left">Tywydd <span
                  class="font-weight-bold">Tywi</span> Weather </h6>
            </v-card>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-card color="rgb(255, 0, 0, 0)" elevation="0">
              <h6 class="font-weight-bold lighttext--text">LOCAL FORECAST</h6>
              <h4 class="heading-4 lighttext--text font-weight-light">//Get Forecast</h4>
          </v-card>
          <v-icon size="40" color="secondary" position="left" class="align-center justify-left">mdi-map-marker</v-icon>  
          <v-col class="d-flex mt-6" cols="4" >      
              <v-select light :items="items" color="white" background-color="white" dense outlined></v-select>
          </v-col> -->
          <language/>
          <v-menu left offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn dark icon v-bind="attrs" v-on="on">
                <v-icon color="lighttext">icons8-male-user</v-icon>
              </v-btn>
            </template>

            <v-list dense ma-0 nav pa-0>
              <v-list-item class="ma-0 pa-0 pt-2">
                <v-btn v-if="$vuetify.theme.dark" class="justify-end" color="primary" text width=100%
                       @click="toggleDarkTheme()">
                  <v-icon color="darktext">icons8-light</v-icon>
                  <v-list-item-title class="darktext--text text-start pl-2">{{
                      $t('appbar.lightMode')
                    }}
                  </v-list-item-title>
                </v-btn>
                <v-btn v-else class="justify-end" color="primary" text width=100% @click="toggleDarkTheme()">
                  <v-icon color="darktext">icons8-light</v-icon>
                  <v-list-item-title class="darktext--text text-start pl-2">{{
                      $t('appbar.darkMode')
                    }}
                  </v-list-item-title>
                </v-btn>
              </v-list-item>
              <v-list-item class="ma-0 pa-0">
                <v-btn v-if="this.GET_currentUser !== null" class="justify-end" color="primary" text to="/profile"
                       width=100%>
                  <v-icon color="darktext">icons8-male-user</v-icon>
                  <v-list-item-title class="darktext--text text-start pl-2">{{
                      $t('appbar.profile')
                    }}
                  </v-list-item-title>
                </v-btn>
                <v-btn v-else class="justify-end" color="primary" disabled text width=100%>
                  <v-icon color="darktext">icons8-male-user</v-icon>
                  <v-list-item-title class="grey--text text-start pl-2">{{ $t('appbar.profile') }}</v-list-item-title>
                </v-btn>
              </v-list-item>
              <v-list-item class="ma-0 pa-0">
                <v-btn v-if="this.GET_currentUser !== null" class="justify-end" color="primary" text to="/activity"
                       width=100%>
                  <v-icon color="darktext">icons8-male-user</v-icon>
                  <v-list-item-title class="darktext--text text-start pl-2">{{
                      $t('appbar.activity')
                    }}
                  </v-list-item-title>
                </v-btn>
                <v-btn v-else class="justify-end" color="primary" disabled text width=100%>
                  <v-icon color="darktext">icons8-male-user</v-icon>
                  <v-list-item-title class="grey--text text-start pl-2">{{ $t('appbar.activity') }}</v-list-item-title>
                </v-btn>
              </v-list-item>
              <v-list-item class="ma-0 pa-0">
                <v-btn v-if="this.GET_currentUser !== null" class="justify-end" color="primary" text width=100%
                       @click="logout()">
                  <v-icon color="darktext">icons8-exit</v-icon>
                  <v-list-item-title class="darktext--text text-start pl-2">{{
                      $t('appbar.logOut')
                    }}
                  </v-list-item-title>
                </v-btn>
                <v-btn v-else class="justify-start" color="primary" text width=100% @click="logout()">
                  <v-icon color="darktext">icons8-enter</v-icon>
                  <v-list-item-title class="darktext--text text-start pl-2">{{ $t('appbar.logIn') }}</v-list-item-title>
                </v-btn>
              </v-list-item>
              <v-list-item-title class="pt-2 pb-2 grey--text text-center">
                App version {{ LATEST_VERSION }}
              </v-list-item-title>
            </v-list>

          </v-menu>
        </v-row>
      </v-app-bar>

      <NavigationDrawer/>
      <progressbar/>
      <v-main class="appbackground">
        <!-- <HelloI18n/> -->
        <!-- <router-link to="/">{{ $t('nav.home') }}</router-link> | -->
        <!-- <router-link to="/about">{{ $t('nav.about') }}</router-link> -->
        <v-container class="fill-height fill-width" fluid ma-0 pa-0>
          <router-view/>
        </v-container>
        <v-col class="pl-2 appbackground" cols="12">
          <appcopyright/>
        </v-col>
        <!--start of ACCESS-CODE-OVERLAY-->
        <v-overlay v-if="!authSuccess" :opacity="1" color="vindicoPurple">
          <v-dialog v-if="!authSuccess" v-model="dialogLogin" persistent style="z-index: 3000 !important;" width="500">
            <v-card class="login" color="vindicoPurple" flat height="350">
              <v-card-title class="justify-center pt-4">
                <v-img :src="require('@/assets/vlogo-white.png')" contain height="50" width="50%"/>
              </v-card-title>
              <v-divider class="white mt-2"></v-divider>
              <v-card-text class="mt-6 mb-0 pb-0">
                <!-- <p class="headline white--text text-left font-weight-thin my-0 py-0" >Vindico Preview</p> -->
                <p class="body-1 white--text text-left my-0 py-2">
                  Please enter the access code you received in order to proceed to the preview.
                </p>
                <!-- ACCESS-CODE-INPUT-->
                <v-text-field v-model.trim="accessCode" dark filled hide-details="auto" label="Preview Code"
                              type="password" v-on:keyup.enter="enterAccessCode()"></v-text-field>
                <!-- start of ERROR-MESSAGE-->
                <v-row v-if="authCode === 0" class="justify-center ma-0 pa-0">
                  <transition enter-active-class="animated flash" name="custom-classes-transition">
                    <span
                        :class="{ 'error--text': !authSuccess }"
                        class="subtitle1 font-weight-bold my-1"
                    >{{ authMessage }}</span>
                  </transition>
                </v-row>
                <!-- end of ERROR-MESSAGE-->
              </v-card-text>
              <v-card-actions class="justify-center pt-0 mt-6">
                <!-- ENTER-ACCESS-CODE-BUTTON-->
                <v-btn class="white vindicoPurple--text" large @click="enterAccessCode()">Enter</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-overlay>
        <!--end of ACCESS-CODE-OVERLAY-->

        <!-- App Copyright -->

      </v-main>
    </v-app>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import progressBar from '@/modules/progressBar/progressBar_component.vue';
import NavigationDrawer from "./components/structural/navigation-drawer.vue";
import {LATEST_VERSION} from '../service-worker';

export default {
  name: "App",
  components: {
    NavigationDrawer,
    'progressbar': progressBar,
  },
  data: () => ({
    LATEST_VERSION: LATEST_VERSION,
    tab: null,
    currentUser: [],
    items: ['Gelli Aur', 'Example 1', 'Example 2'],
    menu: [
      {name: "MAP", icon: "icons8-map-marker", path: "/sites"},
      {name: "FARMING", icon: "icons8-tractor", path: "/farming"},
      {name: "FORECAST", icon: "icons8-rain-cloud", path: "/forecast"},
      {name: "INFO", icon: "icons8-information", path: "/information"},
    ],
    menu2: [
      {name: "MAP", icon: "icons8-map-marker", path: "/sites"},
      {name: "FFERMIO", icon: "icons8-tractor", path: "/farming"},
      {name: "RHAGOLYGON", icon: "icons8-rain-cloud", path: "/forecast"},
      {name: "GWYBODAETH", icon: "icons8-information", path: "/information"},
    ],
    // * ACCESS CODE VARIABLES
    // TODO - change access password
    accessPassword: "Vindico2021!", // ? ACCESS PASSwORD
    accessCode: '', // TODO remove before go live // user input code
    dialogLogin: true, // login dialog
    authSuccess: true, // TODO change back to false before go live // * when true - closes the overlay
    authMessage: null, // message displayed to user
    authCode: null, // * 1 for correct password, 0 for incorrect

    userTypes: [],
    userType: '',
    userRoles: [],
    userRole: ''

  }),
  computed: {
    ...mapGetters({
      GET_authToken: 'GET_authToken',
      GET_weatherDataStations: 'GET_weatherDataStations',
    }),
    stations: {
      get() {
        return this.GET_weatherDataStations;
      }
    }
  },
  watch: {
    currentUser: {
      handler: function () {
        this.$store.commit("SET_currentUser", this.currentUser);
        // console.log(
        //     "currentUser: " + JSON.stringify(this.currentUser, null, 2)
        //);
      },
      deep: true,
    },
  },
  methods: {
    logout() {
      this.$firebase.auth.signOut().then(() => {
        this.showPageElements = false;
        this.$router.push("/login").catch((err) => {
          console.log("Route error: " + err);
        });
        this.$store.commit("SET_currentUser", null);
      });
    },
    enterAccessCode() {
      // * Access code validation
      var v = this;
      if (this.accessCode === this.accessPassword) {
        v.authSuccess = true; // * close the overlay
        v.authCode = 1; // * success code
        v.authMessage = "The access code was correct";
        v.accessCode = null;
      } else {
        v.authSuccess = false; // ! keep the overlay open
        v.authCode = 0; // ! error code
        v.authMessage = "The access code you have entered is incorrect";
      }
    },
    toggleDarkTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      // console.log(this.$vuetify.theme.dark);
    },
  },
  async created() {
    var t = this;
    this.$firebase.auth.onAuthStateChanged((user) => {
      var userDeets = {}
      userDeets = JSON.parse(JSON.stringify(user))
      if (userDeets !== null) {
        if (userDeets["stsTokenManager"]) {
          t.MIX_authToken(userDeets.stsTokenManager.accessToken);
        } else {
          t.MIX_authToken('');
        }
      } else {
        t.MIX_authToken('');
      }
//            console.log('user.uid = ' + user.uid)
      if (user != null) {
        t.$firebase.db
            .collection("users")
            .doc(user.uid)
            .get()
            .then((doc) => {
              if (doc.exists) {
                this.key = doc.id;
                var document = doc.data();
                document.id = doc.id;
                this.currentUser = document;
                //console.log('doc exists')
              } else {
                console.log('NO SUCH DOCUMENT');//alert("No such document!");
              }
            });
      }
    });
  },
  async mounted() {
    this.MIX_progressBar({show: false})
    //console.log('getAuthToken = ' + this.getAuthToken, null)
  }
}
</script>

<style>
html body {
  background-color: #F2F2F2 !important;
}

.tabfont {
  font-size: 10px !important;
}
.tabfont_welsh {
  font-size: 9px !important;
}
.v-slide-group__prev--disabled {
  display: none !important;
}
.v-slide-group__next--disabled {
  display: none !important;
}
.v-banner__wrapper {
  padding: 0px !important;
  margin: 0px !important;
}

.v-banner__content {
  padding: 0px !important;
  margin: 0px !important;

}
</style>

