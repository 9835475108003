<template>
    <div class="d-flex align-start flex-column justify-start fill-height"  style="width: 100vw !important;">
        <v-container fluid width=100% style="padding: 0px !important;">
            <v-card flat color="lightgrey">
                <v-row class="py-2" >
                    <v-col cols="12" class="px-8 align-center">
                        <div class="text-h5 pt-2 primary--text font-weight-thin">STATIONS</div>
                        <span class="body-2 font-weight-regular darktext--text">Welcome to Stations, please select a location and then the type of activity below you want to monitor.</span>
                    </v-col>
                </v-row>
            </v-card>
            
            <v-row class="d-flex align-center justify-end">
                <v-col cols="2" xs="2" class="ma-3 px-5 text-right d-flex align-center justify-end">
                    <v-btn @click="columnsDialog = !columnsDialog">Headers</v-btn>
                </v-col>
            </v-row>

            <v-data-table :headers="computedHeaders" :items="computedTableData" :items-per-page="10" item-key="id" class="elevation-0 mx-5" hide-default-header :search="search" >
                <!-- // HEADERS -->                        
                <template v-slot:header="{ props }">
                    <th :align="head.align" class="pa-4 font-weight-medium" style="font-size:12px !important;" v-for="(head, index) in props.headers" :key="index"> {{ $t(`fields.${head.text}`) }} </th>
                </template>     
                <!-- // NAME -->
                <template v-slot:[`item.station_name`]="{ item }">
                    <span @click="MIX_go(`/admin/stations/${item.station_id}`)" class="d-inline-block text-truncate" style="max-width: 200px;">{{ item.station_name }}</span>
                </template>
                <!-- // Registered Date -->
                <template v-slot:[`item.registered_date`]="{ item }">
                    <span>{{ MIX_formatDateTime(item.registered_date, 'X', 'DD-MMM-YY') }}</span>
                </template>
                <!-- // Registered Date -->
                <template v-slot:[`item.subscription_end_date`]="{ item }">
                    <span>{{ MIX_formatDateTime(item.subscription_end_date, 'X', 'DD-MMM-YY') }}</span>
                </template>
            </v-data-table> 
        </v-container>  

            <pre></pre>
            
            <!-- // * DYNAMIC COMPONENT DIALOG -->        
            <v-dialog v-model="columnsDialog" scrollable max-width="300px">
                <v-card>
                    <v-card-title>{{$t('headings.showHideColumns')}}</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text style="height: 300px;">
                        <v-list>
                            <v-list-item v-for="(header, index) in headers" :key="index">
                            <v-list-item-title><v-checkbox color="grey darken-1" hide-details v-model="headers[index].hidden" :false-value="true" :true-value="false" :label="$t(`fields.${header.text}`)"></v-checkbox></v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="text-right">
                        <v-spacer />
                        <v-btn color="primary" text @click="columnsDialog = false">Close</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>     
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'; // Map Actions / Getters
    export default {
        data: () => ({
            tableData: [],
            search: '',
            filter: {
                sortAsc: true,
            },
            sortBy: 'station_name',
            columnsDialog: false,
            headers: [
                { text: 'station_id', value: 'station_id', sortable: false, align: 'start', hidden: false, hide: false },
                { text: 'station_name', value: 'station_name', sortable: false, align: 'start', hidden: false, hide: false },
                { text: 'gateway_id', value: 'gateway_id', sortable: false, align: 'start', hidden: true, hide: true },
                { text: 'gateway_id_hex', value: 'gateway_id_hex', sortable: false, align: 'start', hidden: true, hide: true },
                { text: 'product_number', value: 'product_number', sortable: false, align: 'start', hidden: true, hide: true },
                { text: 'username', value: 'username', sortable: false, align: 'start', hidden: true, hide: true },
                { text: 'user_email', value: 'user_email', sortable: false, align: 'start', hidden: true, hide: true },
                { text: 'company_name', value: 'company_name', sortable: false, align: 'start', hidden: true, hide: true },
                { text: 'active', value: 'active', sortable: false, align: 'start', hidden: false, hide: true },
                { text: 'private', value: 'private', sortable: false, align: 'start', hidden: false, hide: true },
                { text: 'recording_interval', value: 'recording_interval', sortable: false, align: 'center', hidden: false, hide: true },
                { text: 'firmware_version', value: 'firmware_version', sortable: false, align: 'start', hidden: true, hide: true },
                { text: 'imei', value: 'imei', sortable: false, align: 'start', hidden: true, hide: true },
                { text: 'registered_date', value: 'registered_date', sortable: false, align: 'center', hidden: false, hide: true },
                { text: 'subscription_end_date', value: 'subscription_end_date', sortable: false, align: 'center', hidden: false, hide: true },
                { text: 'time_zone', value: 'time_zone', sortable: false, align: 'start', hidden: true, hide: true },
                { text: 'city', value: 'city', sortable: false, align: 'start', hidden: true, hide: true },
                { text: 'region', value: 'region', sortable: false, align: 'start', hidden: true, hide: true },
                { text: 'country', value: 'country', sortable: false, align: 'center', hidden: true, hide: true },
                { text: 'latitude', value: 'latitude', sortable: false, align: 'center', hidden: false, hide: true },
                { text: 'longitude', value: 'longitude', sortable: false, align: 'start', hidden: false, hide: true },
                { text: 'elevation', value: 'elevation', sortable: false, align: 'start', hidden: true, hide: true },



            ],
        }),
        computed: {
            ...mapGetters({
                GET_weatherDataStations: 'GET_weatherDataStations',
                GET_weatherDataCurrent: 'GET_weatherDataCurrent',
            }),
            // Computed Table Headers            
            computedHeaders() {
                var t = this;
                var headers = t.headers;
                return (t.$filter(headers, {
                    hidden: false, // filter headers based on initial hidden value
                }));
            },
            // Computed Table Data
            computedTableData() {
                var t = this;
                var tableData = t.tableData;
                // // Filter by status
                // tableData = tableData.filter(function(item) {
                //     return t.filter.status.includes(item[t.collectionItem+'Status']);
                // });
                // // Filter by type
                // if ((t.filter.type !== '') && (t.filter.type !== undefined) && (t.filter.type !== null)) {                    
                //     tableData = tableData.filter(function(item) {
                //         return t.filter.type === item[t.collectionItem + 'Type'];
                //     });
                // }
                // // Filter by Search Mobile                
                // if (t.search !== '' && t.$vuetify.breakpoint.smAndDown) {
                //     tableData = tableData.filter(function(item) {
                //         var itemName = item[t.collectionItem+'Name'].toUpperCase();
                //         var search = t.search.toUpperCase();
                //         return itemName.includes(search)
                //     });
                // }
                var tableDataList = tableData.map(e => {
                    return {
                        station_id: e.station_id,
                        station_name: e.station_name,
                        gateway_id: e.gateway_id,
                        gateway_id_hex: e.gateway_id_hex,
                        product_number: e.product_number,
                        username: e.username,
                        user_email: e.user_email,
                        company_name: e.company_name,
                        active: e.active,
                        private: e.private,
                        recording_interval: e.recording_interval,
                        firmware_version: e.firmware_version,
                        imei: e.imei,
                        registered_date: e.registered_date,
                        subscription_end_date: e.subscription_end_date,
                        time_zone: e.time_zone,
                        city: e.city,
                        region: e.region,
                        country: e.country,
                        latitude: e.latitude,
                        longitude: e.longitude,
                        elevation: e.elevation
//                        delete: t.MIX_formatDateTime(e.delete, 'x', 'DD-MMM-YY HH:mm'),
                    };
                });
                // Sort the Table by the Field in the Sort By Filter
                var sortResults = t.$sortBy(tableDataList, t.sortBy)
                if (!t.filter.sortAsc) {sortResults.reverse()}
                return (sortResults);      
            }            

        },
        methods: {
        },
        watch: {
            GET_weatherDataStations: {
                handler() {
                    this.tableData = this.GET_weatherDataStations;
                }, deep: true
            }
        },
        async mounted() {
            var t = this;
            t.tableData = t.GET_weatherDataStations;
        }

}
</script>


<style scoped>

.contentWidth {
    width: calc(100vw - 150px) !important
}

</style>
