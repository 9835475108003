<template>
<div id="chart">
            <div class="" style="width: 600px !important; height: 300px !important;">
                <linechart :key="chartData.updated" :chartdata="chartData" :title="$t('charts.linechart.title')" width="100%" height="100%" />
            </div>
            <div class="" style="width: 600px !important; height: 300px !important;">
                <linechart :key="chartData.updated" :chartdata="chartData3" :title="$t('charts.linechart.title')" width="100%" height="100%" />
            </div>
            <div class="" style="width: 600px !important; height: 300px !important;">
                <barchart :key="chartData.updated" :chartdata="chartData" :title="$t('charts.barchart.title')" width="100%" height="100%" />
            </div>
            <div class="" style="width: 600px !important; height: 300px !important;">
                <piechart :key="chartData.updated" :chartdata="chartData3" :title="$t('charts.piechart.title')" width="100%" height="100%" />
            </div>
            <div class="" style="width: 600px !important; height: 300px !important;">
                <semidonut :key="chartData.updated" :chartdata="chartData3" :title="$t('charts.semidonut.title')" width="100%" height="100%" />
            </div>
            <div class="" style="width: 600px !important; height: 300px !important;">
                <zoomable :key="chartData.updated" :chartdata="chartData" :title="$t('charts.zoomable.title')" width="100%" height="100%" />
            </div>
            <div class="" style="width: 600px !important; height: 300px !important;">
                <annotated :key="chartData.updated" :chartdata="chartData" :title="$t('charts.annotated.title')" width="100%" height="100%" />
            </div>
            <div class="" style="width: 600px !important; height: 300px !important;">
                <radar :key="chartData.updated" :chartdata="chartData" :title="$t('charts.radar.title')" width="100%" height="100%" />
            </div>
            <div class="" style="width: 600px !important; height: 300px !important;">
                <polararea :key="chartData.updated" :chartdata="chartData3" :title="$t('charts.polararea.title')" width="100%" height="100%" />
            </div>
            <div class="" style="width: 600px !important; height: 300px !important;">
                <donut :key="chartData.updated" :chartdata="chartData3" :title="$t('charts.donut.title')" width="100%" height="100%" />
            </div>
            <div class="" style="width: 600px !important; height: 300px !important;">
                <radialbar :key="chartData.updated" :chartdata="chartData3" :title="$t('charts.radialbar.title')" width="100%" height="100%" />
            </div>
            <div class="" style="width: 600px !important; height: 300px !important;">
                <gauge :key="chartData.updated" :chartdata="chartData4" :title="$t('charts.gauge.title')" width="100%" height="100%" />
            </div>
            <div class="" style="width: 600px !important; height: 300px !important;">
                <scatter :key="chartData.updated" :chartdata="chartData2" :title="$t('charts.scatter.title')" width="100%" height="100%" />
            </div>
            <div class="" style="width: 600px !important; height: 300px !important;">
                <timeline :key="chartData.updated" :chartdata="chartData5" :title="$t('charts.timeline.title')" width="100%" height="100%" />
            </div>
            <div class="" style="width: 600px !important; height: 300px !important;">
                <heatmap :key="chartData.updated" :chartdata="chartData6" :title="$t('charts.heatmap.title')" width="100%" height="100%" />
            </div>
            <div class="" style="width: 600px !important; height: 300px !important;">
                <areachart :key="chartData.updated" :chartdata="chartData" :title="$t('charts.areachart.title')" width="100%" height="100%" />
            </div>
            <v-btn class="black white--text">{{ $t('home.button1') }}</v-btn>
            <v-btn @click="updateData">{{ $t('home.update-button') }}</v-btn>
            <!-- <pre>{{ chartData }}</pre> -->
        </div>
</template>

<script>

export default {
    name: 'Charts',
    data: () => ({
        chartData: {
            name: "Desktops",
            updated: 1,
            //data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
            data: [
                { x: 'Jan', y: '10'},
                { x: 'Feb', y: '41'},
                { x: 'Mar', y: '35'},
                { x: 'Apr', y: '51'},
                { x: 'May', y: '49'},
                { x: 'Jun', y: '62'},
                { x: 'Jul', y: '69'},
                { x: 'Aug', y: '91'},
                { x: 'Sep', y: '148'},

            ]
        },  
        chartData2: [{
            data: [ [2.5, 10], [27.1, 10], [2.9, 11.5], [7.1, 10.8], [2.1, 12] ]
            },{
            data: [ [16.4, 5.4], [21.7, 2], [25.4, 3], [19, 2], [10.9, 1] ]
            },{
            data: [ [21.7, 3], [23.6, 3.5], [24.6, 3], [29.9, 3], [21.7, 20] ]
        }],
        chartData3: {
            data: [10, 41, 35, 51, 49],
        },
        chartData4: {
            data: [56]
        },
        chartData5: [{
            data: [
                { x: 'Code', y: [new Date('2019-03-02').getTime(),new Date('2019-03-04').getTime()] },
                { x: 'Test', y: [new Date('2019-03-04').getTime(),new Date('2019-03-08').getTime()] },
                { x: 'Validation', y: [new Date('2019-03-08').getTime(),new Date('2019-03-12').getTime()] },
                { x: 'Deployment', y: [new Date('2019-03-12').getTime(),new Date('2019-03-18').getTime()] }
            ]
        }],
        chartData6: [{
            name: 'Metric1',
            data: [10, 48, 65, 81, 64, 83, 1, 16]
        }, {
            name: 'Metric2',
            data: [64, 18, 48, 81, 12, 34, 78, 64]
        },{
            name: 'Metric3',
            data: [14, 65, 42, 90, 64, 94, 84, 12]
        },{
            name: 'Metric4',
            data: [54, 49, 56, 45, 78, 64, 35, 6]
        },{
            name: 'Metric5',
            data: [10, 48, 65, 81, 64, 83, 1, 100]
        }]
    }),
    methods: {
        updateData() {
            // console.log('update data')
            this.chartData.data[0].y = '20';
            this.chartData3.data[0] = '30';
            this.chartData.updated ++;
        }
    },
    created () {
        // console.log(api_URL)
    },
  }
</script>