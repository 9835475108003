<template>
    <div>
        <apexchart :options="chartOptions"  :height="height" :width="width" :series="series" ></apexchart>
     </div>
</template>

<script>
export default {
    props: {
        width: { type: String, default: '100%' },
        height: { type: String, default: '100%' },
        title: { type: String, default: 'Chart Title' },
        chartdata: { 
            type: Number,
            default: 0, 
            // default: function() { 
            //     return {} 
            // }
        },
    },
    // mounted () {
    //     this.renderChart(this.chartData, this.options)
    

    data: () => ({
        series: [],
        chartOptions: {
            chart: {
                height: 50,
                type: 'radialBar',
                offsetY: -20,
                sparkline: {
                    enabled: true
                },
                fill: {
                    colors: []
                }
            },
            title: {
                text: '',
            },
            plotOptions: {
                radialBar: {
                    startAngle: -90,
                    endAngle: 90,
                    track: {
                        background: "lightgrey",
                        strokeWidth: '100%',
                        margin: 5,
                        dropShadow: {
                            enabled: false,
                            // top: 2,
                            // left: 0,
                            // color: '#999',
                            // opacity: 1,
                            // blur: 2,
                        }
                    },
                    dataLabels: {
                        name: {
                            show: false,
                            fontSize: '22px',
                        },
                        value: {
                            formatter: function(val) {
                                return parseInt(val);
                            },
                            fontSize: '16px',
                            offsetY: -2,
                        },
                        // total: {
                            // show: true,
                            // label: 'Total',
                            // formatter: function (val) {
                                // return ()
                            // }
                        // }
                    }
                }
            }
            
        },
    }),
    methods: {
        updateChart() {
            // console.log('updatechart')
            //console.log('this.chartdata = ' + JSON.stringify(this.chartdata))
//            this.chartOptions.labels = ['10'] //this.chartdata.data;
            this.series = [];
                    // console.log(this.chartdata)

            this.series.push (JSON.stringify(this.chartdata))
//            var chartData = []
            // chartData.push (this.chartdata)
            // this.series.push (chartData)
        }
    },
    mounted () {
        this.chartOptions.title.text = this.title // save prop into title
        this.chartOptions.chart.height = this.height;
        this.chartOptions.chart.width = this.width;
        this.chartOptions.chart.fill.colors[0] = this.$vuetify.theme.themes.light.secondary  
        this.updateChart();
    }
}
</script>