<template>
     <apexchart :options="chartOptions"  :height="height" :width="width" :series="series" ></apexchart>
</template>

<script>
export default {
    props: {
        width: { type: String, default: '100%' },
        height: { type: String, default: '100%' },
        title: { type: String, default: 'Chart Title' },
        chartdata: { type: Object, default: function() { return {} } },
    },
    // mounted () {
    //     this.renderChart(this.chartData, this.options)
    

    data: () => ({
        series: [],
        chartOptions: {
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May'],
            chart: {
                height: 50,
                type: 'polarArea',
            },
            title: {
                text: 'Polar Area',
            },
            stroke: {
                colors: ['#fff']
            },
            fill: {
                opacity: 0.8
            },
            // responsive: [{
                // breakpoint: 480,
                // options: {
                    // chart: {
                        // width: 200
                    // },
                    // legend: {
                        // position: 'bottom'
                    // }
                // }
            // }],
        },
    }),
    methods: {
        updateChart() {
            // console.log('updatechart')
            // console.log('this.chartdata = ' + JSON.stringify(this.chartdata))
            this.series = [];
            this.series = this.chartdata.data;
        }
    },
    mounted () {
        this.chartOptions.title.text = this.title // save prop into title
        this.chartOptions.chart.height = this.height;
        this.chartOptions.chart.width = this.width;
        // this.chartOptions.chart.fill.colors[0] = this.$vuetify.theme.themes.light.primary  
        this.updateChart();
    }
}
</script>