import Vue from 'vue';
import Vuetify from "vuetify"; // DEFINITELY NOT "vuetify/lib"
import "../node_modules/vuetify/dist/vuetify.min.css"; // Add this line
import "@mdi/font/css/materialdesignicons.css"; //material icons

Vue.use(Vuetify);

const opts = {
    options: {
      customProperties: true
    },
    theme: {
      themes: {
        light: {
          primary: '#146AB5',
          secondary: '#FCD72E',
          accent: '#FFFFFF',
        //   info: '#E0E0E0',
          lightgrey: '#E0E0E0',
          darkgrey: '#4f4f4f',
          darktext: '#4f4f4f',
          lighttext: '#FFFFFF',
          appbackground: '#F2F2F2',
          success: '#00942A',
          warning: '#E04B0D',
          error: '#E10203',
          info: '#4492DA',
          vindicoPurple: '#582468',
          iconColorState: 'b3b3b3'
        },
        dark: {
            primary: '#FCD72E',
            secondary: '#FCD72E',
            accent: '#4F4F4F',
          //   info: '#E0E0E0',
            lightgrey: '#4f4f4f',
            darkgrey: '#4f4f4f',
            darktext: '#FFFFFF',
            lighttext: '#4f4f4f',
            appbackground: '#333333',
            success: '#00b533',
            warning: '#E04B0D',
            error: '#db1f1f',
            info: '#4492DA',
            vindicoPurple: '#582468',
            iconColorState: 'b3b3b3'
          },
      },
    },
    breakpoint: {
      thresholds: {
        xs: 340,
        sm: 600, // 540,
        md: 800,
        lg: 1500,  // sets Ipad 
      },
      scrollBarWidth: 24,
    },
  };

export default new Vuetify(opts);
