<template>
  <div id="mapContainer" :style="style"></div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import userPin from '../../assets/user_pin.png';

export default {
    name: 'mapbox',
    props: {
        width: {
            type: String,
            default: '100%',
            required: false,
        },
        height: {
            type: String,
            default: '100%',
            required: false,
        },
    },
    data: () => ({
        accessToken:
            'pk.eyJ1IjoidmluZGljbyIsImEiOiJjanY2a3VpanQwMHZhM3lwZm1oaWxhemtnIn0.D8js3Hp9ss34dscb5Z1Y9Q',
        map: {},
        features: [],
        geojson: {},
    }),
    computed: {
        ...mapState('mapbox_store/', {
            mapbox: state => state.map,
            marker: state => state.marker
        }),
        ...mapGetters({
            GET_mapboxData: 'GET_mapboxData',
            GET_features: 'mapbox_store/GET_features',
            GET_marker: 'mapbox_store/GET_marker',
            GET_weatherDataStations: 'GET_weatherDataStations',
            GET_zone: 'mapbox_store/GET_zone',
            GET_activeStation: 'GET_activeStation',
        }),
        zone: {
            get () {
                const zone = this.GET_zone;
                return zone;
            }
        },
        stations: {
            get () {
                const stations = this.GET_weatherDataStations;
                return stations;
            }
        },
        style() {
            return `width: ${this.width} !important; height: ${this.height} !important;`
        }
    },
    watch: {
        GET_marker: {
            handler: function() {
                switch (this.GET_marker.method) {
                    case 'create':
                        this.geojson = [];
                        this.createNode(this.GET_marker.data.id, this.GET_marker.data);
                        break;
                    case 'update':
                        // console.log(
                        //     `id: ${this.GET_marker.properties.id} | ${JSON.stringify(
                        //         this.GET_marker.properties,
                        //         null,
                        //         2
                        //     )}`
                        // );
                        this.updateNode(
                            this.GET_marker.properties.id,
                            this.GET_marker.properties
                        );
                        break;
                    case 'delete':
                        this.geojson = [];
                        this.deleteNode(this.GET_marker.data.id);
                        break;
                    default:
                        // do nothing
                        break;
                }
            },
            deep: true,
        },
        mapbox: {
            handler() {
                this.createMap();
            },
            deep: true,
        },
    },
    methods: {
        createMap() {
            // console.log('createmap');
            this.$mapboxgl.accessToken = this.accessToken;
            this.map = new this.$mapboxgl.Map({
                container: 'mapContainer',
                style: this.mapbox.style,
                center: this.mapbox.center,
                zoom: this.mapbox.zoom,
                generateId: true
            });
            this.map.addControl(new this.$mapboxgl.NavigationControl());
            const usersLocation = 
                new this.$mapboxgl.GeolocateControl({
                    positionOptions: {
                        enableHighAccuracy: true,
                    },
                    showAccuracyCircle: true,
                    trackUserLocation: true
                });   
            this.map.addControl(usersLocation);
            this.map.on('load', () => {
                document.querySelector('#button').onclick = () => {
                    usersLocation.trigger();
                    const stations = this.stations;          
                    const zone = this.zone;
                    this.map.setZoom(9);           
                    stations.forEach(station => {
                        if (station.station_name === zone[0].key) {
                            this.map.setFeatureState({ source: station.station_id, id: 0}, { clicked: true });
                            this.MIX_activeStation(station);
                        }
                    });      
                }
            });
            usersLocation.on('geolocate', (e) => {
                const id = e.timestamp.toString();
                this.map.loadImage(userPin, (error, image) => {
                    // console.log(e);
                    if (error) throw error;
                    this.map.addImage('user_pin', image);
                    this.map.setZoom(9);
                    this.map.addLayer({
                        id: id,
                        type: "symbol",
                        source: {
                            type: "geojson",
                            data: {
                                type: "FeatureCollection",
                                features: [{
                                    type: "Feature",
                                    geometry: {
                                        type: "Point",
                                        coordinates: [e.coords.longitude, e.coords.latitude]
                                    }
                                }]
                            },
                        },
                        layout: {
                          "icon-image": "user_pin",
                          "icon-size": 0.8
                        }
                    });
                    this.map.addLayer({
                        id: id + '-label',
                        type: 'symbol',
                        source: id,
                        layout: {
                            'text-field': 'You are here',
                            'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
                            'text-offset': [0, 2.5],
                            'text-anchor': 'bottom',
                        },
                        paint: {
                            'text-color': '#146ab5',
                        },
                    });
                });
            })
        },
        alert(props) {
            alert(JSON.stringify(props, null, 2));
        },
        deleteNode(id) {
            const t = this;
            if (typeof t.map.getLayer(id) !== 'undefined') {
                // Remove map layer & source.
                t.map.removeLayer(id);
                t.map.removeLayer(id + '-label');
                t.map.removeLayer(id + '-value');
                t.map.removeSource(id);
            }
        },
        updateNode(id, properties) {
            // console.log('update node');
            const t = this;
            const mapLayer = t.map.getLayer(id);
            if (typeof mapLayer !== 'undefined') {
                // console.log('not undefined');
                const features = t.map.queryRenderedFeatures({ layers: [id] });
                if (JSON.stringify(features) !== '[]') {
                    // console.log('new features = ' + JSON.stringify(features, null, 2));
                    features[0].properties.airquality = properties.airquality;
                    features[0].properties.pm25 = properties.pm25;
                    features[0].properties.temp = properties.temp;
                    features[0].properties.pm10 = properties.pm10;
                    features[0].properties.updated = properties.updated;
                    t.map.getSource(id).setData({
                        type: 'FeatureCollection',
                        features: features,
                    });
                }
          }
        },
        createNode(id, data) {
            const t = this;
            t.geojson = data;
            t.map.addSource(id, {
                type: 'geojson',
                data: t.geojson,
                generateId: true
            });
            // Add Marker Circle
            t.map.addLayer({
                id: id,
                type: 'circle',
                source: id,
                paint: {
                    'circle-radius': {
                        stops: [
                            [5, 70],
                            [10, 120],
                        ],
                        base: 5
                    },
                    'circle-opacity': 0.5,
                    'circle-color': 'rgba(20, 106, 181, 0.11)',
                    'circle-stroke-color': [
                        'case', ['boolean', ['feature-state', 'clicked'], false],
                            'rgba(20,106,181, 1)',
                            'rgba(20, 106, 181, 0.11)',
                    ],
                    'circle-stroke-width': [
                        'case', ['boolean', ['feature-state', 'clicked'], false],
                            2,
                            0,
                    ],
                },
            });
            // Add Marker Label
            t.map.addLayer({
              id: id + '-label',
              type: 'symbol',
              source: id,
              layout: {
                'text-field': ['get', 'name'],
                'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
                'text-offset': [0, 0],
                'text-anchor': 'center',
                'text-max-width': 5,
              },
              paint: {
                'icon-halo-width': 10,
                'text-color': '#146ab5',
              },
            });
            // Add Marker Value
            t.map.addLayer({
                id: id + '-value',
                type: 'symbol',
                source: id,
                layout: {
                    'text-field': ['get', 'pm25'],
                    'text-size': 12,
                    'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
                },
                paint: {
                    'icon-halo-width': 10,
                    'text-color': '#ffffff',
                },
            })
            t.map.on('click', id, (e) => {
                const features = t.map.queryRenderedFeatures(e.point, {
                    layers: [id],
                });
                const uniqueId = e.features[0]['id'];
                t.ACT_update_layerSelected(features[0].properties);
                t.map.setFeatureState({ source: id, id: uniqueId }, { clicked: true });
                const stations = t.stations;                 
                stations.forEach(station => {
                    if (station.station_name === features[0].properties.name) {
                        this.MIX_activeStation(station);
                    }
                }); 
                t.MIX_go('/farming'); 
            });
            t.map.on('mouseenter', id, (e) => {
                const uniqueId = e.features[0]['id'];
                t.map.getCanvas().style.cursor = 'pointer';
                t.map.setFeatureState({ source: id, id: uniqueId }, { clicked: true });
            });
            t.map.on('mouseleave', id, () => {
                const uniqueId = 0;
                t.map.getCanvas().style.cursor = '';
                t.map.setFeatureState({ source: id, id: uniqueId }, { clicked: false });
            });
        },
    },  
    mounted() {
    //   console.log('mapbox component mounted');
      this.createMap;
    },
};  
</script>
