<!-- //* TODO 
-->


<template>
    <div>
        <h2 class="font-weight-light primary--text">{{ forecast.main }}</h2>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'; // Map Actions / Getters
export default {
    data: () => ({
        forecast: {
            main: '-',
        }
    }),
    computed: {
        ...mapGetters({
                GET_weatherDataForecast: 'GET_weatherDataForecast',
        }),
    },
    watch: {
        GET_weatherDataForecast: {
            handler() {
                this.forecast = this.GET_weatherDataForecast;
                this.updateForecast();
            },deep: true
        },
    },
    methods: { 
        // getSensor: function(sensor_type) {
            // return this.current.find(sensor => parseInt(sensor.sensor_type) === parseInt(sensor_type) )
        // },
        updateForecast() {
            var t = this;
            t.forecast.main = t.forecast.current.weather.main
        },
    },
    // mounted () { 
        // this.updateSensors();
    // }
    // async mounted () {
        // var t = this;
        // var stationId = 107021;
        // t.station = await t.GET_weatherDataStationById(stationId);
        // await t.MIX_weatherDataForecast(t.station.latitude, t.station.longitude);
    // }
}
</script>