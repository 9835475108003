import firebase from 'firebase/app';
import 'firebase/analytics'
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyA1fyrWT8ivJ1t5qOSULosDhXkXURu-W9o",
    authDomain: "agriculture-research.firebaseapp.com",
    projectId: "agriculture-research",
    storageBucket: "agriculture-research.appspot.com",
    messagingSenderId: "58640196366",
    appId: "1:58640196366:web:38134bc63b0a123c71ed5f",
    measurementId: "G-0CMVYBL58V"
  };

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const analytics = firebase.analytics();
const auth = firebase.auth();
const storage = firebase.storage();
const currentUser = auth.currentUser;
const fv = firebase.firestore.FieldValue;

//https://europe-west1-agriculture-research.cloudfunctions.net/checkAdminRegCode?code=Farming2021!

export {
  db,
  analytics,
  storage,
  auth,
  currentUser,
  fv
};