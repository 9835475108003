<template>
    <div class="d-flex align-start flex-column justify-start fill-height" style="width: 100% !important;">
        <v-container fluid height="160" width=100% style="padding: 0px !important;">
            <v-card flat color="lightgrey">
                <v-row class="pt-6" >
                    <v-col cols="12" class="px-8">
                        <div class="text-h5 primary--text font-weight-thin">{{ $t('farming.title') }}</div>
                        <span class="body-2 font-weight-regular darktext--text">{{ $t('farming.description') }}</span>
                    </v-col>
                </v-row>
                <v-row class="mx-3 pt-4"> 
                    <v-select v-model="station" :label="$t('farming.selectLocation')" :items="computedStations" item-text="station_name" return-object background-color="accent" dense outlined>{{ station }}</v-select> 
                </v-row>
            </v-card>
            <v-row class="pt-2 px-2" no-gutters>    
                <v-col cols="12" class="px-2 mt-2">
                    <div class="text-h5 font-weight-thin darktext--text text--darken-2 pl-1 pt-1">{{ $t('farming.activity') }}</div>
                </v-col>
                <v-col cols="12" xs="12" sm="6" md="12" lg="12">
                    <v-row no-gutters>
                        <v-col cols="6" md="2" class="pa-2" @click="go('live')">
                            <v-card flat :color="liveButtonColorState" width="100%" height="100" class="d-flex align-center justify-center"> 
                                <div>
                                    <v-row class="align-center justify-center"> 
                                        <v-icon size="50" :color="liveIconColorState">icons8-record</v-icon> 
                                    </v-row>
                                    <v-row class="align-center justify-center">
                                         <div class="caption grey--text font-weight-regular text-center pt-4 px-4">{{ $t('menu.liveView') }}</div>
                                    </v-row> 
                                </div>
                            </v-card>
                        </v-col>
                        <v-col cols="6" md="2" class="pa-2" @click="go('slurry')">
                            <v-card flat :color="buttonColorState" width="100%" height="100" class="d-flex align-center justify-center"> 
                                <div>
                                    <v-row class="align-center justify-center"> 
                                        <v-icon size="50" :color="iconColorState">icons8-oil-tanker</v-icon> 
                                    </v-row>
                                    <v-row class="align-center justify-center" width="100">
                                        <div class="caption grey--text font-weight-regular text-center pt-4">{{ $t('menu.slurry') }}</div>
                                    </v-row> 
                                </div>
                            </v-card>
                        </v-col>
                        <v-col cols="6" md="2" class="pa-2" @click="go('muck')">
                            <v-card flat :color="buttonColorState" width="100%" height="100" class="d-flex align-center justify-center"> 
                                <div>
                                    <v-row class="align-center justify-center"> 
                                        <v-icon size="50" :color="iconColorState">icons8-tractor</v-icon> 
                                    </v-row>
                                    <v-row class="align-center justify-center" width="100">
                                        <div class="caption grey--text font-weight-regular text-center pt-4">{{ $t('menu.muck') }}</div>
                                    </v-row> 
                                </div>
                            </v-card>
                        </v-col>
                        <v-col cols="6" md="2" class="pa-2" @click="go('pesticide')">
                            <v-card flat :color="buttonColorState" width="100%" height="100" class="d-flex align-center justify-center"> 
                                <div>
                                    <v-row class="align-center justify-center"> 
                                        <v-icon size="50" :color="iconColorState">icons8-insecticide</v-icon> 
                                    </v-row>
                                    <v-row class="align-center justify-center" width="100">
                                        <div class="caption grey--text font-weight-regular text-wrap text-center pt-4 px-4">{{ $t('menu.pesticide') }}</div>
                                    </v-row> 
                                </div>
                            </v-card>
                        </v-col>
                        <v-col cols="6" md="2" class="pa-2" @click="go('harvesting')">
                            <v-card flat :color="buttonColorState" width="100%" height="100" class="d-flex align-center justify-center"> 
                                <div>
                                    <v-row class="align-center justify-center"> 
                                        <v-icon size="50" :color="iconColorState">icons8-harvester</v-icon> 
                                    </v-row>
                                    <v-row class="align-center justify-center" width="100">
                                        <div class="caption grey--text font-weight-regular text-center pt-4">{{ $t('menu.harvesting') }}</div>
                                    </v-row> 
                                </div>
                            </v-card>
                        </v-col>   
                        <v-col cols="6" md="2" class="pa-2" @click="go('fertiliser')">
                            <v-card flat :color="buttonColorState" width="100%" height="100" class="d-flex align-center justify-center"> 
                                <div>
                                    <v-row class="align-center justify-center"> 
                                        <v-icon size="50" :color="iconColorState">icons8-field</v-icon> 
                                    </v-row>
                                    <v-row class="align-center justify-center" width="100">
                                        <div class="caption grey--text font-weight-regular text-center pt-4 px-4">{{ $t('menu.fertiliser') }}</div>
                                    </v-row> 
                                </div>
                            </v-card>
                        </v-col>                                          
                    </v-row>
                </v-col>
            </v-row>
            <v-row class="justify-center mt-6">
                <v-btn elevation="0" class="primary lighttext--text" to="/sites">{{ $t('buttons.backToMap')}}</v-btn>
            </v-row>

        </v-container>

        <v-dialog v-model="registerDialog">
            <v-card class="pa-5">
                <div class="text-h6 primary--text font-weight-light">{{ $t('registration.registrationRequired') }}</div>
                <div class="mt-2 body-1">{{ $t('registration.access') }}</div>
                <v-row class="my-4">
                    <v-col cols="6">
                        <v-btn block depressed large @click="MIX_go('/login')" class="mt-3 grey darken-2 white--text">{{ $t('buttons.login') }}</v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="6">
                        <v-btn block depressed @click="MIX_go('/register')" large class="mt-3 primary">{{ $t('buttons.register') }}</v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
        <!-- <v-col cols="12"> -->
            <!-- <pre v-if="JSON.stringify(current) !== '[]'"> {{ current }}</pre>  -->
        <!-- </v-col> -->
        <!-- <v-col> -->
            <!-- Forecast <br /><pre>{{ GET_weatherDataForecast }}</pre> -->
        <!-- </v-col> -->
    </div> 
</template>

<script>
// @ is an alias to /src
//
// require('csg-wp-api.node_modules.index.js')
    import { mapGetters } from 'vuex'; // Map Actions / Getters
    export default {
    name: 'Farming',
    data: () => ({
        items: ['Gelli Aur', 'Example 1', 'Example 2'],
        registerDialog: false,
        current: [],
        forecast: {
            main: '-'
        },
        buttonColorState: 'lightgrey',
        iconColorState: 'iconColorState',
        liveButtonColorState: 'primary',
        liveIconColorState: 'accent',
        sensors: {
            temperature: '-',
            humidity: '-',
            rainfall: '-',
            wind_direction: '-',
            wind_speed: '-',
            soil_moisture: '-',

            //create all of the sensor values
        },
        temperatureGaugeKey: 0,
        humidityGaugeKey: 0,
        date: new Date().toISOString().substr(0, 10),
        time: null,
        startmenu: false,
        endmenu: false,
        startmenu2: false,
        endmenu2: false,
        tab: false,
        chartData: {
            name: "Desktops",
            updated: 1,
            data: [
                { x: 'Jan', y: '10'},
                { x: 'Feb', y: '41'},
                { x: 'Mar', y: '35'},
                { x: 'Apr', y: '51'},
                { x: 'May', y: '49'},
                { x: 'Jun', y: '62'},
                { x: 'Jul', y: '69'},
                { x: 'Aug', y: '91'},
                { x: 'Sep', y: '148'},
            ]
        },  
        chartData4: {
            data: [6]
        },
    }),
    computed: {
        ...mapGetters({
                GET_activeStation: 'GET_activeStation',
                GET_currentUser: 'GET_currentUser',
                GET_weatherDataStations: 'GET_weatherDataStations',
                GET_weatherDataCatalog: 'GET_weatherDataCatalog',
                GET_weatherDataCurrent: 'GET_weatherDataCurrent',
                GET_weatherDataForecast: 'GET_weatherDataForecast',
        }),
        station: {
            get () {
                let t = this;
                if (t.GET_currentUser !== null) {
                    t.buttonColorState = 'accent';
                    t.iconColorState = 'primary';
                }
                t.liveButtonColorState = 'accent';
                t.liveIconColorState = 'primary';
                return this.GET_activeStation;
            },
            set (value) {
                this.MIX_activeStation(value);
                // console.log(this.GET_activeStation.station_id)
            }
        },
        computedStations() {
            const stations = [];
            this.GET_weatherDataStations.forEach(function(station) {
                stations.push(station);
            });
            stations.sort((a, b) => a.station_name.localeCompare(b.station_name))
            return stations;
        }
    },
    methods: {
        go(facility) {
            let t = this;
                if (JSON.stringify(t.station) === '{}') {
                    t.MIX_alert(-1, 'Select a Location First', null, null) 
                } else {
                    if (facility === 'live') {
                        t.MIX_go(`/${facility}/${t.station.station_id}`)
                    } else {
                        if (t.GET_currentUser !== null) {
                            t.MIX_go(`/${facility}/${t.station.station_id}`)
                        } else {
                            t.registerDialog = true;
                        }                         
                    }
                }
        },
        updateForecast() {
            var t = this;
            t.forecast.main = t.forecast.current.weather.main
        },
        toggleDarkTheme() {
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
            // console.log(this.$vuetify.theme.dark);
        },
    },
    async mounted () {
        let t = this;
        t.MIX_weatherDataStations();
        if (this.station === '') {
            if (this.GET_activeStation !== null) {
                this.station === this.GET_activeStation;
            }
        }
     }
  }
</script>

<style> *{ text-transform: none; } </style>