import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

import Activity from '@/views/activity.vue';
import Admin from '@/views/admin';
import Catalog from '@/views/admin/catalog';
import Charts from '@/views/charts.vue';
import Farming from '@/views/farming.vue';
import Fertiliser from '@/views/fertiliser_spreading.vue';
import Forecast from '@/views/forecast.vue';
import Harvesting from '@/views/harvesting.vue';
import Home from '@/views/Home.vue';
import Live from '@/views/live.vue';
import Muck from '@/views/muck_spreading.vue';
import Pesticide from '@/views/pesticide_spreading.vue';
import Profile from '@/views/profile.vue';
import Sites from '@/views/sites.vue';
import Slurry from '@/views/slurry_spreading.vue';
import Station from '@/views/admin/station';
import Stations from '@/views/admin/stations';
import Information from "@/views/information";

import Dashboard from '@/views/dashboard copy';

// Auth routes Import
import Register from '@/views/auth/register.vue';
import Login from '@/views/auth/login.vue';
import Reset from '@/views/auth/reset.vue';

Vue.use(VueRouter)

const routes = [
    {path: '/', name: 'Home', component: Home},
    {path: '/about', name: 'About', component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue')},
    {path: '/activity', name: 'Activity', component: Activity},
    {path: '/charts', name: 'Charts', component: Charts, meta: {requiresAuth: true}},
    {path: '/dashboard/:stationid?', name: 'Dashboard', component: Dashboard},
    {path: '/farming/:stationid?', name: 'Farming', component: Farming},
    {path: '/fertiliser/:stationid?', name: 'Fertiliser', component: Fertiliser},
    {path: '/forecast', name: 'Forecast', component: Forecast},
    {path: '/harvesting/:stationid?', name: 'Harvesting', component: Harvesting},
    {path: '/live/:stationid?', name: 'Live', component: Live},
    {path: '/muck/:stationid?', name: 'Muck', component: Muck},
    {path: '/pesticide/:stationid?', name: 'Pesticide', component: Pesticide},
    {path: '/profile', name: 'Profile', component: Profile},
    {path: '/sites', name: 'Sites', component: Sites},
    {path: '/slurry/:stationid?', name: 'Slurry', component: Slurry},
    {path: "/information", name: "Information", component: Information},
    {
        path: '/admin/', name: 'Admin', component: Admin,
        children: [
            {path: '/admin/stations', name: 'Stations', component: Stations},
            {path: '/admin/stations/:stationid?', name: 'Station', component: Station},
            {path: '/admin/catalog', name: 'Catalog', component: Catalog},
        ]
    },
    {path: '/register/:id?', name: 'Register', component: Register},
    {path: '/login', name: 'Login', component: Login},
    {path: '/reset', name: 'Reset', component: Reset}
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
router.beforeEach((to, from, next) => {
    // Check for Update to Service Worker
    navigator.serviceWorker.register('/service-worker.js').then(reg => {
        // sometime later…
        //console.log('Check for Update to Service Worker')
        reg.update();
    });
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    // TODO uncomment when adding userLevel check
    ////const userLevel = to.matched.find(record => record.meta.requiresUserLevel).meta.requiresUserLevel;
    ////const requiresUserLevel = to.matched.some(record => record.meta.requiresUserLevel);
    if (requiresAuth) {
        let checkUser = store.getters['firebase_auth_store/GET_FIREBASE_currentUser'];
        // console.log('USER ROUTER: ' + JSON.stringify(checkUser, null, 2))
        if (!checkUser) {
            next("/login");
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
  