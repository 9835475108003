<template>
  <v-navigation-drawer v-if="$vuetify.breakpoint.mdAndUp" app class="darkgrey noprint" clipped expand-on-hover
                       permanent>
    <v-list dense ma-0 nav pa-0>
      <v-list-item v-for="(item, index) in computedTabs" :key="index" :to="item.path" active-class="secondary" link>
        <v-list-item-icon class="white--text">
          <v-icon class="navicons white--text">{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="white--text">{{ $t(item.name) }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: "NavigationDrawer",
  data: () => ({
    tabs: [
      {name: "navbar.home", path: "/", icon: "icons8-home-page", userLevels: ['CU', 'SA', 'DA']},
      {name: "navbar.farming", path: "/farming", icon: "icons8-tractor", userLevels: ['CU', 'SA', 'DA']},
      {name: "navbar.forecast", path: "/forecast", icon: "icons8-rain-cloud", userLevels: ['CU', 'SA', 'DA']},
      //{ name: "Dashboard", path: "/dashboard", icon: "icons8-rain-cloud", userLevels: ['CU','SA','DA']},
      {name: "navbar.map", path: "/sites", icon: "icons8-map-marker", userLevels: ['CU', 'SA', 'DA']},
      {name: "navbar.information", path: "/information", icon: "icons8-information", userLevels: ['CU', 'SA', 'DA']},
      {name: "navbar.stations", path: "/admin/stations", icon: "icons8-gps-signal", userLevels: ['SA', 'DA']},
      {name: "navbar.catalog", path: "/admin/catalog", icon: "icons8-moleskine", userLevels: ['SA', 'DA']},
    ],
  }),
  computed: {
    ...mapGetters({
      GET_currentUser: 'GET_currentUser',
    }),
    computedTabs() {
      let t = this;
      var tabs = this.tabs;
      var newTabs = []
      var userLevel = '';
      if (t.getCurrentUser === undefined) {
        userLevel = 'CU';
      } else {
        userLevel = t.GET_currentUser.userLevel;
      }
      tabs.forEach(function (tab) {
        if (tab.userLevels.includes(userLevel)) {
          newTabs.push(tab)
        }
      })
      return newTabs
    }
  }
}
</script>

<style scoped>
.navicons {
  font-size: 24px !important;
  font-weight: bold !important;
  color: "accent"
}

.v-tab--active {
  background-color: var(--v-accent-base) !important;
}

.tabsBackgroundImage {
  /* background: url("../../assets/navigation-background.jpg") !important; */
  background-repeat: repeat;
  background-color: var(--v-accent-base) !important;
}
</style>