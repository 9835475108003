<!--
//*TODO 
 -->

<template>
    <div class="d-flex align-center flex-column justify-start fill-height" style="width: 100% !important;">
        <v-container fluid width=100% class="lightgrey pa-5">
            <v-card flat color="lightgrey" height=100%>
                <v-row class="pt-4 pl-4">
                    <v-col cols="2"  md="1" lg="1" class="d-flex align-center">
                        <v-row class=""> 
                            <div v-if="sensors.soil_moisture >= soilMoistureBarMin && period === 'OPEN'"> <v-icon size="40" color="success" position="center" class="pl-2">icons8-field</v-icon> </div>
                            <div v-else-if="sensors.soil_moisture < soilMoistureBarMin"> <v-icon size="40" color="error" position="center" class="pl-2">icons8-field</v-icon> </div>
                            <div v-else-if="period === 'CLOSED'"> <v-icon size="40" color="error" position="center" class="pl-2">icons8-field</v-icon> </div>
                            <div v-else> <v-icon size="40" color="grey" position="center" class="pl-2">icons8-field</v-icon> </div>
                        </v-row>
                    </v-col>

                    <v-col cols="10">
                        <div class=""> 
                            <div v-if="sensors.soil_moisture >= soilMoistureBarMin && period === 'OPEN'"> <h3 class="font-weight-normal success--text">{{ $t('menu.fertiliser') }}</h3> </div>
                            <div v-else-if="sensors.soil_moisture < soilMoistureBarMin"> <h3 class="font-weight-normal error--text">{{ $t('menu.fertiliser') }}</h3> </div>
                            <div v-else-if="period === 'CLOSED'"> <h3 class="font-weight-normal error--text">{{ $t('menu.fertiliser') }}</h3> </div>
                            <div v-else> <h3 class="font-weight-normal">{{ $t('menu.fertiliser') }}</h3> </div>
                            <div class=""> <h5><span v-if="JSON.stringify(station) !== '{}'">{{ station.station_name }} </span></h5> </div>
                        </div>    
                    </v-col>

                </v-row>
            </v-card>
        </v-container>

        <v-row class="pt-3" style="width: 100% !important;">    
            <v-col cols="12" xs="12" sm="6" md="5" lg="4">

                <dashcard :currentdata="currentData.fertiliserPeriod" :key="currentData.fertiliserPeriod.componentKey" />
                <dashcard :currentdata="currentData.soilTempMonthDegC" :key="currentData.soilTempMonthDegC.componentKey" />
                <weektemp :currentdata="currentData.soilTempWeekDegC" :key="currentData.soilTempWeekDegC.componentKey" />
                <dashcard :currentdata="currentData.soilMoistureBar" :key="currentData.soilMoistureBar.componentKey" />
                <forecast :currentdata="currentData.weatherForecast" :key="currentData.weatherForecast.componentKey" />

                <!-- <v-card flat color="appbackground" width=100% height="130" class="pt-4 pa-2"> 
                    <weatherforecast />
                </v-card>  -->


                <div v-if="sensors.soil_moisture >= soilMoistureBarMin">
                    <v-btn block depressed large @click="MIX_logActivity('fertiliser', station, currentData), overlay = !overlay" color="success" class="mt-3">{{ $t('buttons.activity') }}</v-btn>
                </div>
                <v-btn block depressed large @click="MIX_go('/farming')" class="mt-3 lightgrey">{{ $t('buttons.back') }}</v-btn>

                <v-overlay :value="overlay">
                    <v-card color="appbackground" flat style="width: 75vw impo !important">
                        <overlay :currentdata="currentData" :key="currentData.componentKey" :showButton="true" />
                        <v-col cols=12 class="">
                            <v-btn block depressed large @click="overlay = false" class="lightgrey darktext--text">{{ $t('buttons.back') }}</v-btn>   
                        </v-col>             
                    </v-card>
                </v-overlay>


            </v-col>
        </v-row>
    </div> 
</template>

<script>

import moment from 'moment'
import { mapGetters } from 'vuex'; // Map Actions / Getters
export default {
    name: 'FertiliserSpreading',
    data: () => ({
        items: ['Gelli Aur', 'Example 1', 'Example 2'],
        station: {},
        current: [],
        overlay: false,
        sensors: {
            soil_moisture: '-',
            soil_temp_average: '-',
            //create all of the sensor values
        },
        soilMoistureBarMin: 10,
        beforeEndOfOpenPeriod: '',
        currentData: {
            componentKey: 10,
            activity: {
                title: 'menu.fertiliser'
            },
            fertiliserPeriod: {
                title: 'weather.fertiliserPeriod.title',
                subtitle: 'weather.fertiliserPeriod.date',
                icon: 'icons8-calendar',
                value: '-',
                status: 'grey',
                componentKey: 0
            },
            soilTempWeekDegC: {
                title: 'weather.daily5SoilTemperature',
                value: [],
                componentKey: 2
            },
            soilTempMonthDegC: {
                title: 'weather.monthlySoilTemperature',
                unit: "°C",
                icon: 'icons8-temperature',
                value: '-',
                status: 'grey',
                componentKey: 4
            },
            soilMoistureBar: {
                title: 'weather.soilMoisture',
                unit: "bar",
                icon: 'icons8-soil',
                value: '-',
                status: 'grey',
                componentKey: 6
            },
            weatherForecast: {
                value: [],
                componentKey: 8
            }
        }


    }),
    computed: {
        ...mapGetters({
                GET_weatherDataStations: 'GET_weatherDataStations',
                GET_weatherDataStationById: 'GET_weatherDataStationById',
                GET_weatherDataCurrent: 'GET_weatherDataCurrent',
        }),
        period() { 
            return this.MIX_farmingPeriod("fertiliserPeriod")
        }
    },
    methods: {
        convertToCelcius: function (value) {
          if (!value) return ''
          return parseFloat(((5/9) * (parseFloat(value) - 32)).toFixed())
        },  
        getSensor: function(sensor_type) {
            return this.current.find(sensor => parseInt(sensor.sensor_type) === parseInt(sensor_type) )
        },
        updateSensors() {
            var t = this;    
            t.sensors.soil_moisture = parseFloat(t.getSensor(108).data[0].moist_soil_last) 
            var soil_temp_average = parseFloat(t.getSensor(105).data[0].temp_avg)     
            t.sensors.soil_temp_average = t.convertToCelcius(soil_temp_average);  
            t.sensors.soil_tempUpdated = t.getSensor(108).data[0].ts;
        },
        updateFertiliserPeriod() {
            var t = this;
            t.currentData.fertiliserPeriod.value = t.period
            if (t.currentData.fertiliserPeriod.value === 'OPEN') {
                t.currentData.fertiliserPeriod.status = 'success';
                t.currentData.fertiliserPeriod.value = 'weather.fertiliserPeriod.open'
            } else {
                t.currentData.fertiliserPeriod.status = 'ERROR';
                t.currentData.fertiliserPeriod.value = 'weather.fertiliserPeriod.closed'
            }
            t.currentData.fertiliserPeriod.componentKey++
        },
        updateSoilTempWeekDegC: async function(stationId) {
            var t = this;
            var todayDate = moment(new Date()).startOf("day").format('x')
            // console.log("todayDate = " + todayDate)
            var soilDataResult = await t.MIX_weatherDataSoilData(stationId, todayDate)
            // console.log("soil result = " + soilDataResult)
            soilDataResult.forEach((item) => {
                item.value = t.convertToCelcius(item.value);
                item.unit = '°C';
                if (item.value < 15) {
                    item.status = 'success'
                } else {
                    item.status = 'error'
                }
            })
            t.currentData.soilTempWeekDegC.value = soilDataResult.reverse()
            t.currentData.soilTempWeekDegC.componentKey++
        },
        updateForecast: async function(lat, lon) {
            var t = this;
            var forecastResult = await t.MIX_weatherDataForecast(lat, lon)
            // console.log("forecast result = " + JSON.stringify(forecastResult, null, 2))
            t.currentData.weatherForecast.value = forecastResult.data.daily
            t.currentData.weatherForecast.componentKey++
        },
        updateSoilTempMonthDegC() {
            var t = this; 
            t.currentData.soilTempMonthDegC.value = t.convertToCelcius(parseFloat(t.getSensor(105).data[0].temp_avg))
            t.currentData.soilTempMonthDegC.subtitle = t.MIX_formatDateTime(t.getSensor(108).data[0].ts, 'X', 'DD-MMM-YY HH:mm:ss')
            t.currentData.soilTempMonthDegC.componentKey++
        },
        updateSoilMoistureBar() {
            var t = this;
            t.currentData.soilMoistureBar.value = parseFloat(t.getSensor(108).data[0].moist_soil_last);
            t.currentData.soilMoistureBar.subtitle = t.MIX_formatDateTime(t.getSensor(108).data[0].ts, 'X', 'DD-MMM-YY HH:mm:ss')
            if (t.currentData.soilMoistureBar.value >= t.soilMoistureBarMin) {
                t.currentData.soilMoistureBar.status = 'success' 
            } else if (t.currentData.soilMoistureBar.value < t.soilMoistureBarMin && t.currentData.soilMoistureBar.value <= 4) {
                t.currentData.soilMoistureBar.status = 'error'
            } else {
                t.currentData.soilMoistureBar.status = 'warning'
            }
            t.currentData.soilMoistureBar.componentKey++

        },

        checkDate() {      
            this.date = moment(new Date()).format('MM-DD')
            this.beforeEndOfOpenPeriod = moment(this.date).isBefore('10.14');
            return this.beforeEndOfOpenPeriod;
        }
    },
    async mounted () {
        var t = this;
        var routerPath = t.$route.path.split('/')
        var stationId = routerPath[2];
        t.station = await t.GET_weatherDataStationById(stationId);
        await t.MIX_weatherDataCurrent(stationId) 
        t.current = await t.GET_weatherDataCurrent;
        // console.log('got here')
        t.updateSensors();
        t.updateFertiliserPeriod();
        t.updateSoilTempMonthDegC();
        t.updateSoilMoistureBar();
        t.updateSoilTempWeekDegC(stationId);
        t.updateForecast(t.station.latitude, t.station.longitude);
    }
  }
</script>