<template>
<div>
    <div v-if="checkDate()">
        <h2 class="success--text pr-2">{{ $t('weather.fertiliserPeriod.open') }}</h2>
    </div>
    <div v-else-if="!checkDate()">
        <h2 color="error--text pr-2">{{ $t('weather.fertiliserPeriod.closed') }}</h2>
    </div>
    <div v-else>
        <h2 color="grey--text pr-2">-</h2>
    </div>
</div>
</template>

<script>
import moment from 'moment'

export default {
    name: 'FertiliserPeriod',
    data: () => ({
        beforeEndOfOpenPeriod: '',
    }),
    methods: {
        checkDate() {      
            this.date = moment(new Date()).format('MM-DD')
            this.beforeEndOfOpenPeriod = moment(this.date).isBefore('09.14');
            // console.log("end of open period " + this.beforeEndOfOpenPeriod);
            return this.beforeEndOfOpenPeriod;
            // this.afterEndOfOpenPeriod = moment(this.date).isAfter('2021.09.14');
            // console.log("start of closed period" + this.afterEndOfOpenPeriod);
        }
    },

    // mounted () {  
        // this.checkDate();
    // }
}

</script>