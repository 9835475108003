import { mapActions } from 'vuex';
import axios from 'axios';

// * GLOBAL MIXIN
const mixin = {
    data: function () {
        return {
            cloudFunctionsAPI: 'https://europe-west1-agriculture-research.cloudfunctions.net'
        }
    },
    methods: {
        ...mapActions('firebase_auth_store', ['ACT_FIREBASE_currentUser']),
        MIX_FIREBASE_currentUser(user) {
            this.ACT_FIREBASE_currentUser(user)
        },
        MIX_FIREBASE_logout() {
            let t = this;
            t.$firebase.auth.signOut().then(() => {
                // t.showPageElements = false;
                t.$router.push("/login").catch((err) => {
                    console.log("Route error: " + err);
                });
                this.ACT_FIREBASE_currentUser(null)
                // t.$store.commit("MUT_FIREBASE_currentUser", null);
            });
        },
        MIX_FIREBASE_checkAdminRegCode: async function(code) {
            let url = `${this.cloudFunctionsAPI}/checkAdminRegCode?code=${code}`
            return axios
                .get(url)
                .then((response) => response.data)
                .catch((error) => error)
        }
    },
    mounted() {
    }
}

export default {
    install (Vue) {
      Vue.mixin(mixin)
    }
  }