import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';
import { find, meanBy } from 'lodash-es';
import moment from 'moment';
const mixins = {     
    // * DATA 
    data: () => ({
        csgAPIURL: 'https://api.agricultureresearch.wales/api/v1/',
        token: ''
        //csgAPIURL: 'http://localhost/api/v1/',
    }),
    // * COMPUTED
    computed: {
        // * MAP GETTERS
        ...mapGetters({
            GET_currentUser: 'GET_currentUser',
            GET_authToken: 'GET_authToken'
        })
    },
//    watch
    // * METHODS
    methods: {
        // * MAP ACTIONS
        ...mapActions({
            ACT_weatherDataStations: 'ACT_weatherDataStations',
            ACT_weatherDataCatalog: 'ACT_weatherDataCatalog',
            ACT_weatherDataCurrent: 'ACT_weatherDataCurrent',
            ACT_weatherDataForecast: 'ACT_weatherDataForecast',
            ACT_authToken: 'ACT_authToken',
            ACT_activeStation: 'ACT_activeStation',
            ACT_weatherDataHistorical: 'ACT_weatherDataHistorical',
        }),
        MIX_logActivity(page, station, currentData) {
            // console.log(this.GET_currentUser.userId)
            //console.log(JSON.stringify(currentData,null,2));
            var activity = {
                'user': {
                    'userId': this.GET_currentUser.userId,
                    'userName': this.GET_currentUser.userFirstName + this.GET_currentUser.userLastName,
                    'userEmail': this.GET_currentUser.userEmail
                },
                'accesed': page,
                'locationId': station.station_id,
                'locationName': station.station_name,
                'dateTime': this.$moment().format('x'),
                'currentData': currentData,
            }
            // console.log('activity: ' + JSON.stringify(activity, null, 2))
            this.$firebase.db.collection("activityLog").doc().set(activity).then((docRef) => {
                console.log('docRef ' + docRef)
            }).catch((error) => {
                   console.log("Error adding document: ", error);
          });
        },
        MIX_sortByDateTime(toSortObject) {
            var t = this;
            return toSortObject.sort(function (left, right) {
                return t.$moment(right.dateTime, "x").diff(t.$moment(left.dateTime, "x"))
            });
        },
        MIX_terms() {
            alert('terms and conditions')
        },
        MIX_authToken(token) {
            this.ACT_authToken(token);
        },
        // * ACTIVE STATION
        MIX_activeStation(station) { 
            this.ACT_activeStation(station);
        },
        // * CALCULATE ARRAY LENGTH
        MIX_arrayLength(array) {
            if (JSON.stringify(array) !== '[]') {
                return array.length
            } else {
                return 0
            }
        },
        // * MIX BACK
        MIX_back() {
            this.$router.go(-1);
        },                
        // * MIX GO
        MIX_go(path) {
            this.$router.push(path).catch((err) => {
                console.log("Route error: " + err);
            });
        },
        MIX_dayTimeFormat(date, shortLong){
           let dt = Number(date);  
            let formatedDate ="";
            if( shortLong === "short"){
                formatedDate = this.$moment(dt).format('YYYY-MM-DD');
            }else if(shortLong === 'long'){
                formatedDate = this.$moment(dt).format('dddd Do MMMM \'YY');
            }
           return formatedDate
        },
        // * FORMAT DATE TIME
        MIX_formatDateTime: function(date, formatIn, formatOut) {
            var t = this;
            if ((date !== '') && (date !== null) && (date !== undefined)) {
                return t.$moment(date, formatIn).format(formatOut);
            } else {
                return ''
            }
        },
        // * TIME FROM NOW
        MIX_fromNow: function(date, formatIn, ago) {
            var t = this;
            return t.$moment(date, formatIn).fromNow(ago);
        },
        // * AXIOS GET GENERAL FUNCTION
        // authtoken will only work for logged in useres, but is only required for certain api calls
        MIX_axiosGet: function(url) {
            return new Promise((resolve) => {
                try {
                    axios
                        .get(url, {
                            headers: {
                                authtoken: this.GET_authToken
                            }
                        })
                        .then((response) => {
                            resolve (response.data)    
                        })
                        .catch ((axiosError) => {
                            resolve ({ code: -1, message: 'ERROR Axios Get Failed', data: null, error: axiosError })    
                        })
        
                }
                catch (axiosError) {
                    resolve ({ code: -1, message: 'ERROR Axios Get Failed', data: null, error: axiosError })    
                }
            })
        },
        // * WEATHER STATIONS - GET AND STORE DATA IN STORE
        MIX_weatherDataStations: async function() {
            //console.log('MIX_weatherDataStations run')
            var t = this;
            var axiosResult = await this.MIX_axiosGet(`${t.csgAPIURL}stations`)
            setTimeout(() => {}, 1000)
            console.log(`${t.csgAPIURL}stations`)
            if (axiosResult.code === 1) {
                t.ACT_weatherDataStations(axiosResult.data.stations)
            }
            return axiosResult;
        },
        // * SENSOR CATALOG - GET AND STORE DATA IN STORE
        MIX_weatherDataCatalog: async function() {
            var t = this;
            console.log(`Catalog = ${t.csgAPIURL}catalog`)
            var axiosResult = await this.MIX_axiosGet(`${t.csgAPIURL}catalog`)
            if (axiosResult.code === 1) {
                t.ACT_weatherDataCatalog(axiosResult.data.sensor_types)
            }
            return axiosResult;
        },
        // * WEATHER STATION CURRENT DATA - GET AND STORE DATA IN STORE
        MIX_weatherDataCurrent: async function(stationId) {
            var t = this;
            // var axiosResult = await this.MIX_axiosGet(`${t.csgAPIURL}current/${stationId}/`)
            var axiosResult = await this.MIX_axiosGet(`${t.csgAPIURL}stationsData/${stationId}/`)
            if (axiosResult.code === 1) {
                t.ACT_weatherDataCurrent(axiosResult.data.sensors)
            } else {
                t.MIX_alert(-1, 'Error showing station data.', null, null);
            }
            return axiosResult;
        },
        // * WEATHER STATION HISTORIC DATA - GET AND STORE DATA IN STORE
        MIX_weatherDataHistoric: async function(stationId, startDate, endDate) {
            var t = this;
            var axiosResult = await this.MIX_axiosGet(`${t.csgAPIURL}historic/${stationId}/?start=${startDate}&end=${endDate}`)
            return axiosResult.data;
        },        
        // * WEATHER STATION  HISTORIC SOIL DATA - LAST 5 DAYS
        MIX_weatherDataSoilData: async function(stationId, todayDate) {
            // console.log('stationId in Mixin = ' + stationId);
            var t = this;
            // console.log(`${t.csgAPIURL}getSensorDataAverageLast5Days/${stationId}?date=1616544000&sensor_type=105&sensor_name=temp_avg`)
            var axiosResult = await this.MIX_axiosGet(`${t.csgAPIURL}getSensorDataAverageLast5Days/${stationId}?date=${todayDate}&sensor_type=105&sensor_name=temp_avg`)
            return axiosResult.data;
        },
        // * WEATHER FORECAST DATA - GET AND STORE DATA IN STORE
        MIX_weatherDataForecast: async function(lat, lon) {
            var t = this;
            if (lat !== undefined) {
                var axiosResult = await this.MIX_axiosGet(`${t.csgAPIURL}forecast/?lat=${lat}&lon=${lon}`)
                if (axiosResult.code === 1) {
                    t.ACT_weatherDataForecast(axiosResult.data)
                }    
            } else {
                t.ACT_weatherDataForecast({})
            }
            return axiosResult;
        },
        MIX_farmingPeriod: function(period) {
            var todaysDate = moment().format('X')
            var periodStart, periodEnd             
            switch (period) {
                case "fertiliserPeriod":
                    periodStart = moment('15-JAN', 'DD-MMM').format('X');
                    periodEnd = moment('15-SEP', 'DD-MMM').format('X');
                    if ((todaysDate >= periodStart) && (todaysDate <= periodEnd)) { 
                        return "OPEN";
                    } else {
                        return "CLOSED";
                    }
                case "slurryPeriod":
                    periodStart = moment('15-JAN', 'DD-MMM').format('X');
                    periodEnd = moment('15-OCT', 'DD-MMM').format('X');
                    if ((todaysDate >= periodStart) && (todaysDate <= periodEnd)) { 
                        return "OPEN";
                    } else {
                        return "CLOSED";
                    }
            }
        },
        MIX_weatherForecastIcons: function(id) {
            // var t = this;
            // var axiosResult = await this.MIX_axiosGet(`${t.csgAPIURL}forecast/?lat=${lat}&lon=${lon}`)
            switch (true) {
                case((id>=200) && (id<300)):
                    return 'icons8-storm';
                case((id>=300) && (id<400)):
                    return 'icons8-light-rain';
                case((id>=500) && (id<504)):
                    return 'icons8-rain';
                case(id===511):
                    return 'icons8-sleet';
                case((id>=511) && (id<531)):
                    return 'icons8-rain-cloud';
                case((id>=600) && (id<700)):
                    return 'icons8-snow';
                case((id>=701) && (id<800)):
                    return 'icons8-haze';
                case(id===800):
                    return 'icons8-sun';
                case(id===801):
                    return 'icons8-partly-cloudy-day';
                case(id===802):
                    return 'icons8-partly-cloudy-day';
                case(id===803):
                    return 'icons8-cloud';
                case(id===804):
                    return 'icons8-cloud';
            }
        },
        MIX_popIcons: function(id) {
          switch (true) {
              case(id==0):
                  return "icons8-no-rain";
              case((id>0) && (id<=0.25)):
                  return "icons8-light-rain";
              case((id>0.25) && (id<=0.50)):
                  return "icons8-rain";
              case((id>0.50 && (id<=0.75))):
                  return "icons8-heavy-rain";
              case(id>0.75):
                  return "icons8-torrential-rain";
          }
        },
        MIX_getAverage: (data, key) => {
            return meanBy(data, (sensor) => sensor[key]).toFixed(0)
        },
        MIX_getObjectByKeyValue: (data, key, value) => {
            // var t = this;
            return new Promise((resolve) => {
                try{
                    resolve(find(data, function(o) { 
                        return o[key] === value
                    }))
                }
                catch(err) {
                    console.log('err' + err)
                }
            })
        },
    }
}
export default {
    install (Vue) {
        Vue.mixin(mixins)
    }
}