<!-- //* TODO 
-->


<template>
    <div>
        <div v-if="value >= threshold">
            <v-row class="justify-center pr-3"> 
               <h1 class="font-weight-bold align-center pt-1 error--text">{{ value }}</h1>
           </v-row>
           <v-row class="justify-center pr-3">
                <h6 class="error--text pt-2">{{ $t('measurements.wet') }}</h6>
           </v-row>
        </div>
        <div v-if="value < threshold">
           <v-row class="justify-center pr-3"> 
               <h1 class="font-weight-bold align-center pt-1 success--text">{{ value }}</h1>
           </v-row>
           <v-row class="justify-center pr-3">
                <h6 class="success--text pt-2">{{ $t('measurements.optimum') }}</h6>
           </v-row>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: { 
            type: [String, Number],
            default: '-'
        },
        threshold: {
            type: [Number]
        }
    },
}
</script>