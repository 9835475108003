import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

// * PERSISTENT STATE
import createPersistedState from "vuex-persistedstate"; // Required for User Auth on Refresh

// * FIREBASE AUTH STORE
import firebase_auth_store from '@/modules/firebase/firebase_auth_store.js';

// * IMPORT MODULES
import terms_store from '@/modules/terms/terms_store.js' // Terms and Conditions Module
import alertbox_store from "@/modules/alertbox/alertbox_store.js"; // Alertbox Module
import progressBar_store from "@/modules/progressBar/progressBar_store.js";
import mapbox_store from "@/modules/mapbox/mapbox_store.js"; // Alertbox Module



export default new Vuex.Store({
    plugins: [createPersistedState()],
    modules: {
        'firebase_auth_store': firebase_auth_store,
        'alertbox_store': alertbox_store, // Alertbox Module
        'terms_store': terms_store, // Terms and Conditions Module
        'progressBar_store': progressBar_store, // Progressbar Module
        'mapbox_store': mapbox_store
    },    
    // * STATE
    state: {
        currentUser: null,
        authToken: '',
        activeStation: null,
        weatherData: {
            catalog: [],
            stations: [],
            current: [],
            forecast: {}
        }
    },
    // * MUTATIONS
    mutations: {
        MUT_activeStation(state, data) { state.activeStation = data },
        MUT_authToken(state, data) { state.authToken = data },
        MUT_weatherDataStations(state, data) { state.weatherData.stations = data },
        MUT_weatherDataCatalog(state, data) { state.weatherData.catalog = data },
        MUT_weatherDataCurrent(state, data) { state.weatherData.current = data },
        MUT_weatherDataForecast(state, data) { state.weatherData.forecast = data },
        SET_currentUser(state, payload) { state.currentUser = payload },
    },
    // * GETTERS
    getters: {
        GET_activeStation(state) { return state.activeStation },        
        GET_authToken(state) { return state.authToken },        
        GET_currentUser(state) { return state.currentUser },        
        GET_weatherDataStations(state) { return state.weatherData.stations },
        GET_weatherDataStationById: (state) => (stationId) => { 
            return state.weatherData.stations.find(station => parseInt(station['station_id']) === parseInt(stationId))
        },
        GET_weatherDataCatalog(state) { return state.weatherData.catalog },
        GET_weatherDataCatalogBySensorType: (state) => (sensorType) => { 
            return state.weatherData.catalog.find(sensor => parseInt(sensor['sensor_type']) === parseInt(sensorType))
        },
        GET_weatherDataCurrent(state) { return state.weatherData.current },
        GET_weatherDataForecast(state) { return state.weatherData.forecast },
    },
    // * ACTIONS
    actions: {
        ACT_activeStation({commit}, payload){ commit('MUT_activeStation', payload) },
        ACT_authToken({commit}, payload){ commit('MUT_authToken', payload) },
        ACT_weatherDataStations({commit}, payload){ commit('MUT_weatherDataStations', payload) },
        ACT_weatherDataCatalog({commit}, payload){ commit('MUT_weatherDataCatalog', payload) },
        ACT_weatherDataCurrent({commit}, payload){ commit('MUT_weatherDataCurrent', payload) },
        ACT_weatherDataForecast({commit}, payload){ commit('MUT_weatherDataForecast', payload) },
    }    
})
