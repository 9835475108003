<template>
  <div class="d-flex align-start flex-column justify-start fill-height" style="width: 100% !important;">
    <mapbox width="100%" height="100%" />
    <v-container fluid class="container-bottom mt-2 mb-2 px-0 py-0">
      <v-card flat tile color="lightgrey" class="px-5 text-center">
        <v-row text-center class="">
          <v-col cols="12" s="6" class="py-5 px-2">
            <v-btn elevation="1" color="success" to="/farming" class="text-h5 justify-center white--text px-2 mb-6" v-if="!showZone && $vuetify.breakpoint.mdAndUp">{{ Math.round(zone[0].distance) }}{{ $t('map.miToCenter') }}{{ zone[0].key }}</v-btn>
            <v-btn elevation="1" color="success" to="/farming" class="text-h7 d-flex justify-center white--text px-2 mb-6" v-if="!showZone && $vuetify.breakpoint.mdAndDown">{{ Math.round(zone[0].distance) }}{{ $t('map.miToCenter') }}{{ zone[0].key }}</v-btn>
            <v-btn block color="primary" id="button" class="text-capitalize lighttext--text" v-if="$vuetify.breakpoint.mdAndUp" x-large v-on:click="showZone = !showZone" @click="getNearestZone()">{{ $t('map.nearestZone') }}</v-btn>
            <v-btn block color="primary" id="button" class="text-capitalize lighttext--text" v-else-if="$vuetify.breakpoint.mdAndDown" large v-on:click="showZone = !showZone" @click="getNearestZone()">{{ $t('map.nearestZone') }}</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'Sites',
  station: '',
  data: () => ({
    location: null,
    gettingLocation: false,
    errorStr: null,
    showZone: true,
  }),
  computed: {
    ...mapGetters({
      GET_weatherDataStations: 'GET_weatherDataStations',
      GET_activeStation: 'GET_activeStation',
      GET_zone: 'mapbox_store/GET_zone',
      GET_user_location: 'mapbox_store/GET_user_location'
    }),
    zone: {
      get () {
        const zone = this.GET_zone;
        return zone;
      }
    },
    userLocation: {
      get () {
        const location = this.GET_user_location;
        // console.log(location);
        return location;
      }
    },
  },
  methods: {
    createMapNodes: async function() {
      const stations = this.GET_weatherDataStations;
      const node = {};
      const feature = {};
      for (var i = 0; i < stations.length; i++ ) {
        var station = stations[i]
        node.id = station.station_id;
        node.type = 'FeatureCollection';
        node.features = [];
        feature.type = 'Feature';
        feature.geometry = {};
        feature.geometry.type = 'Point';
        feature.geometry.coordinates = [
          station.longitude,
          station.latitude,
        ];
        feature.properties = {};
        feature.properties.name = station.station_name;
        feature.properties.id = station.station_id;
        node.features.push(feature);
        await this.MIX_marker_create(node.id.toString(), JSON.parse(JSON.stringify(node)));
      }
    },
    getLocation: async function() {
      return new Promise((resolve, reject) => {
        if(!("geolocation" in navigator)) {
          reject(new Error('Geolocation is not available.'));
        }
        navigator.geolocation.getCurrentPosition(pos => {
          resolve(pos);
        }, err => {
          reject(err);
        });
      });
    },
    getNearestZone: async function() {
      this.gettingLocation = true;
      try {
        this.gettingLocation = false;
        this.location = await this.getLocation();
      } catch(e) {
        this.gettingLocation = false;
        this.errorStr = e.message;
      }
      return this.MIX_nearest_zone(this.location.coords.latitude, this.location.coords.longitude);
    },
  },
  async mounted() {  
    let t = this;
    t.MIX_weatherDataStations();
    this.station === this.GET_activeStation;
    setTimeout(() => {this.createMapNodes()}, 3000);
    this.getNearestZone();
    this.MIX_update_map();
  },
};
</script>

<style scoped>
#mapContainer {
  position: relative;
}
.container-bottom {
  position: fixed;
  bottom: 0;
}
</style>