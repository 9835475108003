import Vue from 'vue';

// * FIREBASE INTEGRATION
const firebase = require("@/firebase/firebase-config.js"); // Import Firebase Config for Firebase Object Access
Vue.prototype.$firebase = firebase; // Prototype Firebase for use throughout Project $firebase
import firebase_auth_mixin from '@/modules/firebase/firebase_auth_mixin.js'; //
Vue.use(firebase_auth_mixin)
import firebase_register_component from '@/modules/firebase/firebase_register_component.vue';
Vue.component('firebase-auth-register', firebase_register_component);
import firebase_login_component from '@/modules/firebase/firebase_login_component.vue';
Vue.component('firebase-auth-login', firebase_login_component);
import firebase_reset_component from '@/modules/firebase/firebase_reset_component.vue';
Vue.component('firebase-auth-reset', firebase_reset_component);


