<template>
<div>
    <div v-if="checkDate() == true">
        <h2 class="success--text">{{ $t('weather.slurrySpreadingPeriod.open') }}</h2>
    </div>
    <div v-if="checkDate() == false">
        <h2 color="error">{{ $t('weather.slurrySpreadingPeriod.closed') }}</h2>
    </div>
</div>
</template>

<script>
import moment from 'moment'

export default {
    name: 'SlurryPeriod',
    data: () => ({
        beforeEndOfOpenPeriod: '',
    }),
    methods: {
        checkDate() {      
            this.date = moment(new Date()).format('MM-DD')
            this.beforeEndOfOpenPeriod = moment(this.date).isBefore('10.14');
            // console.log("end of open period " + this.beforeEndOfOpenPeriod);
            return this.beforeEndOfOpenPeriod;
            // this.afterEndOfOpenPeriod = moment(this.date).isAfter('2021.09.14');
            // console.log("start of closed period" + this.afterEndOfOpenPeriod);
        }
    },

    // mounted () {  
        // this.checkDate();
    // }
}

</script>