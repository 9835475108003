<template>
	<v-card flat color="appbackground" width="100%" class="mb-4 pa-2">
    <v-row>
        <v-flex class="lg5-custom" v-for="(item, index) in currentdata.value.slice(0,5)" :key="index">
            <v-card color="accent" class="ma-1 px-1" rounded flat height="130">
                <v-row no-gutters>
                    <v-col cols=12>
                        <v-row class="d-flex align-center justify-center" no-gutters>
                            <div>
                                <div class="text-center caption grey--text pa-0">{{ MIX_formatDateTime(item.dt, 'X', 'DD/MM') }}</div>
                                <div class="font-weight-bold text-h7 text-center darktext--text pa-0">{{ $t(MIX_formatDateTime(item.dt, 'X', 'ddd')) }}</div>
                            </div>
                        </v-row>
                        <v-row class="d-flex align-center justify-center">
                            <div class="text-center text-h4 font-weight-normal darktext--text py-3">{{ item.temp.day.toFixed() }}°</div>
                        </v-row>
                        <v-row class="d-flex align-center justify-center px-1">
                            <v-icon size="15" color="grey" class="">icons8-temperature</v-icon>
                            <h4 class="text-center caption">{{ item.temp.max.toFixed() }}° | <span class="font-weight-light"> {{ item.temp.min.toFixed() }}°</span></h4>
                        </v-row>
                        <div v-if="item.rain" >
                            <v-row class="d-flex align-center justify-center px-1 py-1">
                                <v-icon size="15" color="grey" class="pt-2">icons8-rainmeter</v-icon>
                                <h4 class="text-center caption pt-2">{{ item.rain.toFixed() }}%</h4>
                            </v-row>
                        </div>
                        <div v-else >
                            <v-row class="d-flex align-center justify-center px-1 py-1">
                                <v-icon size="15" color="grey" class="pt-2">icons8-rainmeter</v-icon>
                                <h4 class="text-center caption pt-2">0%</h4>
                            </v-row>
                        </div>
                    </v-col>
                </v-row>
            </v-card>   
        </v-flex>
    </v-row>
    </v-card>
</template>

<script>
export default {
		props: {
			currentdata: {
				type: Object,
				required: false,
				default: function() {
					return {
						title: "Weather Title",
						subtitle: "Weather Sub Title",
						value: [],
						status: "grey",
					};
				},
			},
		},
        mounted() {
            // console.log('currentdata weather= ' + JSON.stringify(this.currentdata, null, 2))
        }
	};
</script>

<style>
    .lg5-custom {
        width: 20%;
        max-width: 20%;
        flex-basis: 20%;
    }
</style>