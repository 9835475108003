var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-start flex-column justify-start fill-height",staticStyle:{"width":"100vw !important"}},[_c('v-container',{staticStyle:{"padding":"0px !important"},attrs:{"fluid":"","width":"100%"}},[_c('v-card',{attrs:{"flat":"","color":"lightgrey"}},[_c('v-row',{staticClass:"py-2"},[_c('v-col',{staticClass:"px-8 align-center",attrs:{"cols":"12"}},[_c('div',{staticClass:"text-h5 pt-2 primary--text font-weight-thin"},[_vm._v("STATIONS")]),_c('span',{staticClass:"body-2 font-weight-regular darktext--text"},[_vm._v("Welcome to Stations, please select a location and then the type of activity below you want to monitor.")])])],1)],1),_c('v-row',{staticClass:"d-flex align-center justify-end"},[_c('v-col',{staticClass:"ma-3 px-5 text-right d-flex align-center justify-end",attrs:{"cols":"2","xs":"2"}},[_c('v-btn',{on:{"click":function($event){_vm.columnsDialog = !_vm.columnsDialog}}},[_vm._v("Headers")])],1)],1),_c('v-data-table',{staticClass:"elevation-0 mx-5",attrs:{"headers":_vm.computedHeaders,"items":_vm.computedTableData,"items-per-page":10,"item-key":"id","hide-default-header":"","search":_vm.search},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return _vm._l((props.headers),function(head,index){return _c('th',{key:index,staticClass:"pa-4 font-weight-medium",staticStyle:{"font-size":"12px !important"},attrs:{"align":head.align}},[_vm._v(" "+_vm._s(_vm.$t(("fields." + (head.text))))+" ")])})}},{key:"item.station_name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"200px"},on:{"click":function($event){return _vm.MIX_go(("/admin/stations/" + (item.station_id)))}}},[_vm._v(_vm._s(item.station_name))])]}},{key:"item.registered_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.MIX_formatDateTime(item.registered_date, 'X', 'DD-MMM-YY')))])]}},{key:"item.subscription_end_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.MIX_formatDateTime(item.subscription_end_date, 'X', 'DD-MMM-YY')))])]}}],null,true)})],1),_c('pre'),_c('v-dialog',{attrs:{"scrollable":"","max-width":"300px"},model:{value:(_vm.columnsDialog),callback:function ($$v) {_vm.columnsDialog=$$v},expression:"columnsDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('headings.showHideColumns')))]),_c('v-divider'),_c('v-card-text',{staticStyle:{"height":"300px"}},[_c('v-list',_vm._l((_vm.headers),function(header,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',[_c('v-checkbox',{attrs:{"color":"grey darken-1","hide-details":"","false-value":true,"true-value":false,"label":_vm.$t(("fields." + (header.text)))},model:{value:(_vm.headers[index].hidden),callback:function ($$v) {_vm.$set(_vm.headers[index], "hidden", $$v)},expression:"headers[index].hidden"}})],1)],1)}),1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"text-right"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.columnsDialog = false}}},[_vm._v("Close")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }