<template>
    <div>
        <h1 class="font-weight-bold justify-end pr-2 grey--text">{{ value }}</h1>
        <!-- <div v-if="sensors.soilaverage >= " -->
    </div>
</template>

<script>
export default {
    props: {
        value: { 
            type: [String, Number],
            default: '-'
        },
        threshold: {
            type: [Number]
        }
    },
}
</script>