import Vue from 'vue';
import moment from 'moment';

const mapbox_store = {
  namespaced: true,
  state: {
    map: {
      center: [-4.033000, 51.804960],
      style: 'mapbox://styles/mapbox/outdoors-v11',
      zoom: 8,
    },
    layerSelected: {
      id: '',
      name: '',
    },
    marker: {
      data: {},
      properties: {},
      method: '',
      updated: '',
    },
    zone: {
      distance: '',
      key: '',
    },
    user: {
      lon: '',
      lat: '',
    },
  },
  mutations: {
    MUT_marker_method(state, data) {
      state.marker.method = data;
    },

    MUT_marker_create(state, data) {
      state.marker.data = {};
      state.marker.properties = {};
      state.marker.data = data;
    },
    MUT_marker_update(state, data) {
      state.marker.properties = data;
    },
    MUT_marker_delete(state, data) {
      state.marker.data = {};
      state.marker.properties = {};
      state.marker.data = data;
    },
    MUT_marker_updated(state) {
      Vue.set(state.marker, 'updated', moment().format('x'));
    },
    MUT_update_map(state, data) {
      state.map = data;
    },
    MUT_update_zoom(state, data) {
      state.map.zoom = data;
    },
    MUT_update_layerSelected(state, data) {
      state.layerSelected = data;
    },
    MUT_nearest_zone(state, data) {
      state.zone = data;
    },
    MUT_user_location(state, data) {
      state.user.lon = data;
      state.user.lat = data;
    },
  },
  actions: {
    ACT_marker_create({ commit }, payload) {
      commit('MUT_marker_create', payload);
      commit('MUT_marker_method', 'create');
      commit('MUT_marker_updated');
    },
    ACT_marker_update({ commit }, payload) {
      commit('MUT_marker_update', payload);
      commit('MUT_marker_method', 'update');
      commit('MUT_marker_updated');
    },
    ACT_marker_delete({ commit }, payload) {
      commit('MUT_marker_delete', payload);
      commit('MUT_marker_method', 'delete');
      commit('MUT_marker_updated');
    },
    ACT_update_map({ commit }, payload) {
      commit('MUT_update_map', payload);
    },
    ACT_update_zoom({ commit }, payload) {
      commit('MUT_update_zoom', payload);
    },
    ACT_update_layerSelected({ commit }, payload) {
      commit('MUT_update_layerSelected', payload);
    },
    ACT_nearest_zone({ commit }, payload) {
      commit('MUT_nearest_zone', payload);
    },
    ACT_user_location({ commit }, payload) {
      commit('MUT_user_location', payload);
    },
  },
  getters: {
    GET_marker(state) {
      return state.marker;
    },
    GET_features(state) {
      return state.features;
    },
    GET_zone(state) {
      return state.zone;
    },
    GET_user_location(state) {
      return state.user;
    },
  },
};
export default mapbox_store;
